
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/base.scss";

.items-container,
.category-item-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem 0 -1.5rem;
  .products-item-card-box {
    padding: 1.5rem;
    flex: 0 0 100%;
    max-width: 100%;
    @include media(xs) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include media(md) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    @include media(lg) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.product-accessories-list {
  margin: 0 -1.5rem 0 -1.5rem;
  display: flex;
  flex-wrap: wrap;
  .products-item-card-box {
    padding: 1.5rem;
    flex: 0 0 100%;
    max-width: 100%;
    @include media(xs) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include media(md) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    @include media(lg) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

.category-list {
  margin: 2rem 0;
}

.category-item {
  margin-top: 8rem;
  &-container {
    padding-top: 2rem;
  }
}

.product-item-card {
  transition: 0.3s ease all;
  width: 100%;
  position: relative;
  display: flex;

  &-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid $color-line;
    &:hover,
    &:focus {
      border: 1px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(135deg, #f86809,#10f4b9,#2088db);
    }
  }

  h4 {
    text-shadow: 0 3px 5px rgb(177, 177, 177);
    color: $color-black;
    font-size: 16px;
    max-width: 100%;
    margin: 0 1.5rem 1.5rem 1.5rem;
    @include pux-scale-with-min('font-size', 23px, 18px);
  }
  &-img {
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-top: 65%;
    }
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
  }
  &-content {
    display: flex;
    align-items: flex-end;
  }
  &.background-picture {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    padding-top: 65%;
    .product-item-card-inner {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      border: 1px solid transparent;
      &:hover,
      &:focus {
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(135deg, #f86809,#10f4b9,#2088db);
      }
    }
    h4 {
      color: $color-white;
      text-shadow: 0 3px 5px rgba(22, 22, 22, 0.678);
    }
  }
}

.IE {
  .product-item-card-img {
    overflow: hidden;
    img {
      max-height: 15rem;
    }
  }
}

.show-more-container {
  margin: 4rem 0 6rem 0;
  text-align: center;
}



.solution-category {
  .products-item-card-box {
    flex: 0 0 100%;
    max-width: 100%;
    @include media(xs) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include media(xxl) {
      flex: 0 0 33.3333%;
      max-width: 33.333%;
    }
  }
}

.middle-container{
  max-width: 1585px;
  margin: 0 auto;
}

.page-helper{
  padding: 150px 0 40px 0;
  @include media(md){
    padding: 200px 0 80px 0;
  }
  h2,
  .h2{
    font-size: 35px;
    line-height: 45px;
    color: $main-color;
    margin: 0;
    @include media(md){
      font-size: 50px;
      line-height: 65px;
    }
  }
}
h3,
.h3,
.promo-item-top{
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
  margin: 0;
}

.promo-item{
  margin: 0 0 40px 0; 
  @include media(lg){
    margin: 0 0 100px 0; 
  }
}

.promo-item-inner{
  display: flex;
  margin-top: 20px;
  gap: 30px;
  flex-direction: column;
  @include media(lg){
    flex-direction: row;
  }
  .promo-item-video{
    width: 100%;
  }
  .promo-item-nwl{
    width: 100%;
    max-width: 390px;
    margin: 0 auto;
    @include media(lg){
      min-width: 390px;
    }
  }
}

.promo-item-nwl{
  background-color: $main-color;
  color: #fff;
  text-align: center;
  .promo-item-nwl-title{
    color: #fff;
  }
  .promo-item-nwl-text{
    //@include pux-scale-with-min('font-size', 23px, 16px);
    //@include pux-scale-with-min('line-height', 30px, 23px);
    font-size: 23px;
    line-height: 30px;
    font-weight: 600;
  }
  .promo-item-nwl-content{
    padding: 15px 10px 15px 10px;
  }
  .iframe-newsletter{
    height: auto;
  }
}

.promo-item-nwl-form{
  margin-top: 15px;
  input[type="text"]{
    width: 100%;
    background: #fff;
    height: 50px;
    border-radius: 10px !important;
    border: none !important;
  }
  label{
    display: none;
  }
  input[type="submit"]{
    height: 50px;
    border-radius: 10px !important;
    border: none !important;
    width: 100%;
    background: #3FA503;
    color: #fff;
    font-weight: 600;
    //@include pux-scale-with-min('font-size', 23px, 16px);
    //@include pux-scale-with-min('line-height', 30px, 23px);
    font-size: 23px;
    line-height: 30px;
  }
  .ErrorLabel, .EditingFormErrorLabel{
    color: red;
    font-size: 18px;
    line-height: 25px;
  }
}

.promo-box{
  .sib-form-message-panel--active{
    animation: fadeOut 0.5s forwards;
    animation-delay: 2s;
  }
}

@keyframes fadeOut {
  0% {
      display: inline-block;
  }
  100% {
      display: none; 
  }
}

.promo-item-video{
  aspect-ratio: 16/9;
  display: flex;
  iframe{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
}

.promo-card-container{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 40px;
  @include media(lg){
    margin-bottom: 100px;
  }
  .promo-card-item{
    width: 100%;
    @include media(sm){
      width: calc(50% - 23px);
    }
    @include media(md){
      width: calc(33.33% - 23px);
    }
    @include media(lg){
      width: calc(25% - 23px);
    }
  }
}

.promo-card-item{
  border: 1px solid $color-line;
  padding: 10px;
}

.promo-carr-item-name{
  color: #000;
  font-weight: 600;
}

.promo-card-item-image{
  height: 200px;
  position: relative;
  img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.promo-video-item{
  position: relative;
  border: none;
  overflow: hidden;
  .promo-carr-item-name{
    display: none;
  }
  .promo-card-item-image{
    position: static;
  }
  img{
    object-fit: cover;
    transition: 250ms;
  }
  &:before{
    content: "\e906";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff0000;
    font-size: 100px;
    z-index: 33;
  }
  &:after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background-color: #fff;
    z-index: 22;
  }
  &:hover{
    img{
      transform: scale(1.05, 1.05);
    }
  }
}

.solution-item{
  background-color: #C9C9C9;
  padding: 20px;
  display: flex;
  gap: 30px;
  font-size: 21px;
  line-height: 23px;
  flex-wrap: wrap;
  flex-direction: column;
  @include media(lg){
    flex-wrap: nowrap;
    gap: 30px 100px;
    flex-direction: row;
  }
}

.solution-item-brand-title{
  font-weight: 900;
  padding-left: 80px;
  .marker{
    color: #F7403A;
  }
}

.solution-item-brand-logo{
  margin: -20px 0 0 -20px;
}

.solution-item-title{
  font-weight: 900;
  margin-bottom: 10px;
}

.solution-item-content{
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    @include media(xl){
      columns: 2;  
    }
    li{
      margin-bottom: 15px;
    }
  }
  a{
    color: #F7403A;
    font-weight: 900;
    text-decoration: none;
    &:focus,
    &:hover{
      color: darken(#F7403A, 10);  
      text-decoration: none;
    }
  }
}