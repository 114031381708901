@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.search-form  {
  position: relative;
  margin-bottom: 30px;
	margin-top: 30px;
	@include media(md) {
			margin-top: 0px;
	}

  &-input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 45px;
    outline: 0;
    background: $color-secondary-light;
    border: 0px;
    @include animate("background-color, color");
    font-size: 18px;

    @include media(lg) {
      height: 50px;
      padding-right: 75px;
      font-size: 22px;
    }

    &:focus {
      outline: 0;
      background: #d3d3d3;
    }
  }

  &-button {
    position: absolute;
    top: 20px;
    right: 10px;
    @include translate(0, -50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    @include border-radius(50%);
    border: 1px solid $color-black;
    cursor: pointer;
    @include transition();

    @include media(lg) {
      right: 20px;
      width: 25px;
      height: 25px;
    }

    &:focus,
    &:hover {
      background-color: $color-black;

      &:after {
        color: $color-white;
      }
    }

    &:after {
      @include icomoon();
      content: "\e909";
      position: absolute;
      top: 50%;
      left: 50%;
      @include translate(-50%, -50%);
      font-size: 18px;
      color: $color-black;

      @include media(lg) {
        font-size: 22px;
      }
    }

    &.cancel {
      &:after {
        content: "\e919";
        font-size: 10px;

        @include media(lg) {
          font-size: 10px;
        }
      }
    }
  }
}


.search-list{
  border-top: 1px solid $color-black;
  h2 {
    @include pux-scale-with-min('font-size', 30px, 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
    content: "\e908";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    border: 2px solid #000;
    transition: all ease-in-out 200ms;
    border-radius: 100%;
    margin-left: 11px;
    }
  }
    .full-width-item{
        display: block;
        text-decoration:none !important;
        color:#000 !important;
        padding: 3rem 0;
        border-bottom: 1px solid $color-black;
        @include media(sm) {
          display: flex;
        }
        &-content {
          flex-basis: calc(100% - 30rem);
        }
        &-img {
          flex-basis: 30rem;
          flex-shrink: 0;
        }
        &:hover {
          h2:after {
            color: $color-white;
            background-color: $color-black;
          }
        }
    }
}

#text-list{
    //display:none;
    .full-width-item-img{
        display:none;
    }
}

.big-button-bw-small {
  max-width: 30rem;
}
