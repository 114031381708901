
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/base.scss";

.product-preview {
  padding:0 0 30px 0;
  @include media(xs) {
    @include flex-block();
    @include flex-flow();
    @include justify-content(space-between);
  }
  &-image {
    width: 100%;

    @include media(xs) {
      width: 40%;
    }

    @include media(md) {
      width: 50%;
    }

    @include media(lg) {
      width: 50%;
    }

    .img-cont {
      position: relative;
      border: 5px solid $color-secondary-light;
      height: 0;
      @include calc("padding-bottom", "100% - 10px");
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;

        &:not(:first-of-type) {
          display: none;
        }
      }
    }
  }

  &-contact {
    position: relative;
    @include flex-block();
    @include flex-flow(column);
    justify-content: space-between;

    @include media(xs) {
      @include calc("width", "60% - 20px");
      margin-top: 0px;
    }
    @include media(md) {
      @include calc("width", "50% - 20px");
    }

    @include media(lg) {
      @include calc("width", "50% - 35px");
    }

    &-perex {
      order: 2;
      line-height: 1.6;
      margin-top: 20px;

      @include media(xs) {
        margin-top: 0px;
      }

    }

    &-types {
      order: 3;
      margin-top: 8px;

      @include flex-block();
      @include flex-flow();
      @include flex-wrap(wrap);
      @include justify-content(space-between);

      @include media(xs) {
        border-bottom: 0;
      }
      .product-line{
          width:100%;
          height:1px;
          background:#000;
      }
      &-format,
      &-load {
        width: 45%;
        padding: 10px 0 14px;

        @include media(xs) {
          width: 48%;
          padding: 13px 0 7px;
        }

        @include media(xl) {
          width: 53%;
          padding: 16px 0 12px;
        }

        &-title {
          font-size: 15px;
          font-weight: 600;

          @include media(sm, sm) {
            font-size: 18px;
          }

          @include media(lg) {
            font-size: 18px;
          }

        }
        a {
          display: inline-block;
          color: $color-black;
          text-decoration: none;
          padding: 2px 0;
          margin-top: 2px;
          @include transition(100ms);
          border-bottom: 1px solid transparent;
          font-size: 13px;

          @include media(sm, sm) {
            font-size: 15px;
          }

          @include media(lg) {
            font-size: 15px;
          }

          @include media(xl) {
            font-size: 17px;
            margin-top: 5px;
          }

          &:not(:last-of-type) {
            margin-right: 8px;

            @include media(lg) {
              margin-right: 15px;
            }

            @include media(xl) {
              margin-right: 18px;
            }
          }

          &:hover,
          &:focus,
          &.active {
            border-bottom: 1px solid $color-black;
            text-decoration: none !important;
          }
        }

      }

      &-load {
        @include media(xl) {
          width: 47%;
        }
      }


    }

    &-colors {
      order: 0;
      position: relative;
      @include flex-block();
      @include flex-flow();
      padding: 0 2px;
      margin: 12px 0;
      margin-top: 20px;

      @include media(lg) {
        padding: 14px 0;
      }

      @include media(xl) {
        padding: 8px 5px;
      }

      &:before {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-black;
        z-index: 1;

        @include media(sm) {
          @include pseudo-elements("", absolute, -12px, 0px);
        }

        @include media(lg) {
          top: -10px;
        }
      }

      &-item {
        width: 31px;
        height: 31px;
        border-radius: 31px;
        cursor: pointer;

        &:not(:first-of-type) {
          margin-left: 15px;
        }

        @include media(xl) {
          width: 39px;
          height: 39px;

          &:not(:first-of-type) {
            margin-left: 22px;
          }
        }
      }
    }
  }
}


@keyframes loading-animate {
  100% {
    transform: rotate(360deg);
    @include transform-origin(center center);
  }
}

.loading-animation {

  &:after {
    @include icomoon();
    font-size: 35px;
    position: absolute;
    content: "\e917";
    color: gray;
    top: 47%;
    left: 48%;
    animation: loading-animate 1s linear infinite;
    z-index: 200;

    @include media(xxs, md) {
      top: 43%;
      left: 48%;
    }
  }
}

.tech-info{
		padding: 5px 0 25px;
		font-size: 0;
		column-count: 1;
    -webkit-column-count:1;
    -moz-column-count: 1;
    column-gap:22px;
    -webkit-column-gap:22px;
    -moz-column-gap:22px;

    dl{
        display: table;
        width: 100%;
        table-layout: fixed;
        break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
        border-bottom: 2px solid $colorv2-gray-2;
        padding: 0;
        margin: 0;
        &:last-of-type {
            border-bottom: 0;
        }
        &:nth-of-type(2),
        &:nth-of-type(3) {
            border-bottom: 2px solid $colorv2-gray-2;
        }
    }

	dt{
    background-color: transparent;
    font-weight: 600;
    padding-right: 10px;
	}

	dt,dd{
    display: table-cell;
    vertical-align: top;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 14px;
		font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 4px 3px;
    width: auto;
	}

	dd{
		text-align: right;
		padding-right: 0;
    overflow: visible;
    text-overflow: inherit;
	}

  &:after {
      content: "";
      display: block;
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $color-white;
  }

	.no-columns &{
        column-count: 1;
        -webkit-column-count:1;
        -moz-column-count:1;
        column-gap:0;
        -webkit-column-gap:0;
        -moz-column-gap:0;
    }

   	@include media(sm){
			column-count: 2;
			-webkit-column-count:2;
			-moz-column-count: 2;
    	dt,dd{
    		font-size: 13px;
    	}
    }
    @include media(md){
    		column-count: 1;
        -webkit-column-count:1;
        -moz-column-count: 1;

        dt,dd{
        	font-size: 14px;
        }
    }
		@include media(lg){
			column-count: 2;
			-webkit-column-count:2;
			-moz-column-count: 2;
    	dt,dd{
    		font-size: 13px;
    	}
    }
		@include media(xl){
    	column-gap:14px;
   		-webkit-column-gap:14px;
  		-moz-column-gap:14px;

			dt,dd{
				padding: 4px 5px;
			}

			dt{
				width: 43%;
			}

			dd{
				width: 57%;
			}
    }
}

.product-detail {
    display: flex;
    flex-direction: column;
    &-container {
        position: relative;
        padding-top: 60px;
        @include media(md) {
            padding-top: 80px;
        }
        @include media(xl) {
            padding-top: 105px;
        }
        .arrow-link-back-in {
          margin-bottom: 2.5rem;
        }
        .btn-primary {
          display: inline-block;
          margin: 3rem 0;
        }
    }
    .title-line {
        border-bottom: 0;
    }
    .product-preview {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include media(md) {
          flex-direction: row;
        }
        &-contact {
            order: 2;
            width: 100%;
            @include media(md) {
                flex: 0 1 49%;
                max-width: 49%;
                width: auto;
            }
            &-perex {
                order: 0 !important;
            }
        }
        &-image {
            order: 1;
            width: 100%;
            @include media(md) {
                flex: 0 1 49%;
                max-width: 49%;
                width: 100%;
            }
            .img-cont {
                width: 100%;
                max-width: 100%;
                margin: 0 auto 15px auto;
                padding-bottom: 80%;
            }
        }
        &-technical-info {
            h2 {
                margin-bottom: 2px;
            }
        }
    }
    &-info {
        padding-top: 14px;
    }
    .product-preview-image .img-cont {
      border: 1px solid $color-line;
    }
    .product-preview-contact-colors {
        margin: 0;
        padding: 0;
        &:before {
            display: none;
        }
        &-title {
            font-weight: 600;
            padding-right: 4px;
            padding-top: 1px;
            display: flex;
            align-items: center;
        }
        &-container {
            display: flex;
            align-items: center;
            padding: 15px 0 20px;
            @include media(md) {
                padding: 17px 0 22px;
            }
        }
        &-item {
            width: 27px;
            height: 27px;
            margin-left: 12px;
            border-radius: 50%;
        }
    }
    ul {
        padding-left: 25px;
        li {
            text-indent: 4px;
        }
    }
    &.accessories-type {
        .title-line {
            border-bottom: 2px solid $color-black;
        }
        .product-preview {
            margin-top: 16px;
            &-image {
                @include media(md) {
                    order: 1;
                }
            }
            &-contact {
                @include media(md) {
                    order: 2;
                }
            }
            .product-preview-technical-info {
                margin-top: 25px;
            }
        }
    }
    .content-gallery {
      margin: 0 -1rem;
      position: relative;
    }
    .content-gallery-list-box {
      padding: 0 1rem;
      margin-bottom: 2rem;
      @include media(md) {
        margin-bottom: 0;
      }


    }
    .content-gallery-list-item {
      display: block;
      height: 100%;
      width: 100%;
      height: 0;
      padding-bottom: 60%;
      overflow: hidden;
      position: relative;
      border: 1px solid $color-line;
      img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        height: auto;
      }

    }
}

.product-detail,
.product-detail-accessories {
  .slick-track {
    min-width: 100%;
  }
  .slick-arrow {
    font-size: 0;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: 1px solid $color-black;
    background-color: $color-white;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    transition: 0.3s ease all;
    outline: 0;
    &:active {
      background-color: $color-black;
      outline: 0;
      &:before {
        color: $color-white;
      }
    }
    &:before {
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .slick-prev {
    left: -7px;
    &:before {
      content: '\e90d';

    }
  }
  .slick-next {
    right: -7px;
    &:before {
      content: '\e908';
    }
  }
  .slick-disabled {
    opacity: 0;
  }
}

.product-accessories {
    &-title {
        padding: 1rem 0px;
        display: block;
        h3 {
            padding: 0;
            margin: 0;
        }
    }
    &-list {
        position: relative;
        padding-top: 1rem;
        &.hidden {
            max-width: 100%;
            overflow: hidden;
            .row {
                flex-wrap: nowrap;
            }
        }
        [data-accessories] + .product-accessories-title {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
        }
        $space-base: 10px;

        .row,
        .items-container {
            margin-left: -$space-base;
            margin-right: -$space-base;
            [class*="col-"] {
                padding-left: $space-base;
                padding-right: $space-base;
            }
        }
    }
    &-item {
        text-decoration: none !important;
        color: #000;
        display: block;
        &-image {
            position: relative;
            overflow: hidden;
            border: 1px solid $colorv2-gray;
            &:before {
                content: "";
                display: block;
                padding-top: 66%;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 80%;
                max-height: 80%;

            }
        }
        &-text {
            font-size: 14px;
            color: #000;
            text-align: left;
        }
    }
    &-btn {
        font-size: 14px;
        padding: 5px 20px;
        border: 1px solid $color-black;
        font-weight: 600;
        display: none;
        position: relative;
        margin-top: 40px;
        cursor: pointer;
        @include transition;
        &:hover,
        &:focus {
            background-color: $colorv2-gray-2;
        }
        &.show {
            display: inline-block;
        }
    }
    & + .product-v2-accessories-container-title {
        display: block;
    }
}
.product-accessories-others {
    .product-accessories-title {
        display: inline-block;
    }
}
.product-level-1 {
    .title-line {
        border-bottom: 2px solid $color-black;
    }
}

.product-back-btn {
    margin-bottom: 10px;
    @include media(lg) {
        margin: 0;
    }

    .content-button-level-up {
        position: relative;
        font-size: 14px;
        color: $color-black;
        display: inline-flex;
        align-items: center;
        margin: 0;
        margin-left: 36px;
        padding: 0;
        text-decoration: none !important;
        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(100%);
            width: 100%;
            @include transition;
            height: 2px;
            background-color: #8f8f8f;
        }
        &:before {
            content: "\e90d";
            font-size: 26px;
            color: $color-black;
            margin-right: 4px;
            border: 0;
            width: 50px;
            position: absolute;
            top: -2px;
            left: 0;
            transform: translate(-100%, 0);
            @include media(lg) {
                font-size: 32px;
            }
        }
        &:hover,
        &:focus {
            color: $color-black;
            &:before {
                background-color: transparent;
            }
            &:after {
                background-color: $color-black;
            }
        }
    }
}



.contact {
    display: flex;
    flex-wrap: wrap;
    &-cell {
        display: flex;
        font-size: 14px;
        line-height: 21px;
        padding-top: 5px;
        @include media(lg) {
            padding-top: 7px;
        }
        &-container {
            flex: 0 1 100%;
            max-width: 100%;
            @include media(sm) {
                flex: 0 1 50%;
                max-width: 50%;
            }
            @include media(md) {
                flex: 0 1 100%;
                max-width: 100%;
            }
            @include media(lg) {
                flex: 0 1 50%;
                max-width: 50%;
            }
        }
        &-country {
            flex: 0 0 50px;
            max-width: 50px;
            font-weight: 700;
        }
        &-phone {
            flex: 1 1 100%;
            max-width: calc(100% - 50px);
            a {
                color: $color-black;
                text-decoration: none;
                font-weight: 400;
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

.product-accessories-container {
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    &-title {
        order: -1;
        padding-bottom: 5px;
    }
}

.product-variant-btn,
.info-tabs-btn {

    &-list {
        margin-top: 20px;
        @include media(md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }
        .h3 {
          margin: 20px 0 15px;
          @include pux-scale-with-min('font-size', 28px, 18px);
          margin-right: 6.8rem;
          color: $main-color;
          text-decoration: none;
          &:hover,
          &:focus,
          &.active {
            text-decoration: underline;
            text-decoration-color: $color-orange;
          }
      }
/*         &.first-init {
            .product-tab-btn {
                &:first-of-type {
                    background-color: $color-primary;
                    color: $color-black !important;
                    &:after {
                        transform: translate(-50%, 100%);
                        @include triangle-bottom(20px, 20px, 20px, $color-primary);
                        @include transition(250ms);
                    }
                }
            }
        } */
    }
}

.info-tabs {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    &-item {
        position: absolute;
        top: 0;
        left: 0;
        height: 100px;
        width: 100%;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        @include transition(100ms);
        padding-top: 5px;
        @include media(lg) {
            padding-top: 15px;
        }
        &.active {
            position: static;
            height: auto;
            visibility: visible;
            opacity: 1;
            @include transition(200ms);
            transition-delay: 100ms;
        }
    }
    &.first-init {
        .info-tabs-item {
            &:first-of-type {
                position: static;
                height: auto;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .big-table {
      margin-top: 3rem;
    }
}



.product-black {
  background-color: $color-black;
}

.product-grey{
  background-color: #919191;
}

.product-video {
		&-holder {
				position: relative;
				margin-bottom: 20px;
				margin-top: 15px;
				@include media(md) {
						margin-top: 0;
						margin-bottom: 30px;
				}
				&:before {
						content: "";
						display: block;
						padding-bottom: 56.3%;
				}
				iframe {
						position: absolute;
						display: block;
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
				}
		}
		&-text {
				margin-bottom: 20px;
				@include media(md) {
						margin-bottom: 30px;
				}
		}
}

iframe {
    max-width: 100%;
}

.info-tabs-item{
  .download-section{
    display: flex;
    flex-wrap: wrap;
    margin:0 -7px;
  }
}
