
@import "_variables.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";


.contact-list {
  padding-top: 20px;

  a {
    color: $color-black !important;

    &:hover,
    &:focus {
      color: $color-black !important;
    }
  }

  .contact-list-item {
    position: relative;
    background-color: $color-secondary-light;
    width: 100%;
    @include flex-block();
    flex-flow: row wrap;
    line-height: 18px;
    margin-bottom: 15px;

    @include media(xs) {
      padding: 10px;
    }

    .contact-item-map {
      position: relative;
      width: 100%;
      height: 0px;
      padding-bottom: 65%;
      order: 2;

      @include media(xs) {
        width: 200px;
        height: 132px;
        padding-bottom: 0;
        order: 1;
      }

      @include media(xs, sm) {
        margin-bottom: 40px;
      }

      @include media(sm) {
        width: 295px;
        height: 195px;
      }

      @include media(md) {
        width: 200px;
        height: 132px;
      }

      @include media(lg) {
        width: 295px;
        height: 195px;
      }

      .contact-map {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .contact-list-item-content {
      width: 100%;
      order: 1;
      padding: 10px 10px 0 10px;

      @include media(xs) {
        padding: 0;
        padding-left: 10px;
        @include calc("width", "100% - 200px");
        order: 2;
      }

      @include media(sm) {
        @include calc("width", "100% - 295px");
      }

      @include media(md) {
        @include calc("width", "100% - 200px");
      }

      @include media(lg) {
        @include calc("width", "100% - 295px");
      }

      .contact-title-line {
        width: 100%;
        border-bottom: 2px solid $color-black;
      }

      .contact-data {
        padding-bottom: 10px;
      }
    }

    .contact-data-button {
      position: absolute;
      bottom: 10px;
      width: 104px;
      height: 30px;
      display: none;

      @include media(xs) {
        display: inline-block;
        left: 10px;
      }


      @include breakpointhelper(540px, 615px) {
        width: 100px;
      }

      @include media(md) {
        left: 10px;
        right: auto;
        width: 132px;
      }

      @include media(lg) {
        left: auto;
        right: 10px;
      }
    }
  }
}
