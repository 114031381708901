@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.modal,
.product-registration-box {
  background-color: rgba($color-black, .85);
  color: $color-black;
  padding-right: 0 !important;
  z-index: 9999;

  .modal-dialog {
    position: relative;
    min-height: 95%;
    margin: 10rem auto;
    text-align: center;
    width: 100%;

    max-width: 100%;

    .modal-content {
      background-color: $color-white;
      padding: 0;
      width: 100%;
      margin: auto;
      @include border-radius(0);
      text-align: left;

      @include media(md) {
        width: 95%;
      }

      @include media(lg) {
        width: 920px;
        max-width: 95%;
      }

      @include media(xl) {
        width: 1100px;
      }

      label {
        font-size: 15px;
        color: $color-black;
        margin-bottom: 7px;
        display: block;
        letter-spacing: 0;
        line-height: 22px;

        @include media(md) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      input[type="text"],
      input[type="password"],
      select,
      textarea {
        background-color: $color-secondary-light;
        width: 100%;
        padding: 8px 10px;
        height: 38px;
        color: $color-black;
        margin-bottom: 15px;
        border-radius: 0 !important;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: 200;
        @include transition();

        &:focus {
          border: 1px solid $input-dark;
          outline: 0;
        }
      }

      select::-ms-expand {
        display: none;
      }

      textarea {
        height: 100px;
        margin-bottom: 0px;

        &:-webkit-resizer {
          display: none;
        }
      }

      .modal-header {
        background-color: $color-primary;
        padding: 10px;
        margin-bottom: 10px;

        @include media(md) {
          padding: 15px 20px;
          margin-bottom: 20px;
        }

        @include media(lg) {
          padding: 15px 30px;
        }

        @include media(xxl) {
          padding: 15px 50px;
        }

        .modal-title {
          margin: 0px;
          padding: 0px;
          border-bottom: 0;
        }

        h2 {
          color: $color-black;
          font-weight: 800;
          font-size: 25px;

          @include media(md) {
            font-size: 30px;
          }

          @include media(xl) {
            font-size: 35px;
          }

          @include media(xxl) {
            font-size: 41px;
          }
        }

      }

      .modal-body,
      .modal-footer {
        margin-top: 0px;
        padding: 0 10px 20px 10px;

        @include media(md) {
          padding: 0 20px 30px 20px;
        }

        @include media(lg) {
          padding: 0 30px 30px 30px;
        }

        @include media(xxl) {
          padding: 0 50px 50px 50px;
        }
      }

      .modal-body {
        text-align: left;

        .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;

          @include media(sm) {
            margin-bottom: 30px;
          }
        }
      }

        .contact-submit-cont {
          width: 100%;
          margin: 0;
        }

        .contact-submit-text {
          font-size: 12px;
          color: #999;
          text-align: left;
          margin-bottom: 5px;

          @include media(sm) {
            margin-bottom: 10px;
          }

          @include media(md) {
            font-size: 13px;
          }

          @include media(lg) {
            margin-bottom: 18px;
          }
        }

        input[type="submit"] {
          width: 100%;
          padding: 0 15px;
          min-height: 40px;
          background-color: $color-primary;
          @include animate("background-color, color");
          font-size: 16px;
          font-weight: 800;
          line-height: 20px;
          text-decoration: none;
          color: black;
          text-align: center;
          border: 0px;
          cursor: pointer;
          @include border-radius(0);

          &:focus,
          &:hover {
            color: $color-white !important;
            background: $color-black;
            text-decoration: none !important;
          }

          @include media(md) {
             font-size: 18px;
          }
        }
    }
  }

  .ErrorLabel,
  .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0px;
    padding-left: 15px;
    background-color: $color-primary;
  }
}

#modal-languages {
  .modal-content {
    padding: 20px;
    background-color: $colorv2-blue;

    .modal-header {
      background-color: $colorv2-blue;

      .modal-title {
        font-size: 22px;
        font-weight: 400;
        color: $color-white;

        @include media(md) {
          font-size: 28px;
        }
      }
    }
  }
  .modal-language-country {
    h3 {
      font-size: 16px;
      font-weight: 800;

      @include media(lg) {
        font-size: 18px;
      }
    }

    h4 {
      font-size: 14px;
      font-weight: normal;

      &:last-of-type {
        text-transform: uppercase;
      }
    }
  }
}

#modal-contact {
  .modal-content {
    padding: 0;

    .modal-body {
      padding-bottom: 0px;

      textarea {
        height: 88px;
        margin-bottom: 20px;

        @include media(sm) {
          margin-bottom: 7px;
        }

        @include media(md) {
          height: 138px;
        }
      }

      .recaptcha-cont {
        display: block;
        width: 100%;
        position: relative;
        text-align: center;


        @include media(md) {
          text-align: left;
          min-height: 74px;
        }

        &  div {
         display: inline-block !important;
         padding: 0px !important;
         margin: 0px auto !important;
       }

        & > div {

        @include media(xxs, xxs) {
          @include transform-origin("left top");
            transform-origin: left top;
          }
        }
      }

      & > span {
          &:first-of-type {
              display: inline-block;
              padding-bottom: 4rem;
          }
      }
    }

    .modal-footer {
      @include flex-block();
      @include flex-flow(column, wrap);
      padding-top: 20px;

      @include media(sm) {
        @include flex-block();
        @include align-items(flex-start);
        @include flex-flow(row, nowrap);
      }

      .contact-upload-cont {
        @include flex-block;
        @include align-items(center);
        width: 100%;
        margin: 0px;
        margin: 20px 0;
        margin-top: auto;

        @include media(sm) {
          display: block;
          width: 35%;
          margin: 0;
          margin-top: auto;
          padding-right: 13px;
        }

        @include media(md) {
          padding-right: 44px;
        }

        @include media(lg) {
          width: 25%;
          padding-right: 20px;
          margin-top: auto;
        }

        input[type="file"] {
          display: none;
        }

        .contact-upload-text {
          font-size: 14px;
          text-align: left;
          order: 2;
          padding-left: 5px;
          line-height: 19px;

          @include media(sm) {
            font-size: 14px;
            margin-bottom: 10px;
            padding-left: 0;
          }

          @include media(lg) {
            font-size: 18px;
            margin-bottom: 18px;
          }
        }

        .contact-upload-button {
          min-width: 125px;
          font-size: 16px;
          order: 1;
          word-break: keep-all;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @include media(sm) {
            width: 100%;
          }
        }
      }

      .contact-submit-cont {
        @include media(sm) {
          width: 68%;
        }

        @include media(lg) {
          width: 75%;
        }

        .contact-submit-text {
          line-height: 19px;

          @include media(sm) {
            text-align: right;
          }
        }
        .gdpr {
            margin-bottom: 3px;
            @include media(sm) {
                text-align: right;
            }
        }
      }
    }
  }
}

#modal-sign-up {

  .modal-content {
    padding: 0;

    .modal-body {
      //padding-bottom: 10px;
    }
  }
}

#modal-login {
    .form-control-error{
        padding:5px 10px;
        color: #000;
        background: #ffd100;
        font-size: 14px;
        margin:-10px 0 15px 0;
    }
    .form-control-error[style*="display: inline"]{
        display:block !important;
    }
  .modal-content {
    padding: 0;
    max-width: 800px;

    .modal-body {
      padding-bottom: 10px;
      table{
          width:100%;
      }
    }


    .contact-submit-text {
      margin-top: 7px;
      margin-bottom: 0px;

      @include media(sm) {
        margin-top: 10px;
        margin-bottom: 0px;
      }

      @include media(lg) {
        margin-bottom: 0px;
      }
    }
  }
}

.product-registration-box {
  margin-top: 50px;

  .modal-dialog {
    .modal-content {
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin: 0;
      border: 0;
      @include border-radius(0);
      background-color: $color-secondary-light;

      .modal-header {
        padding: 0;

        .modal-title {
          padding: 10px;
          font-size: 30px;
          line-height: 35px;

          @include media(sm) {
            padding: 10px 20px;
            font-size: 35px;
            line-height: 40px;
          }

          @include media(lg) {
            font-size: 40px;
            line-height: 45px;
          }
        }
      }

      .modal-body,
      .modal-footer {
        margin-top: 0px;
        padding: 0 7px 15px 7px;

        @include media(md) {
          padding: 0 20px 20px 20px;
        }
      }

      .modal-body {
        padding-bottom: 5px;

        label {
          font-size: 13px;
          margin-bottom: 5px;
          line-height: 19px;

          @include media(md) {
            font-size: 15px;
          }

          @include media(lg) {
            font-size: 18px;
            margin-bottom: 10px;
            line-height: 22px;
          }
        }

        input[type="text"],
        input[type="password"],
        select,
        textarea {
          background: $color-white;

          &:focus {
            background: $input-light;
          }
        }
      }

      .modal-footer {
        margin: 0;
      }
    }
  }

  &-add-inputs {
    display: inline-block;
    font-size: 13px;
    color: $input-darken !important;
    margin-bottom: 15px;
  }

  &-add-more-product {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
    border-bottom: 2px solid $color-black;

    @include media(md) {
      padding-bottom: 20px;
    }

    @include media(lg) {
      padding-top: 25px;
    }
  }
}

#modal-serial-number {
  .modal-content {
    max-width: 800px;
  }
  .modal-header {

    @include media(xxl) {
      padding: 25px 50px;
    }

    .modal-title {
     font-size: 25px;
     line-height: 25px;

     @include media(md) {
       font-size: 30px;
       line-height: 30px;
     }

     @include media(lg) {
       font-size: 35px;
       line-height: 35px;
     }

     @include media(xxl) {
       font-size: 41px;
       line-height: 41px;
     }

    }
  }


  .modal-body {
    .modal-serial-number-image {
      width: 100%;
    }
  }
}

.field-required {
  &:after {
    content: "*";
    color: #ff5445;
  }
}

.close,
.close-yellow,
.close-black {
  $size: 30px;

  position: relative;
  font-size: 0;
  width: $size;
  height: $size;
  border-radius: $size;
  opacity: 1 !important;
  border: 1px solid $color-white !important;
  outline: 0;
  text-shadow: none !important;

  &:after {
    content: "\e919";
    @include icomoon();
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
    color: $color-white;
    @include transition(100ms);
    cursor: pointer;
    font-size: 14px;
  }

  &:focus,
  &:hover {
    background-color: $color-white !important;
    outline:0;

    &:after {
      color: $color-black;
    }
  }
}


.close-yellow {
  border: 1px solid $color-black !important;

  &:after {
    color: $color-black !important;
  }

  &:focus,
  &:hover {
    background-color: $color-black !important;

    &:after {
      color: $color-primary !important;;
    }
  }
}

.padding-footer {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
