@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";

.hp-heroimage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &-container {
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($color-black, 0.43);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &-content {
    z-index: 9;
    flex-grow: 1;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    .pux-container {
      display: flex;
      width: 100%;
    }
    &-inner {
      position: relative;
      width: 100%;
      padding-top: 35px;
      display: flex;
    }
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 60px 0;
      @include media(md) {
        flex-grow: 1;
        padding-right: 5%;
        padding: 0 0 80px;
      }
      .btn-primary {
        margin-top: 15px;
      }
    }
    &-circles {
      display: none;
      @include media(md) {
        display: block;
        flex: 0 0 110px;
        max-width: 110px;
        padding-top: 10%;
      }
      @include media(xl) {
        flex: 0 0 150px;
        max-width: 150px;
      }
      .colored-circle {
        &:not(:first-of-type) {
          margin-top: 15px;
          @include media(lg) {
            margin-top: 20px;
          }
          @include media(xl) {
            margin-top: 25px;
          }
          @include media(xxl) {
            margin-top: 28px;
          }
        }
      }
    }
  }
}

.colored-box {
  padding: 23px 25px 13px;
  line-height: 1.4;
  font-size: 16px;
  text-decoration: none;
  display: block;
  flex: 0 1 100%;
  max-width: 100%;
  @include media(sm) {
    flex: 0 1 50%;
    max-width: 50%;
  }
  @include media(md) {
    font-size: 18px;
    flex: 0 1 33.33%;
    max-width: 33.33%;
  }
  @include media(xl) {
    font-size: 2rem;
  }
  @include media(xxxl) {
      max-width: 35rem;
  }
  h1,
  h2,
  h3,
  h4,
  big {
    font-family: 24px;
    padding: 0;
    margin: 0;
    line-height: inherit;
    font-size: 24px;
    font-weight: 600;
    @include media(md) {
      font-size: 26px;
    }
    @include media(xl) {
      font-size: 3.2rem;
    }
  }
  &:not(.white-type) {
    h1,
    h2,
    h3,
    h4,
    big {
      color: $color-white;
    }
    .arrow-link {
      color: $color-white;
      &:after {
        color: $color-white;
        border-color: $color-white;
      }
      &:hover,
      &:focus {
        &:after {
          color: $color-black;
          border-color: $color-white;
          background-color: $color-white;
        }
      }
    }
  }
  .arrow-link {
    margin-top: 15px;
  }
  &.black-type {
    background-color: $color-black;
    color: $color-white;
  }
  &.blue-type {
    background-color: $main-color;
    color: $color-white;
    .arrow-link {
      &:hover,
      &:focus {
        color: $color-white;
        &:after {
          color: $color-black;
          border-color: $color-white;
        }
      }
    }
  }
  &.white-type {
    background-color: $color-white;
    color: $color-black;
    @include media(sm, sm) {
      flex: 0 1 100%;
      max-width: 100%;
    }
  }
}

.division-box {
  margin: 0 -15px;
}

.division-item-news-box {
  flex: 0 0 100%;
  max-width: 100%;

  @include media(sm) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include media(xl) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.hp-news-container {
  display: flex;
  flex-wrap: wrap;
}

.division-item-news {
  display: block;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  position: relative;
  text-decoration: underline;
  color: $color-white;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  transition: 0.3s ease all;
  &:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 11;
    background: rgb(0,120,192);
    background: linear-gradient(0deg, rgba(0,120,192,1) 0%, rgba(0,120,192,0.5) 35%, rgba(0,0,0,0) 100%);
  }

  /*&.black-text {
    color: $color-black;

    .division-item-news-text h3 {
      border-bottom: 1px solid $color-black;
    }
  }*/

  &:hover,
  &:focus {
    color: #fff;

    h3 {
      color: #fff;
    }
    .division-item-news-text h3 {
      //border-bottom: 1px solid $color-primary;
    }
    &:before{
      background: linear-gradient(0deg, rgba(0,120,192,1) 0%, rgba(0,120,192,0.5) 55%, rgba(0,0,0,0) 100%);
    }
    &.black-text {
      color: #fff;

      h3 {
        color: #fff;
      }
    }
  }

  .division-item-news-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    z-index: 22;

    @include media(lg) {
      padding: 3rem 6rem;
    }

    h3 {
      @include pux-scale-with-min('font-size', 26px, 20px);
      @include pux-scale-with-min('line-height', 32px, 25px);
      font-weight: 400;
      border-bottom: 1px solid $color-white;
      padding-bottom: 1rem;
      padding-right: 2rem;
    }
    .division-item-news-date {
      padding-right: 2rem;
    }
  }
}

.colored-box-container {
    position: relative;
    z-index: 8;
    width: 100%;
    &-inner {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        flex-wrap: wrap;
        @include media(xxxl) {
            justify-content: flex-end;
            position: absolute;
            bottom: 0;
            width: 56%;
            right: 0;
        }
    }
}

.talents-testimonial-item{
  max-width: 1055px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  @include media(sm){
      flex-wrap: nowrap;
  }
  .talents-testimonial-item-image{
      min-width: 165px;
      width: 165px;
      margin: 0 auto 6rem auto;
      @include media(sm){
          margin: 0 4rem 0 0;
      }
  }
  .talents-testimonial-item-desc{
      background-color: #A5A5A5;   
      padding: 2.7rem 2.7rem 1.5rem 2.7rem;
      border-radius: 10px;
      position: relative;
      width: 100%;
      @include media(sm){
          width: auto;
          border-radius: 0px 10px 10px 10px;
      }
      &:before{
          content:"";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 20px 20px 20px;
          border-color: transparent transparent #A5A5A5 transparent;
          position: absolute;
          top: -20px;
          left: 50%;
          transform: translateX(-50%);
          @include media(sm){
              top: 0;
              left: -20px;
              transform: translateX(0);
              border-width: 0 20px 20px 0;
              border-color: transparent #A5A5A5 transparent transparent;
          }
      }
  }
  .talents-testimonial-item-bottom{
      margin-top: 10px;
  }
  .talents-testimonial-item-name{
      display: inline-block;
      color: #000;
      font-weight: 600;
  }
}

.talents-testimonial{
  margin: 30px 0;
  @include media(md){
    margin: 50px 0;
  }
}