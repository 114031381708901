
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.IE11 {
    .hp-heroimage-content-title {
        min-height: 80vh;
    }
    .blueheart-banner-title {
        @include media(lg) {
            padding-top: 50rem;
        }
    }
}
