
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";


html {
  font-size: $scale-base-size*$scale-ratio-xxs;
  @include media(xs) {
    font-size: $scale-base-size*$scale-ratio-xs;
  }
  @include media(sm) {
    font-size: $scale-base-size*$scale-ratio-sm;
  }
  @include media(md) {
    font-size: $scale-base-size*$scale-ratio-md;
  }
  @include media(lg) {
    font-size: $scale-base-size*$scale-ratio-lg;
  }
  @include media(xl) {
    font-size: $scale-base-size*$scale-ratio-xl;
  }
  @include media(xxl) {
    font-size: $scale-base-size*$scale-ratio-xxl;
  }
  @include breakpointhelper(2048px, 0) {
      font-size: $scale-base-size*$scale-ratio-2048;
  }
  @include breakpointhelper(2304px, 0) {
      font-size: $scale-base-size*$scale-ratio-2304;
  }
  @include breakpointhelper(2560px, 0) {
    font-size: $scale-base-size*$scale-ratio-2560;
  }
  @include breakpointhelper(2880px, 0) {
      font-size: $scale-base-size*$scale-ratio-2880;
  }
  @include breakpointhelper(3440px, 0) {
      font-size: $scale-base-size*$scale-ratio-3440;
  }
  @include breakpointhelper(3840px, 0) {
      font-size: $scale-base-size*$scale-ratio-3840;
  }
}

$px-values: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 130, 140, 170);

@each $value in $px-values {
	.pt-#{$value} {
    padding-top: calc(#{$value}rem / 10);
	}

  .pb-#{$value} {
      padding-bottom: calc(#{$value}rem / 10);
  }

  .mt-#{$value} {
    margin-top: calc(#{$value}rem / 10);
  }

  .mb-#{$value} {
      margin-bottom: calc(#{$value}rem / 10);
  }
}

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@include font-face("Graphik", "Graphik-SemiBold", 600);
@include font-face("Graphik", "Graphik-Regular", 400);
@include font-face("Graphik", "Graphik-Medium", 500);

body,
input,
select,
textarea {
    font-family: 'Graphik', $font-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include pux-scale-with-min('font-size', 20px, 17px);
    @include pux-scale-with-min('line-height', 20px, 17px);
}

body{
  main{
    line-height: 1.6;
  }
}

table {
  p {
    margin: 3px 0;
  }
  margin: 20px 0 1rem;
  @include pux-scale-with-min('font-size', 16px, 12px);
  @include pux-scale-with-min('line-height', 16px, 12px);
  td {
    font-weight: 300;
    padding: 0.5rem;
    big {
      @include pux-scale-with-min('font-size', 23px, 14px);
    @include pux-scale-with-min('line-height', 23px, 17px);
    }
  }
  th {
    text-align: unset;
    background-color: $main-color;
    color: $color-white;
    padding: 0.5rem 1rem;
    @include pux-scale-with-min('font-size', 19px, 17px);
    @include pux-scale-with-min('line-height', 22px, 20px);
  }
  .marker {
    color: $main-color;
    font-weight: bold;
  }
}
.text-right{
    text-align:right;
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight:600;
}

.h1, .h2, .h3, .h4, .h5, .h6{
    display: block;
}

h1,.h1 {
  @include pux-scale-with-min('font-size', 71px, 40px);
  @include pux-scale-with-min('line-height', 80px, 45px);
    &.title-line {
        color: $main-color;
        //background-size: 100% 35px;
        //background-position: 0 30px;
        //line-height: 35px;
        display: inline-block;
        //background-image: linear-gradient($color-orange 4px, transparent 3px);
        text-decoration: underline;
        text-decoration-color: $color-orange;
        //@include media(sm) {
            //background-size: 100% 39px;
            //background-position: 0 36px;
        //}
        @include media(md) {
            //background-size: 100% 48px;
            //background-position: 0 44px;
            text-decoration: underline;
            text-decoration-color: $color-orange;
            //background-image: linear-gradient($color-orange 4px, transparent 3px);
        }
    }
}

h2,.h2 {
  @include pux-scale-with-min('font-size', 58px, 24px);
}

h3,.h3 {
  @include pux-scale-with-min('font-size', 36px, 20px);
    margin:15px 0 10px;
    @include media(xl){
        margin:20px 0 10px;
    }
}

h4,.h4 {
  @include pux-scale-with-min('font-size', 18px, 16px);
  margin:15px 0 10px;
  @include media(xl){
      margin:20px 0 10px;
  }
}

.new-h2 {
    @include media(md) {
        @include pux-scale-with-min('font-size', 110px, 40px);
        @include pux-scale-with-min('line-height', 110px, 40px);
    }
 }


strong,
.bold,
b{
    font-weight:600;
}

.semibold{
    font-weight:600;
}

.no-href,
a {
    text-decoration: underline;
    color: $color-link;
    cursor: pointer;
    outline: none;

    //@include animate(color);

    &:hover {
        text-decoration: none;
        color: $color-link-hover;
    }
}



.logon-password-retrieval-button,
.btn{
    @include border-radius(0);
    cursor:pointer;
    color:#000;
    border:1px solid #000;
    height:30px;
    @include box-shadow(none);
    padding:0 15px;
    outline:0 !important;
}

.logon-password-retrieval-button,
.btn-primary{
    background-color: #999;
    &:focus,
    &:hover{
        background-color: #000;
        color:#999;
        border:1px solid #000;
    }
}

.btn-primary,
.arrow-down-link{
    background-color: $main-color;
    color: $color-white !important;
    padding: 15px 25px;
    border: 2px solid $main-color !important;
    @include transition;
    font-weight: 500;
    @include pux-scale-with-min('font-size', 20px, 16px);
    text-decoration: none;
    display: inline-block;
    height: auto;
    &:focus,
    &:hover{
        text-decoration: none !important;
        background-color: $color-black;
        color: $color-white;
    }
}

.btn-default {
  background-color: $color-white;
  color: $color-primary !important;
  padding: 15px 25px;
  border: 2px solid $main-color !important;
  @include transition;
  font-weight: 500;
  @include pux-scale-with-min('font-size', 20px, 16px);
  text-decoration: none;
  display: inline-block;
  height: auto;
  &:focus,
  &:hover{
      text-decoration: none !important;
      background-color: $color-primary;
      color: $color-white !important;
  }
}

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid $color-black;
  @include animate("background-color, color");
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  color: $color-white;
  text-align: center;


  &:focus,
  &:hover {
    color: $color-primary !important;
    background: $color-black !important;
    text-decoration: none !important;
  }
}

.square-button-bw {
  &:focus,
  &:hover {
    color: $color-white !important;
    background: $color-black;
  }
}

.big-button-bw,
.big-button-bw-small {
	width: 100%;
	padding: 0 15px;
	min-height: $btn-height;
	line-height: $btn-height - ($btn-border-width*2);
	vertical-align: middle;
	display: inline-block;
	font-size: 18px;
	color: $color-white;
	background: $color-grey;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	text-align: center;
	@include animate("background, color");
	font-weight: 600;

	&:hover,
  &:focus {
		color: $color-white !important;
		background: $color-black;
    text-decoration: none;
	}
}

.big-button-bw-small {
	font-size: 15px;
  padding: 0 10px;

  @include media (md) {
    font-size: 18px;
    padding: 0 20px;
  }
}

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid $color-white !important;
  color: $color-white !important;
  text-shadow: 0;
  opacity: 1;
  font-size: 15px;
  @include transition(100ms);

  &:hover,
  &:focus {
    color: $color-black !important;
    background-color: $color-white;
  }
}

.colored-title {
    h1,
    h2
    h3,
    h4 {
        color: $color-primary;
    }
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.items-container{
  &.loading{
    position: relative;
    min-height: 130px;
    &:before{
      content: '';
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 0.2rem solid transparent;
      border-top-color: $main-color;
      border-bottom-color: $main-color;
      animation: spinner .8s ease infinite;
      position: absolute;
      left:50%;
      top: calc(50% - 25px);
      z-index: 33;
    }
    &:after{
      content:"";
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: 22;
      background: rgba(255,255,255,0.5);
    }
  }
}
