@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";

.devider-title {
  margin-top: 40px;
  @include media(md) {
      margin-top: 50px;
  }
  @include media(xl) {
      margin-top: 65px;
  }
  .pux-container {
      padding: 0;
  }
  .flex-box {
      flex-wrap: wrap;
  }
  a {
      color: $color-black;
  }
}

.division-box {
  flex-wrap: wrap;
}
.division-box-container.two-cards,
.division-box-container.four-cards {
  .products-item-card-box {
    padding: 1.5rem;
  }
  .product-item-card {
    padding-bottom: 80%;
    &-inner {
      display: block;
    }
    &-img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 100%;
      width: 100%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      img {
        padding-top: 0;
        height: auto !important;
      }
    }
  }
  h4 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}




.two-cards .division-box {
  .products-item-card-box,
  .download-item-box {
    flex: 0 0 50%;
    max-width: 50%;
  }
  h4 {
    display: none;
  }
}

.three-cards .division-box{
  margin: 0 -7px;
  padding-top: 10px;
  .products-item-card-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 7px;
    @include media(sm) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
  .products-item-card {
    height: 0;
    padding-bottom: 62%;
  }
}
.four-cards .division-box{
  .products-item-card-box {
    flex: 0 0 50%;
    max-width: 50%;
    @include media(md) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  h4 {
    display: none;
  }
}

//..................Innovation widget (selected products)

.innovation {
  background-color: $grey-background;
  padding: 1.5rem;

  .devider-title {
    margin-top: 0;
  }

  .division-box {
    margin: 3rem 0 0 0;
    @include media(md) {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;
    }

    .row {
      margin-left: 0;
      margin-right: 0;
      @include media(sm) {
        margin: 0 -7px;
      }
    }

    &-perex,
    &-perex-respo {
      @include pux-scale-with-min('font-size', 51px, 30px);
      @include pux-scale-with-min('line-height', 48px, 30px);
      font-weight: 800;
      margin-bottom: 2rem;
      display: none;
      @include media(md) {
        display: block;
      }
      &-respo {
        display: block;
        padding: 0 0 10px 0;
        @include media(md) {
          display: none;
        }
      }
    }
  }

  .division-box-products {
    width: 100%;
    @include media(md) {
      width: auto;
    }
  }

  .products-item-card-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 7px 0;
    @include media(sm) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 7px;
    }
  }

  .product-item-card {
    position: relative;
    padding-bottom: 47%;
    border-color: transparent;
    height: 100%;
    h4 {
      color: $color-white;
    }
  }


  .division-box-innovator {
    position: relative;
    &-person {
      display: flex;
      align-items: flex-end;
      margin: 3rem 0;
      @include media(md) {
        display: block;
        margin: 0;
      }
    }
    img {
      max-width: 20rem;
      @include media(md) {
        margin-bottom: 7px;
        max-width: 30rem;
      }
      @include media(xxl) {
        min-width: 38rem;
        max-width: none;
      }
    }
    &-text {
      width: 100%;
      z-index: 2;
      font-weight: 800;
      @include media(md) {
        color: $color-white;
        text-align: center;
        position: absolute;
        bottom: 2rem;
        left: 0;
      }
      &-name {
        @include pux-scale-with-min('font-size', 21px, 18px);
        @include pux-scale-with-min('line-height', 21px, 18px);
      }
      &-position {
        @include pux-scale-with-min('font-size', 14px, 13px);
        @include pux-scale-with-min('line-height', 14px, 13px);
      }
    }
  }
}

//..................Markets widget (solutions)

.solutions-container {
  display:  flex;
  flex-wrap: wrap;
  margin: 2rem -3.5px 0 -3.5px;
  @include media(md) {
    flex-wrap: nowrap;
    margin: 2rem 0 0 0;
    width: 100%;
  }
.main-card {
  width: 100%;
  @include media(md) {
    @include flex(0 0 40%);
    max-width: 40%;
    width: 40%;
    margin: 0;
  }
  .products-item-card-box{
    @include media(md) {
      height: 100%;
      width: 100%;
      padding: 0 4px 0 0;
    }

    .product-item-card {
      @include media(md) {
        padding-bottom: 0%;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.other-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @include media(md) {
    flex: 0 0 60%;
    max-width: 60%;
    width: 60%;
  }
  .products-item-card-box {
    flex: 0 0 50%;
    max-width: 50%;
    padding-top: 7px;
    @include media(md) {
      flex: 0 0 33.333%;
      max-width: 33.3333%;
      padding-top: 0;
      &:first-child,
      &:nth-child(2)
      &:nth-child(3) {
        padding-bottom: 7px
      }
    }
  }
}
.products-item-card-box {
  padding: 0 3.5px;
  @include media(md) {
    padding: 0 0 0 14px;
  }
}
.products-item-card {
  position: relative;
  display: block;
  padding-bottom: 60%;
  height: 0;
  border-color: transparent;
  &-img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
    h4 {
      position: absolute;
      color: $color-white;
    }
  }
}

//..................Download widget

.hp-download-widget {
  display: flex;
  flex-wrap: wrap;

  .download-item-box {
    flex: 0 0 100%;

    @include media(sm) {
      flex: 0 0 50%;
      max-width: 50%;
    }


    &:nth-child(odd) {
      .colored-title {
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;

        @include media(xl) {
            padding-left: 17%;
        }
      }
    }
  }

  .download-item.locked {
      position: relative;
      &:before {
          display: none;
      }
      h3 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:before {
              content: "\e90a";
          }
      }
  }

  .download-item {
    display: block;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 0;
    padding-bottom: 65%;
    position: relative;

    &:hover,
    &:focus {
      .btn-primary {
        color: $color-primary !important;
        border-color: $color-primary !important;
      }
    }

    .perex {
      display: none;
    }

    .colored-title {
      padding-top: 4rem;
      padding-left: 15px;
      padding-right: 15px;

      @include media(sm) {
        padding-right: 1.5rem;
        padding-left: 10rem;
      }

      @include media(xl) {
        padding-top: 8rem;
        padding-left: 14rem;
      }

      h3 {
        text-decoration: none;
        color: $color-white;
        margin: 0;
        @include pux-scale-with-min('font-size', 90px, 40px);
        @include pux-scale-with-min('line-height', 90px, 40px);
      }
    }

    .btn-primary {
      position: absolute;
      right: 7rem;
      bottom: 4rem;
      background: none !important;
      @include pux-scale-with-min('font-size', 26px, 20px,  !important);
      @include pux-scale-with-min('line-height', 32px, 25px,  !important);
      font-weight: 400;
      border-bottom: 1px solid $color-white !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      padding: 0 2rem 1rem 0;
      max-width: 100%;
    }

  }

  .download-item-image {
    display: none;
  }
}


//..................Testimonial widget

.testimonial-container {
  padding: 9rem 0;
  background-position: top left, bottom right;
  background-size: 80% auto;
  background-repeat: no-repeat;
  @include media(xxxl) {
    margin-left: -50px;
    margin-right: -50px;
  }
}

.testimonial {
  position: relative;
  display: flex;
  background-color: $main-color;
  color: $color-white;
  border-radius: 20px;
  padding: 4rem 3rem 3rem 3rem;
  margin: 0 2rem;
  @include pux-scale-with-min('font-size', 29px, 20px);
  font-weight: 500;
  box-shadow: 0 0 75px 1px rgba(0, 0, 0, 0.09);
  line-height: 1.25;
  @include media(xxxl) {
    margin: 0 50px;
    padding: 2rem 3rem 3rem 3rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 7rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 83px 130px 0 0;
    border-color: $main-color transparent transparent transparent;
  }

  .devider-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 1.5rem 0;

    h2,
    h3,
    a {
      color: $color-white;
      &:after {
        color: $color-white;
        border: 2px solid $color-white;
      }
      &:hover,
      &:focus {
        &:after {
          color: $main-color;
          background-color: $color-white;
        }
      }
    }
  }

  &-people {
    text-align: center;
    margin: 0 -3rem;
    @include media(md) {
      display: flex;
    }
    &-container {
      width: 100%;
    }
    .person-item-box {
      @include media(md) {
        flex: 0 0 33.333%;
        max-width: 33.3333%;
        padding: 0 3rem;
      }
    }

    .person-item {
      @include pux-scale-with-min('font-size', 16px, 14px);
      font-weight: 400;
      max-width: 50rem;
      margin: 0 auto;
      &-image {
        border-radius: 50%;
        width: 25rem;
        height: 25rem;
        overflow: hidden;
        margin: 3rem auto 1rem auto;
        position: relative;
        img {
          min-width: 100%;
        }
        h4 {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          text-shadow: 0 5px 9px rgba(3, 4, 3, 0.37);
          max-width: 100%;
          margin-bottom: 4.5rem;
          @include pux-scale-with-min('font-size', 23px, 18px);
          @include pux-scale-with-min('line-height', 23px, 18px);
        }
      }
    }
  }

  &-background {
    display: none;
    @include media(xxl) {
      position: static;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 6rem;
      img {
        flex-shrink: 0;
      }
    }
  }
}
