@import "_variables.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";


.social {
  font-size: 0;
  ul {
    display: flex;
    li {
      a {
        margin-left: 5px;
      }
    }
  }
  .ico-social {
    display: inline-block;
    @include border-radius(50%);
    margin: 5px;
    height: 27px;
    width: 27px;
    text-decoration: none !important;
    position: relative;

    &:before {
      color: $color-black;
      @include animate(color);
      font-size: 30px;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:focus,
    &:hover {
      &:before {
        color: $color-primary;
      }
    }

    &:first-child {
      margin: 5px 5px 5px 0;

      @include media(md) {
        margin: 5px;
      }
    }

    @each $name,
    $icon-number in $share-icon-names {
      &.s-#{$name} {
        &:before {
          content: "#{$icon-number}";
        }
      }
    }
  }
}


.dark-box {
  padding: 0 8px 15px 8px;
  width: 100%;

  @include media(md) {
    flex: 0 1 50%;
    max-width: 50%;
  }
}



.nwl-box {
  .ErrorLabel {
    color: #000;
    display: block;
    transform: translateY(10px);
    font-size: 14px;

    @include media(lg) {
      font-size: 18px;
    }
  }
}

.newsletter-row {
  padding: 0 0 25px;
  @include flex-block;
  @include align-items(flex-start);
  @include justify-content(center);
  flex-direction: column;

  @include media(sm) {
    flex-direction: row;
  }

  //@include flex-wrap(wrap);
  .ErrorLabel {
    color: #000;
  }

  .EditingFormErrorLabel {
    margin-top: 5px;
    padding: 5px 7px;
  }

  &-input {
    padding: 5px 5px;
    width: 230px;

    input {
      width: 220px;
      background-color: $color-black;
      border: 2px solid $color-white;
      margin: 0;
      height: 54px;
      color: $color-white;

      &:focus {
        background-color: transparent;
        color: $color-white;
        border: 2px solid $color-white;
      }
    }
  }

  &-btn {
    width: 120px;
    padding: 5px 5px;

    .btn {
      padding: 15px 10px;
      height: 54px;
      color: $color-white;
      font-size: 14px;
      border: 2px solid $main-color;
    }
  }

  & * {
    @include media(xxs, xs) {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.slim-title {
  @include media(xxl) {
    padding-left: 15%;
    padding-right: 15%;
  }
}


.category-bottom-text,
.category-top-text {
  max-width: 990px;
}

.category-item {

  &-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
  }
}

.box-header {
  position: relative;
  min-height: 87px;
  margin: 0 auto;
  margin-bottom: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  color: $color-white;

  @include media(xs) {
    min-height: 200px;
  }

  @include media(sm) {
    min-height: 250px;
  }

  @include media(md) {
    min-height: 300px;
    margin-bottom: 40px;
  }

  @include media(xl) {
    min-height: 352px;
    margin-bottom: 70px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, .5);
    z-index: 0;
  }

  .box-header-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 70px 0 10px;

    @include media(xs) {
      padding: 80px 0 20px;
    }

    @include media(md) {
      padding: 90px 0 20px;
    }

    @include media(xl) {
      padding: 100px 0 20px;
    }

    h1 {
      display: none;
    }

    .svg-title {
      margin-top: 30px;

      @include media(md) {
        margin-top: 45px;
      }

      @include media(xl) {
        margin-top: 55px;
      }
    }
  }
}



.svg-title {
  width: 100%;
  filter: contrast(1.8);
  overflow: visible !important;

  text {
    font-size: 50px;
    stroke-width: 2px;
    text-shadow: 0 12px 11px rgba(3, 4, 3, 0.5);
    font-weight: 600;
    fill: transparent;

    @include media(md) {
      font-size: 60px;
    }

    @include media(xl) {
      font-size: 73px;
    }

    &:not(.generated-text) {
      visibility: hidden;
    }
  }
}

.full-height-image {
  .pux-container {
    height: 100%;

  }

  .box-header-content {
    @include media(xl) {
      min-height: 100vh;
      height: 100vh;
    }
  }

  .svg-title-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .svg-title {
    margin-top: -115px !important;
  }
}

.breadcrumbs {
  z-index: 9997;
  color: inherit;
  line-height: 18px;
  padding-right: 80px;
  position: relative;
  margin-bottom: 2.5rem;

  a,
  span {
    margin-top: -5px;
    color: $color-white !important;
  }

  .CMSBreadCrumbsLink,
  .CMSBreadCrumbsCurrentItem {
    color: inherit;
    font-size: 16px;

    @include media(md) {
      font-size: 19px;
    }
  }

  .breadcrumb-separator {
    font-size: 16px;
    display: inline-block;
    margin: 0 5px;

    @include media(md) {
      font-size: 19px;
    }
  }

  a {
    color: $color-white;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.colored-circle {
  overflow: hidden;
  position: relative;
  background-image: url('/Prolyte/media/system/img/circle.svg');

  &:before {
    content: "";
    padding-top: 100%;
    display: block;
  }

  &-bg {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &-inner {
    color: $color-white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;

    @include media(xl) {
      font-size: 19px;
    }

    h1,
    h2,
    h3,
    h4,
    big {
      font-family: 24px;
      padding: 0;
      margin: 0;
      line-height: inherit;

      @include media(xl) {
        font-size: 31px;
      }
    }
  }
}

.arrow-link-in a,
.devider-title a,
.arrow-link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: lowercase;
  text-decoration-color: none;
  text-decoration: none;
  @include transition;

  @include media(xl) {
    font-size: 20px;
  }

  &:after {
    content: "\e908";
    @include icomoon;
    display: inline-block;
    font-size: 24px;
    color: $color-black;
    font-weight: 600;
    border: 2px solid $color-black;
    @include transition;
    border-radius: 100%;
    margin-left: 11px;

    @include media(md) {
      font-size: 26px;
    }

    @include media(lg) {
      font-size: 30px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;

    &:after {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

.arrow-link-back,
.content-button-level-up,
.arrow-link-back-in a {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: lowercase;
  text-decoration-color: none;
  text-decoration: none;
  color: $color-black;
  @include transition;

  @include media(xl) {
    font-size: 20px;
  }

  &:before {
    content: "\e90d";
    @include icomoon;
    display: inline-block;
    font-size: 24px;
    color: $color-black;
    font-weight: 600;
    border: 2px solid $color-black;
    @include transition;
    border-radius: 100%;
    margin-right: 11px;

    @include media(md) {
      font-size: 26px;
    }

    @include media(lg) {
      font-size: 30px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $color-black;

    &:before {
      background-color: $color-black;
      color: $color-white;
    }
  }
}

.content-button-level-up {
  margin-bottom: 15px;
}

.devider-title,
.colored-title {

  h2,
  h3 {
    font-size: 28px;
    color: $main-color;
    //ackground-image: linear-gradient($color-orange 3px, transparent 3px);
    background-size: 100% 32px;
    background-position: 0 28px;
    line-height: 32px;
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: $color-orange;

    @include media(md) {
      font-size: 32px;
      background-size: 100% 36px;
      background-position: 0 32px;
      line-height: 36px;
      //background-image: linear-gradient($color-orange 4px, transparent 4px);
    }

    @include media(xl) {
      font-size: 42px;
      background-size: 100% 46px;
      background-position: 0 42px;
      line-height: 46px;
    }
  }
}

.colored-title-small {

  h2,
  h3 {
    font-size: 22px;
    color: $main-color;
    //background-image: linear-gradient($color-orange 3px, transparent 3px);
    //background-size: 100% 25px;
    //background-position: 22px;
    line-height: 25px;
    display: inline-block;
    text-decoration: underline;
    text-decoration-color: $color-orange;

    @include media(md) {
      font-size: 24px;
      //background-size: 100% 28px;
      //background-position: 0 24px;
      line-height: 28px;
      //background-image: linear-gradient($color-orange 4px, transparent 4px);
    }

    @include media(xl) {
      font-size: 28px;
      //background-size: 100% 32px;
      //background-position: 0 28px;
      line-height: 32px;
    }
  }
}


.content-mobile-menu {
  display: none;
  margin: 2rem 0 4rem 0;
  font-size: 16px;

  &:hover {
    color: $color-base !important;
    background: $btn-bg;
  }

  &:after {
    display: inline-block;
    vertical-align: middle;
    @include icomoon();
    content: "\e903";
    margin-left: 5px;
    margin-top: -3px;
    font-size: 35px;
    @include animate(transform);
  }

  &.active {
    &:after {
      @include rotate(180deg);
    }
  }

  @include media(xxs, sm) {
    display: block;
  }
}

.content-menu,
.products-menu {
  width: 100.25%;
  @include list-reset();
  margin: 4rem 0;
  color: $color-black;

  @include media(md) {
    display: block !important;
  }

  @include media(md) {
    margin-top: 0px;
  }

  .content-menu-item,
  .products-menu-item {
    &>a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      line-height: 17px;
      color: inherit;
      font-weight: 600;
      padding: 5px 5px;
      @include animate("background-color, color");

      @include media(md) {
        font-size: 14px;
      }

      @include media(lg) {
        font-size: 16px;
        font-weight: 800;
        padding: 5px 10px;
      }

      @include media(xl) {
        font-size: 18px;
        line-height: 20px;
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-black;
      }
    }

    &.active>a {
      background-color: $color-primary;
      color: $color-black;
    }

    &.active .content-submenu,
    &.active .products-submenu {
      display: block;
    }

    .content-submenu,
    .products-submenu {
      width: 100%;
      @include list-reset();
      background-color: $color-secondary-light;
      padding: 0 10px;
      margin-bottom: 10px;
      text-decoration: none;
      display: none;

      .content-submenu-item,
      .products-submenu-item {
        &>a {
          position: relative;
          display: block;
          padding: 7px 0 7px 10px;
          color: $color-black;
          font-size: 16px;
          line-height: 17px;
          text-decoration: none;

          @include media(md) {
            padding: 5px 0 5px 10px;
            font-size: 13px;
          }

          @include media(lg) {
            font-size: 16px;
          }

          @include media(xl) {
            font-size: 18px;
            line-height: 20px;
          }

          &:before {
            display: block;
            content: "";
            width: 4px;
            height: 4px;
            background: $color-base;
            @include border-radius(10px);
            position: absolute;
            left: 0;
            top: 11px;

            @include media(xl) {
              top: 14px;
            }

          }

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .active {
          font-weight: 800;
        }
      }
    }
  }
}

.content-menu-generated {
  width: 100.25%;
  margin-top: 20px;
  color: $color-black;

  ul {
    @include list-reset();
  }

  @include media(md) {
    display: block !important;
  }

  @include media(md) {
    margin-top: 0px;
  }

  li {
    &>a {
      display: block;
      text-decoration: none;
      font-size: 16px;
      line-height: 17px;
      color: inherit;
      font-weight: 600;
      padding: 5px 5px;
      @include animate("background-color, color");

      @include media(md) {
        font-size: 14px;
      }

      @include media(lg) {
        font-size: 16px;
        font-weight: 800;
        padding: 5px 10px;
      }

      @include media(xl) {
        font-size: 18px;
        line-height: 20px;
      }

      &:hover,
      &:focus {
        background-color: $color-primary;
        color: $color-black;
      }
    }

    &.Highlighted>a {
      background-color: $color-primary;
      color: $color-black;
    }

    &.Highlighted .content-submenu {
      display: block;
    }

    &>ul {
      width: 100%;
      @include list-reset();
      background-color: $color-secondary-light;
      padding: 0 10px;
      margin-bottom: 10px;
      text-decoration: none;
      display: none;

      &>li {
        &>a {
          position: relative;
          display: block;
          padding: 7px 0 7px 10px;
          color: $color-black;
          font-size: 16px;
          line-height: 17px;
          text-decoration: none;

          @include media(md) {
            padding: 5px 0 5px 10px;
            font-size: 13px;
          }

          @include media(lg) {
            font-size: 16px;
          }

          @include media(xl) {
            font-size: 18px;
            line-height: 20px;
          }

          &:before {
            display: block;
            content: "";
            width: 4px;
            height: 4px;
            background: $color-base;
            @include border-radius(10px);
            position: absolute;
            left: 0;
            top: 14px;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }

        .Highlighted {
          font-weight: 800;
        }
      }
    }
  }
}

.faq-tabs {
  @include media(sm) {
    margin-top: 10px;
  }

  @include media(lg) {
    margin-top: 25px;
  }

  &-item {
    background-color: $color-secondary-light;
    @include transition();
    margin-bottom: 10px;

    &-link {
      padding: 10px;
      cursor: pointer;
      @include transition();

      @include media(sm) {
        padding: 15px;
      }

      @include media(lg) {
        padding: 17px 20px;
      }

      h2 {
        margin: 0 !important;
        padding: 0 !important;
        font-size: 16px;
        line-height: 22px;
        border: 0px !important;

        @include media(xs) {
          font-weight: 800;
        }

        @include media(sm) {
          font-size: 18px;
          line-height: 27px;
        }

        @include media(lg) {
          font-size: 22px;
          line-height: 35px;
        }
      }

      &.active {
        h2 {
          background-image: linear-gradient(#000 2px, transparent 2px);
          background-size: 100% 22px;
          background-position: 0 20px;

          @include media(sm) {
            background-size: 100% 27px;
            background-position: 0 25px;
          }

          @include media(lg) {
            background-size: 100% 35px;
            background-position: 0 33px;
          }
        }

        &:focus,
        &:hover {
          background-color: transparent;
        }
      }
    }

    &-content {
      display: none;
      padding: 0 10px 10px 10px;
      font-size: 14px;
      line-height: 18px;

      @include media(sm) {
        padding: 0 15px 15px 15px;
      }

      @include media(lg) {
        padding: 0 20px 17px 20px;
      }

      a {
        color: blue;
        text-decoration: underline;

        &:focus,
        &:hover {
          color: blue;
          text-decoration: none;
        }
      }
    }

    &:focus,
    &:hover {
      background-color: $color-secondary;
    }

    &.active {
      background-color: $color-primary;

      &:focus,
      &:hover {
        background-color: $color-primary;
      }
    }

  }
}

.logos-gallery-item {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 600;

  &-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px;

    @include media(md) {
      margin-bottom: 25px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &-links {
    font-weight: 400;

    a {
      color: $color-primary;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px;

      @include media(md) {
        margin-top: 0px;
      }

      &:focus,
      &:hover {
        color: $color-primary;
        text-decoration: none;
      }
    }
  }
}

.contact-box {
  background-color: $grey-background;
  padding: 0 3rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
  @include pux-scale-with-min('font-size', 16px, 14px);
  @include pux-scale-with-min('line-height', 18px, 16px);

  &-content {
    align-self: flex-start;
  }

  &-button {
    margin: 1rem 0 1.5rem 0;
    padding: 1rem 5rem;
    color: $color-white !important;
    background-color: $color-grey;
    border: 2px solid transparent;
    @include pux-static-size('font-size', 14px);
    height: auto;
    font-weight: 800;

    &:hover,
    &:focus {
      color: $color-black !important;
      border-color: $color-black !important;
      background-color: transparent;
    }
  }

  &-image {
    margin-top: -30px;
    padding: 0 2rem;
    display: none;

    @include media(xs) {
      display: block;
    }

    img {
      max-height: 180px;
      max-width: none;
    }
  }
}

.about-us-peoples {
  &-title {
    background-color: $color-secondary-light;
    padding: 5px 10px;
    margin: 0;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  &-item {
    margin-bottom: 30px;
    line-height: 18px;
    font-size: 13px;

    @include media(lg) {
      font-size: 14px;
    }

    &-title {
      font-size: 14px;
      margin-bottom: 3px;

      @include media(md) {
        font-size: 16px;
        margin-bottom: 5px;
      }

      @include media(lg) {
        font-size: 18px;
      }
    }

    &-image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 67.5%;
      margin-bottom: 5px;
      background-color: $color-primary;
      overflow: hidden;

      @include media(md) {
        margin-bottom: 15px;
        padding-bottom: 105%;
      }

      img {
        position: absolute;
        bottom: 0;
        left: 50%;
        @include translate(-50%, 0);
        width: auto;
        height: 100%;

        @include media(md) {
          width: 100%;
          height: auto;
        }
      }

    }
  }
}

.content-tabs {
  margin-top: 25px;

  &-title {
    @include flex-block();
    @include flex-flow();
    border-bottom: 2px solid $color-black;

    &-link {
      display: inline-block;
      padding: 2px 10px;
      font-size: 14px;
      color: $color-black;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;

      @include media(sm) {
        padding: 2px 10px;
        font-size: 16px;
      }

      @include media(md) {
        font-size: 18px;
      }

      &.active,
      &:focus,
      &:hover {
        color: $color-black;
        background-color: $color-secondary-light;
        text-decoration: none !important;
      }
    }
  }

  &-list {
    @include media(sm) {
      padding-top: 15px;
    }

    &-item {
      display: inline-block;
      width: 100%;
      height: 50px;
      padding-top: 10px;
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
        color: $color-black;
      }

      &-image {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 65%;
        border: 1px solid $color-black;
        overflow: hidden;

        img {
          @include cover-image;
        }
      }

      &-name {
        margin-top: 5px;
        font-size: 11px;
        text-transform: uppercase;
        color: $color-black;

        @include media(xs) {
          font-size: 13px;
        }

        @include media(md) {
          margin-top: 10px;
        }

        @include media(xl) {
          font-size: 14px;
        }

        &-overflow {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.events-tabs {
  .content-tabs-title-link {
    background-color: transparent;
    text-transform: none;

    @include media(xxs, xxs) {
      font-size: 12px;
    }

    &:focus,
    &:hover {
      background-color: $color-secondary-light;
    }

    &.active {
      background-color: $color-primary;
    }
  }

  .content-tabs-list-cont {
    padding-top: 10px;

    @include media(md) {
      padding-top: 25px;
    }
  }

  &-item {
    margin-bottom: 10px;
    @include flex-block;
    background-color: $color-secondary-light;

    &-image {
      width: 40%;
      min-height: 95px;
      padding: 5px;
      text-align: center;

      @include media(xs) {
        min-height: 135px;
        padding: 15px 12.5px;
      }

      @include media(md) {
        padding: 25px 15.5px 25px 19.5px;
      }

      @include media(lg) {
        width: 27.5%;
        min-height: 168px;
      }

      img {
        @include vertical-pos(50%);
        max-width: 100%;
        max-height: 100%;
        margin: 0px;
      }
    }

    &-text {
      position: relative;
      width: 75%;
      padding: 5px 5px 35px 5px;
      font-size: 13px;
      line-height: 15px;

      @include media(sm) {
        padding: 10px;
        padding-bottom: 50px;
        font-size: 14px;
        line-height: 18px;
      }

      &-title {
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;

        @include media(xs) {
          font-size: 16px;
          line-height: 25px;
        }

        @include media(lg) {
          font-size: 18px;
          line-height: 35px;
        }
      }

      &-date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 13px;
        font-weight: 600;

        @include media(xs) {
          bottom: 20px;
          right: 15px;
          font-size: 14px;
        }

        @include media(lg) {
          font-size: 18px;
        }
      }
    }
  }
}

.locked-box {
  @include flex-block();
  @include align-items(center);
  @include justify-content(space-between);
  background-color: $color-secondary-light;
  padding: 10px;
  margin-top: 45px;
  margin-bottom: 35px;

  @include media(xl) {
    padding: 15px;
  }

  &-text {
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    line-height: 17px;

    @include media(xs) {
      font-size: 17px;
      line-height: 19px;
    }

    @include media(sm) {
      font-size: 19px;
      line-height: 21px;
    }

    @include media(md, md) {
      padding-left: 40px;
      font-size: 17px;
      line-height: 19px;
    }

    @include media(xl) {
      font-size: 22px;
      line-height: 25px;
    }

    &:before {
      @include icomoon;
      @include pseudo-elements("\e90a", absolute, 50%, -5px);
      font-size: 20px;
      @include translate(0, -50%);
      color: $color-black;

      @include media(sm) {
        font-size: 22px;
      }

      @include media(xl) {
        font-size: 25px;
      }
    }
  }

  &-submit {
    @include flex(0 0 90px);
    padding-left: 10px;

    @include media(sm) {
      @include flex-basis(110px);
    }

    @include media(lg) {
      @include flex-basis(160px);
    }

    .button-sign-up {
      width: 100%;
      min-height: 32px;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 5px;

    }
  }
}

.download-cont {
  padding-bottom: 30px;
}


.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px;

  @include media(lg) {
    margin-top: 40px;
  }

  .product-card-title {
    margin-bottom: 5px;
  }

  .download-item {
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

.subscribed {
  .locked-box {
    display: none;
  }
}

.unsubscribed {
  .download-item.locked {
    &:before {
      content: "\e90a";
    }
  }
}

.solutions {
  display: flex;
}

.blu-harters-page {
  color: $color-white;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1800px;
    background: linear-gradient(180deg, #0078c0 0%, #0078c0 15%, rgba(0, 118, 192, 0.171) 60%, transparent 90%);
  }

  .pux-container {
    @include media(lg) {
      background-image: url(/Prolyte/media/system/img/blu-harter.png);
      background-repeat: no-repeat;
      background-position: 100% 13rem;
      background-size: 41rem auto;
      position: relative;
      z-index: 22;
    }
  }

  &-perex {
    @include pux-scale-with-min('font-size', 58px, 30px);
    @include pux-scale-with-min('line-height', 53px, 30px);
    font-weight: 700;
    margin-bottom: 7rem;

    @include media(lg) {
      max-width: 87rem;
    }

    h1 {
      @include pux-scale-with-min('font-size', 58px, 30px);
      @include pux-scale-with-min('line-height', 53px, 30px);
      text-decoration: underline;
      text-decoration-color: $color-orange;
      margin-bottom: 1rem;
    }
  }
}

.blu-harter-item-box:not(:first-child) {
  margin: 12rem 0 0 0;
}

.blu-harter-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include media(md) {
    flex-wrap: nowrap;
  }

  @include media(lg) {
    max-width: 93rem;
  }

  &-image {
    @include pux-scale-with-min('width', 250px, 100px);
    @include pux-scale-with-min('height', 250px, 100px);
    border-radius: 50%;
    width: 25rem;
    height: 25rem;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin: 0 auto 2rem auto;

    @include media(md) {
      margin: 0;
    }

    img {
      min-width: 100%;
    }
  }

  &-text {
    @include media(md) {
      margin-left: 4rem;
    }

    &-header {
      align-items: baseline;
      margin-bottom: 0.5rem;
      text-align: center;

      @include media(md) {
        text-align: left;
      }

      h3 {
        display: inline;
        @include pux-scale-with-min('font-size', 32px, 18px);
        color: #a4a4a4;
        margin-right: 8px;
      }

      ;

      &-position {
        @include pux-scale-with-min('font-size', 20px, 16px);
        color: #b9b9b9;
        font-weight: 700;
      }
    }

    &-long {
      @include pux-scale-with-min('font-size', 16px, 14px);
      @include pux-scale-with-min('line-height', 16px, 14px);
      position: relative;
      padding: 5rem 5rem 5rem 3rem;
      color: $color-black;
      background-color: lighten($color-secondary-light, 10);
      text-align: center;

      @include media(md) {
        text-align: left;
      }

      &-inner {
        position: relative;
        z-index: 2;
      }
    }

    &-quote {
      &:before {
        content: "“";
        font-family: Helvetica !important;
        font-weight: 700;
        color: $color-secondary-light;
        position: absolute;
        font-size: 21rem;
        line-height: 18rem;
        top: 0rem;
        left: 2rem;
        opacity: 0.5;
      }
    }
  }
}

#map-canvas {
  height: 95rem;

  @include media(md) {
    height: 65rem;
  }
}

.pac-container {
  display: block;
}

.map-container {
  position: relative;
  margin: 0 auto 4rem auto;

  .breadcrumbs {
    display: none;

    @include media(md) {
      display: block;
      position: absolute;
      top: 10rem;
      z-index: 9999;
      left: 50%;
      width: 100%;
      max-width: 1260px;
      transform: translateX(-50%);
    }

    @include media(xxs, xl) {
      margin-left: 15px;
    }

    @include media(xl) {
      top: 9.5rem;
    }
  }
}

.map-search {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media(md) {
    flex-wrap: nowrap;
  }

  .btn {
    height: 47px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    width: 200px;

    @include media(md) {
      width: auto;
    }
  }

  input[type="text"] {
    border: none;
    background: $color-white;
    width: 30rem;
    height: 47px;
    margin-bottom: 1rem;
    width: 200px;

    @include media(md) {
      width: 42rem;
      margin-right: 3.5rem;
    }

  }
}

.store-list {
  display: flex;
  flex-wrap: wrap;
  background-color: $grey-background;
  padding: 4rem;

  @include media(lg) {
    margin: 0 -2rem;
  }

  .store-item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 2rem;

    @include media(xs) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media(md) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }

    &-label {
      margin-bottom: 2rem;

      &-title {
        @include pux-scale-with-min('font-size', 19px, 14px);
        font-weight: 400;
        border-bottom: 1px solid $color-black;
        user-select: none;
        cursor: pointer;
      }

      &-container {
          display: none;
      }

      &-address,
      &-location,
      &-city,
      &-zip,
      &-tel,
      &-email,
      &-web {
          font-size: 16px;
      }
    }
  }
}

.filter-category {
  background-color: $grey-background;
  margin: 0 0 3rem 0;
  padding: 2rem 1.5rem 3rem 1.5rem;

  @include media(lg) {

    margin: 0 1rem 0 0;
  }

  .map-filter-item {
    margin: 0 2rem 1rem 0;
    font-weight: 600;
    cursor: pointer;

    @include media(lg) {
      margin: 0 0 1rem 0;
    }

    img {
      margin-right: 1rem;

      @include media(lg) {
        margin-right: 0.5rem;
      }
    }
  }
}

.title-dealers {
  position: absolute;
  top: 14rem;
  left: 50%;
  width: 100%;
  max-width: 1260px;
  transform: translateX(-50%);
  pointer-events: none;

  @include media(xxs, xl) {
    margin-left: 15px;
  }

  @include media(xl) {
    top: 17rem;
  }
}

.contact-page {
  .box-header {
    min-height: 55rem;
    margin-bottom: 2rem;
  }

  &-col {
    display: flex;
    flex-direction: column;
  }

  &.store-list {
    display: none;
  }

  &-form {
    background: $grey-background;
    padding: 16px 16px 7.5rem 16px;
    margin-top: 1rem;

    input[type="text"],
    textarea,
    .selectric {
      background: $color-white;
      margin-bottom: 0;
    }

    .selectric {
      .label {
        color: $color-black;
      }

      .button {
        background-color: $color-grey;
      }

      &-items li {
        color: $color-black;
        background: $color-white;

        &:hover,
        &:focus {
          background: $color-grey;
        }
      }
    }

    .contact-upload-text {
      margin-top: 1rem;
    }

    .contact-submit-text {
      margin-top: 1rem;
    }
  }

  &#map-canvas {
    height: 38rem;
    border: 16px solid $grey-background;
    margin-top: 1.5rem;
  }

  &-addresses {
    background-color: $grey-background;
    padding: 1.5rem 2rem;
    flex: 0 1 100%;
    margin-top: 1rem;

    ul {
      padding-left: 0;
      list-style: none;
      position: relative;
      padding-left: 30px;

      li {
        &:before {
          content: url('http://localhost:1168/Prolyte/media/system/img/pins/black-pin.png');
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.map-filter-item {
  padding: 0 0 10px 0;

  img {
    margin: 0 10px 0 0;
  }
}

.download-section {
  flex-wrap: wrap;

  .division-box {
    margin: 0 -3.5px;

    @include media(md) {
      margin: 0 -7px;
    }
  }

  &.two-cards {
    .download-item-box {
      flex: 0 0 100%;
      max-width: 100%;

      @include media(sm) {
        flex: 0 0 50%;
        max-width: 50%;
        width: auto;
      }
    }

  }

  &.three-cards {
    .download-item-box {
      width: 100%;

      @include media(md) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        width: auto;
      }
    }
  }

  .download-item-box {
    padding: 3.5px;

    @include media(md) {
      padding: 7px;
    }
  }

  .download-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 2rem;
    background-color: $color-secondary-light;
    background-image: none !important;

    &:hover,
    &:focus {
      background-color: $grey-background;
    }

    .colored-title {
      margin-top: 0;
    }

    .perex {
      @include pux-scale-with-min ('font-size', 23px, 20px);
      @include pux-scale-with-min ('line-height', 23px, 20px);
      font-weight: 400;
      max-width: 280px;
      margin: 2.5rem auto 0 auto;
      color: $color-black;
    }

    &-image {
      display: block;
      margin: 2.5rem 0 3rem 0;

      img {
        width: auto;
        max-height: 38rem;
      }
    }

    .btn {
      margin: 0 auto 9.5rem auto;
    }

    &:before {
      display: none;
    }
  }
}


.image-slider {
    position: relative;
    overflow: hidden;
    display: flex;
    z-index: 1;
    .image-slide {
        display: flex;
        flex: 0 0 100vw;
        max-width: 100vw;
        min-height: 48rem;
        background-position: center;
        background-size: cover;
        visibility: hidden;
        opacity: 0;
        padding: 10rem 0;
        user-select: none;
        .pux-container-narrow {
            position: relative;
            width: 100%;
        }
        &-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            @include media(lg) {
                height: 100%;
                justify-content: flex-start;
                flex-direction: row;
            }
        }
        &-content {
            line-height: 1.7;
            @include pux-scale-with-min("font-size", 28px, 18px);
            @include media(lg) {
                padding-right: 4rem;
            }
            @include media(xl) {
                padding-right: 20rem;
            }
            &-cta {
                padding: 4rem 0 2rem;
            }
            h1,
            h2,
            h3,
            h4,
            big {
                font-size: 40px;
                line-height: 1;
                letter-spacing: -0.2rem;
                font-weight: 600;
                margin: 0.8rem 0 2rem;
                display: block;
                @include media(xs) {
                    font-size: 11.2rem;
                }
                @include media(xl) {
                    width: calc(100% + 20rem);
                }
                text-shadow: 1px 1px 2px #000;
            }
            .marker {
                color: $colorv2-orange;
            }
        }
        &-picture {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 30px;
            @include media(lg) {
                flex-grow: 1;
                max-width: 65%;
                padding: 0;
            }
            img {
                pointer-events: none;
            }
        }
        &.background-white {
            background-color: $color-white;
        }
        &.background-black {
            background-color: $color-black;
        }
        &.background-blue {
            background-color: $colorv2-blue;
        }
        &.slide-remove {
            transition: opacity 600ms ease-out;
            opacity: 0.3;
            .base-slider-dots {
                visibility: hidden !important;
                opacity: 0 !important;
            }
        }
        &.slide-active {
            position: relative;
            opacity: 1;
            visibility: visible;
            z-index: 2;
            transition: opacity 600ms ease-in;
            .base-slider-dots {
                li.slide-active {
                    background-color: $color-primary;
                }
            }
        }
    }
    .base-slider-dots {
        display: inline-flex;
        flex-wrap: wrap;
        position: absolute;
        left: 15px;
        z-index: 3;
        list-style: none;
        padding: 0;
        margin: 0;
        @include media(xl) {
            left: 8.5%;
        }
        li {
            padding: 1.2rem 4rem 1.2rem 0;
            cursor: pointer;
            @include transition;
            letter-spacing: 0.1rem;
            font-size: 18px;
            line-height: 1;
            @include media(lg) {
                font-size: 2rem;
            }
            div {
                background-color: rgba(#b8b8b8, 0.22);
                color: #b8b8b8;
                padding: 0.8rem 5px;
                position: relative;
                &:before {
                    content: "";
                    width: calc(100% + 4rem);
                    height: 2px;
                    background-color: #b8b8b8;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    @include transition;
                    transform: translateY(100%);
                }
            }
            &.dot-active {
                div {
                    color: $color-black;
                    &:before {
                        background-color: $color-black;
                    }
                }
            }
        }
    }
    &.fixed-top {
        .base-slider-dots {
            top: 3rem;
        }
        &.mobile-padding {
            .image-slide {
                @include media(xxs, xs) {
                    padding-top: 120px;
                }
            }
        }
    }
    &.fixed-bottom {
        .base-slider-dots {
            bottom: 3rem;
        }
        &.mobile-padding {
            .image-slide {
                @include media(xxs, xs) {
                    padding-bottom: 120px;
                }
            }
        }
    }
    &.hide-navigation-text {
        .base-slider-dots {
            li {
                width: 4.2rem;
                padding: 2rem 0;
                font-size: 0;
                margin: 0 0.6rem;
                div {
                    font-size: 0;
                    padding: 0;
                    &:before {
                        height: 1px;
                        background-color: $colorv2-gray-2;
                        bottom: 40%;
                        width: 100%;
                    }
                }
                &.dot-active {
                    div {
                        &:before {
                            height: 2px;
                            background-color: $colorv2-orange;
                        }
                    }
                }
            }
        }
    }
    &.under-text {
        .base-slider-dots {
            position: static;
            bottom: auto;
            left: auto;
            right: auto;
            top: auto;
            margin-top: 3rem;
            width: calc(100vw * 0.83);
        }
    }
    &.height-heighest {
        .image-slide {
            min-height: 102rem;
        }
    }
    &.height-fullscreen {
        .image-slide {
            min-height: 100vh;
            @include media(xxxl) {
                padding-bottom: 15rem;
            }
            @include breakpointhelper(1800px, 4000px) {
                padding-bottom: 10rem;
            }
        }
    }
    &.theme-dark {
        background-color: lighten($color-black, 20);
        .image-slide {
            color: $color-white;
            .marker {
                color: $colorv2-blue;
            }
        }
        &:not(.hide-navigation-text) .base-slider-dots {
            li {
                div {
                    color: $color-white;
                    background-color: rgba($color-black, 0.75);
                    &:before {
                        background-color: #636363;
                    }
                }
                &.dot-active {
                    div {
                        color: $color-white;
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
    }
    &.theme-blue {
        background-color: $colorv2-blue;
        .image-slide {
            color: $color-white;
            .base-slider-dots {
                li {
                    div {
                        background-color: transparent;
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
        &:not(.hide-navigation-text).base-slider-dots {
            li {
                color: #636363;
                div {
                    &:before {
                        background-color: #636363;
                    }
                }
                &.dot-active {
                    color: $color-white;
                    div {
                        &:before {
                            background-color: $color-white;
                        }
                    }
                }
            }
        }
    }
    &.content-center {
        .image-slide-inner {
            @include media(xxs, md) {
                justify-content: center;
            }
            @include media(lg) {
                align-items: center;
            }
        }
    }
    &.content-bottom {
        .image-slide-inner {
            @include media(xxs, md) {
                justify-content: flex-end;
            }
            @include media(lg) {
                align-items: flex-end;
            }
        }
    }
    &.align-right {
        .image-slide {
            &-inner {
              text-align: right;
              @include media(xxs, md) {
                  align-items: flex-end;
              }
              @include media(lg) {
                  justify-content: flex-end;
              }
            }
            &-content {
                @include media(lg) {
                    padding: 0;
                    order: 2;
                    padding-left: 4rem;
                }
                @include media(xl) {
                    padding-left: 20rem;
                }
                h1,
                h2,
                h3,
                h4,
                big {
                    @include media(xl) {
                        transform: translateX(-20rem);
                    }
                }
            }
            &-picture {
                @include media(lg) {
                    order: 1;
                }
            }
        }
        .base-slider-dots.fixed {
            left: auto;
            right: 15px;
            @include media(xl) {
                right: 8.5%;
            }
        }
    }
    &[class*=text-width] {
        .image-slide-content {
            @include media(lg) {
              flex: 0 0 60%;
              max-width: 60%;
            }
        }
    }
    &.text-width-35 {
        .image-slide-content {
            @include media(lg) {
                flex: 0 0 55%;
                max-width: 55%;
            }
            @include media(xxl) {
                flex: 0 1 35%;
                max-width: 35%;
            }
        }
    }
    &.text-width-45 {
        .image-slide-content {
            @include media(xxl) {
                flex: 0 0 45%;
                max-width: 45%;
            }
        }
    }
    &.text-width-55 {
        .image-slide-content {
            @include media(xxl) {
                flex: 0 0 55%;
                max-width: 55%;
            }
        }
    }
}

.trainers {
    text-align: center;
    background: $color-black;
    color: $color-white;
    @include media(xl) {
        text-align: left;
        display: flex;
        padding-left: 8.5%;
    }
    &-content {
        padding: 8rem 5%;
        font-size: 20px;
        line-height: 1.8;
        @include media(md) {
            padding-top: 8rem;
            font-size: 3.0rem;
        }
        @include media(xl) {
            flex: 0 1 50rem;
            max-width: 50rem;
            padding: 8rem 0;
        }
        h1,
        h2,
        h3,
        h4,
        big {
            font-size: 40px;
            line-height: 1;
            letter-spacing: -0.2rem;
            font-weight: 600;
            padding-bottom: 2rem;
            @include media(xs) {
                font-size: 11.2rem;
            }
        }
    }
    &-persons {
        display: flex;
        overflow: hidden;
        padding-bottom: 100px;
        @include media(sm) {
            padding-bottom: 0;
        }
        @include media(xl) {
            flex-grow: 1;
        }
        &-item {
            flex: 0 1 40%;
            max-width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            text-align: left;
            min-height: 67rem;
            &-text {
                z-index: 1;
                letter-spacing: 0.47px;
                color: #999999;
                text-transform: uppercase;
                padding-top: 9rem;
                @include media(md) {
                    padding-top: 8rem;
                }
            }
            &-inner {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 250%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-height: 100%;
                width: 100%;
            }
            &.left-type {
                .trainers-persons-item-text {
                    padding-left: 15px;
                    @include media(sm) {
                        padding-left: 7rem;
                    }
                }
            }
            &.center-type {
                flex: 0 1 20%;
                max-width: 20%;
                z-index: 2;
                transform: translateY(100px);
                @include media(sm) {
                    transform: none;
                }
                .trainers-persons-item-text {
                    padding-left: 10rem;
                }
            }
            &.right-type {
                justify-content: center;
                @include media(sm) {
                    padding-left: 7rem;
                }
                @include media(xxl) {
                    padding-left: 0;
                }
            }
        }
    }
    &.theme-light {
        background: $color-white;
        color: $color-black;
        .trainers-persons-item-text {
            color: gray;
        }
    }
    &.theme-blue{
        background: $colorv2-blue;
    }
}


.yt-video {
    background-color: $color-black;
    color: $color-white;
    padding-bottom: 10rem;
    &-inner {
        @include media(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &-title {
        flex: 0 1 100%;
        max-width: 100%;
    }
    &-main {
        padding-bottom: 10px;
        @include media(md) {
            flex-grow: 1;
            width: calc(100% - 200px);
            padding: 0;
            padding-right: 10px;
        }
        @include media(xl) {
            width: calc(100% - 25rem);
            padding-right: 1rem;
        }
        a {
            @include media(md) {
                height: 100%;
            }
        }
    }
    &-list {
        position: relative;
        @include media(md) {
            flex: 0 1 200px;
            max-width: 200px;
            height: 486px;
        }
        @include media(lg) {
            height: 600px;
        }
        @include media(xl) {
            flex: 0 1 25rem;
            max-width: 25rem;
            height: 60rem;
        }
        @include media(xxl) {
            height: 74rem;
        }
        a {
            flex: 0 0 25vw;
            max-width: 25vw;
            display: block;
            min-height: 50px;
            margin: 0 10px 0 0;
            @include media(md) {
                flex:none;
                max-width: none;
                margin: 0 0 10px;
            }
            @include media(xl) {
                margin: 0 0 1rem;
            }
        }
        &-inner {
            overflow: auto;
            display: flex;
            width: 100%;
            @include media(md) {
                display: block;
                overflow: hidden;
                max-height: 100%;
            }
        }
        .yt-video-arrow {
            position: absolute;
            width: 30px;
            height: 100%;
            z-index: 6;
            cursor: pointer;
            @include transition;
            background: rgba($color-black, 0.8);
            display: none;
            @include media(md) {
                width: 100%;
                height: 3rem;
                display: block;
            }
            &:before {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-image: url("/Prolyte/media/system/img/arrow-video.svg");
                background-position: center;
                background-repeat: no-repeat;
            }
            &.arrow-prev {
                top: 0;
                left: 0;
                &:before {
                    transform: rotate(90deg);
                    @include media(md) {
                        transform: rotate(180deg);
                    }
                }
            }
            &.arrow-next {
                top: 0;
                right: 0;
                @include media(md) {
                    right: auto;
                    top: auto;
                    bottom: 0;
                    left: 0;
                }
                &:before {
                    transform: rotate(-90deg);
                    @include media(md) {
                        transform: rotate(0deg);
                    }
                }
            }
            &:hover,
            &:focus {
                background: rgba($color-black, 0.5);
            }
            &.arrow-disabled {
                opacity: 0;
            }
        }
    }
    a {
        display: block;
        background-position: center;
        background-size: cover;
        position: relative;
        &:before {
            content: "";
            display: block;
            padding-top: 56%;
        }
    }
}

.blueheart-banner {
    background-color: $colorv2-blue;
    color: $color-white;
    padding: 30rem 8.5% 40rem;
    position: relative;
    overflow: hidden;
    margin-bottom: -1px;
    @include media(lg) {
        display: flex;
        margin: 0;
        min-height: 80vh;
        padding: 0;
        padding-left: 8.5%;
    }
    &-title {
        padding: 0 15px;
        position: relative;
        z-index: 2;
        margin-bottom: 10rem;
        @include media(lg) {
            padding: 0;
            flex-shrink: 0;
            align-self: flex-end;
            display: inline-block;
        }
        h1 {
            font-size: 40px;
            line-height: 1;
            letter-spacing: -0.2rem;
            font-weight: 600;
            margin: 0.8rem 0 2rem;
            display: block;
            @include media(xs) {
                font-size: 11.2rem;
            }
        }
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            display: block;
            z-index: -1;
            background-image: url("/Prolyte/media/system/img/heart-bg-2.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom;;
            height: 40rem;
            transform: translateY(100%);
            @include media(lg) {
                right: 0;
                transform: translate(99%, -4%);
                background-size: 100% 100%;
                width: calc(100vw - 100%);
                padding-bottom: 50%;
            }
            @include media(xxl) {
                transform: translateX(99%);
                padding-bottom: 70%;
            }
        }
    }
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 50rem;
        z-index: 2;
        background-image: url("/Prolyte/media/system/img/heart-man.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        bottom:0;
        @include media(lg) {
            position: static;
            height: auto;
            bottom: auto;
            flex-grow: 1;
            background-size: 72%;
            background-position: -50% 100%;
        }
        @include media(xl) {
          background-size: 65%;
          background-position: -25% 100%;
        }
        @include media(xxl) {
            background-size: contain;
            background-position: left bottom;
        }
    }
}

.blueheart-page {
    .pux-container-narrow {
        max-width: 77%;
    }
    .align-right {
        .image-slide-content {
            @include media(lg) {
                text-align: left;
            }
            @include media(xl) {
                padding-left: 8rem;
                padding-right: 10rem;
            }
            h1,
            h2,
            h3,
            h4,
            big {
                @include media(lg) {
                    transform: none;
                }
            }
        }
    }
}

.tooltip-inner {
    font-size: 20px;
}

.afi-gallery {
    &-item {
        padding-left: 5px;
        padding-bottom: 5px;
        flex: 0 1 100%;
        max-width: 100%;
        @include media(xs) {
        	   flex: 0 1 50%;
             max-width: 50%;
        }
        @include media(lg) {
            flex: 0 1 33.333%;
            max-width: 33.333%;
        }
        &-inner {
            position: relative;
            overflow: hidden;
            display: block;
            &:before {
                content: "";
                display: block;
                padding-top: 80%;
            }
            img {
                position: absolute;
                top: 0%;
                left: 50%;
                transform: translate(-50%, 0%);
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }
    }
    &.large-space {
        .afi-gallery-item {
            padding-bottom: 40px;
        }
    }
}
