
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";

html {
  box-sizing: border-box;
}

.pux-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include transition;
        @include media(xl) {
            max-width: 97.5%;
        }
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.pux-container-narrow {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @include transition;
    @include media(xl) {
        padding: 0;
        max-width: 83%;
    }
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.col-xs-five-grid {
  @include media(xs, xs) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-sm-five-grid {
  @include media(sm, sm) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-md-five-grid {
  @include media(md, md) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-lg-five-grid {
  @include media(lg, lg) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.col-xl-five-grid {
  @include media(xl, xl) {
      @include flex(0 0 20%);
      max-width: 20%;
  }
}

.flex-as-end{
    @include align-self(flex-end);
}

.flex-box{
    @include flex-block;

}

.jc-space{
    @include justify-content(space-between);
}

.align-center{
   align-items: center;
}


.align-bottom{
  align-items: flex-end;
}

.full-container{
    margin-left: auto;
    margin-right: auto;
    background-color: inherit;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
}

.small-container {
  max-width: 1000px;
}

.header-helper{
    height:50px;
    width:100%;
}

.AdminLayoutContainer {
  display: flex;
  width: 100%;
  .products-item-card {
    min-width: 80px;
  }
  .main-card {
    .products-item-card {
      padding-bottom: 60% !important;
    }
  }
}

header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
    width: 100%;
    min-height:50px;
    left: 50%;
    @include translate(-50%, 0);
    @include media(md) {
        min-height: 70px;
    }
    .logo{
        padding:11px 13px 10px 0;
        @include media(xl) {
            flex: 0 0 17.8rem;
            max-width: 17.8rem;
        }
        a{
            display:inline-block;
        }
        img{
            height: 29px;
            @include breakpointhelper(320px, 340px) {
                height: 22px;
            }
            @include media(md) {
                height: 35px;
            }
            @include media(xl) {
                height: 3.5rem;
            }
        }
        span,
        h1{
            display:none;
        }
    }
    .main-menu{
        width:100%;
        position:absolute;
        top:50px;
        left:0;
        display:none;
        @include calc("max-height", "100vh - 50px");
        overflow:auto;
        @include media(md) {
            top: 56px;
            @include calc("max-height", "100vh - 56px");
        }
        @include media(xl){
            position:relative;
            top:0;
            overflow:visible;
            display:block !important;
            padding-left: 3rem;
        }
        ul{
            margin:0;
            padding:0;
            list-style:none;
            @include flex-block;
            @include flex-direction(column);
            @include media(xl) {
                justify-content: flex-start;
                margin-right: 15px;
                @include flex-direction(row);
                @include align-items(center);
            }
            & > li{
                border-bottom:1px solid #000;
                position:relative;
                @include media(xl){
                  border:none;
                }
                & > a {
                    cursor: pointer;
                    display: block;
                    font-size: 18px;
                    line-height: 1;
                    position: relative;
                    padding: 18px 15px;
                    color: $color-white;
                    background-color: $second-color;
                    text-decoration: none;
                    font-weight: 300;
                    @include media(xl){
                        background-color: transparent;
                        padding: 2.2rem 15px;
                        font-size: 18px;
                    }
                    @include media(xxl) {
                        padding: 2.2rem 2.5rem;
                        font-size: 2.1rem;
                    }
                    @include media(xxxl) {
                        padding: 2.2rem 3.4rem;
                    }
                    & > span{
                        display:inline-block;
                        position:relative;
                        &:after{
                            display: block;
                            content: "";
                            height: 2px;
                            left:0;
                            top: 100%;
                            margin-top: 4px;
                            width: 100%;
                            background: $color-white;
                            @include opacity(0);
                            visibility: hidden;
                            @include animate("opacity, visibility");
                            position:absolute;
                            @include media(xl) {
                                height: 0.2rem;
                                margin-top: 0.4rem;
                            }
                        }
                    }
                    &:focus,
                    &:hover{
                        @include media(xl){
                            span{
                                &:after{
                                    @include opacity(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
                &.Highlighted{
                    & > a{
                        span{
                            &:after{
                                @include opacity(1);
                                visibility: visible;
                            }
                        }
                    }
                }
                &:hover{
                    .submenu{
                        @include opacity(1);
                        visibility: visible;
                    }
                }
                .menu-mobile{
                    position:absolute;
                    right:0;
                    top:0;
                    width:65px;
                    height:53px;
                    text-align:center;
                    background:none;
                    &:before{
                        content:"";
                        width:26px;
                        height:2px;
                        background:#000;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -1px;
                        margin-left: -13px;
                    }
                    &:after{
                        content:"";
                        width:2px;
                        height:26px;
                        background:#000;
                        display:block;
                        position:absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -13px;
                        margin-left: -1px;
                        @include opacity(1);
                        visibility:visible;
                        @include transition;
                    }
                    &.open{
                        &:after{
                            @include rotate(90deg);
                        }
                    }
                    @include media(xl){
                        display:none;
                    }
                }
             }
        }
    }
    .header{
        @include flex-block;
        @include align-items(center);
        justify-content: space-between;
        @include media(xl) {
            justify-content: flex-start;
        }
        &-search{
            @include flex(0 0 auto);
            position:absolute;
            width:100%;
            padding:0 15px;
            left:0;
            top:45px;
            display: none;
            text-transform: uppercase;
            @include transition(300ms);
            @include media(xs) {
                top: 55px;
            }
            @include media(xl){
                position:static;
                max-width:21rem;
                overflow:visible;
                display: block;
                padding:0;
            }
            &.open {
                display: block;
            }
            .searchBox {
                position: relative;
            }
            label{
                display:none;
            }
            input[type="text"]{
                @include border-radius(0px);
                width:100%;
                height:30px;
                padding: 8px 9px;
                border:none;
                outline:0;
                font-size: 16px;
                color: $color-white !important;
                border: 1px solid $color-white !important;
                background-color: $colorv2-blue !important;
                height: 50px;
                @include box-shadow(none);
                margin-bottom: 0;
                @include media(xl) {
                    background-color: transparent !important;
                    height: 2.6rem;
                    font-size: 1.2rem;
                    padding: 0.7rem 0.6rem;
                    width: 21rem;
                    height: 26px;
                    text-transform: uppercase;
                }
                &::-webkit-input-placeholder {
                    color: $color-white;
                }
                &:-ms-input-placeholder {
                    color: $color-white;
                }
                &::placeholder {
                    color: $color-white;
                }
                &:focus{
                    border:none;
                    outline:0;
                    @include box-shadow(none);
                }
            }
            .btn-search{
                position:absolute;
                top:50%;
                right:10px;
                font-size:0;
                width:30px;
                text-align:center;
                transform: translateY(-50%);
                &:before{
                    content:"\e909";
                    font-size: 26px;
                    line-height:32px;
                }
								@include media (xl) {
										right: 4px;
								}
                &-more {
                    padding: 0.8rem 1rem;
                    line-height: 1.3;
                }
            }
            &-inner{
                position:relative;
                padding:10px 0 15px 0;
                @include media(xl){
                    padding:0;
                }
            }
        }
        &-login{
            position:absolute;
            background:$color-secondary;
            bottom: 2px;
            right: 0px;
            width:100%;
            display:none;
            color: $color-white;
            transform: translateY(100%);
            @include media(md, lg) {
                bottom: 12px;
            }
            &-inner{
                position:relative;
                padding:10px;
                input[type="password"],
                input[type="text"]{
                    width: 100%;
                    height: 40px;
                    border:none;
                    @include border-radius(0);
                    color: #999;
                    @include transition;
                    &:focus{
                        color: #000;
                        background: #f2f2f2;
                        border-color: #c7c7c7;
                    }
                }
            }
            @include media(xl){
                right:100px;
                width:360px;
            }
            .logon-remember-me-checkbox{
                display:none;
            }
            .logon-password-retrieval-link,
            .no-href{
                font-size: 18px;
                color:#000 !important;
                @include transition;
                text-decoration:none !important;
                &:focus,
                &:hover{
                    color:$color-primary !important;
                }
            }
            .login-btn{
                position:absolute;
                right:10px;
            }
            .logon-panel-password-retrieval{
                padding:10px 0 0 0;
            }
        }
        &-nav{
            @include flex(0 0 auto);
            @include flex-block;
            @include align-items(center);
            justify-content: space-between;
            width: 150px;
            height:32px;
            text-align:center;
            color: $color-white;
            text-decoration: none;
            font-weight:600;
            line-height: 1;
            margin: 0 0 0 10px;
            @include media(sm) {
                width: 200px;
            }
            @include media(xl){
                width:auto;
                height:auto;
                display: block;
                line-height: inherit;
            }
            &-search,
            &-language,
            &-login,
            &-logout {
                position: relative;
                margin: 0 5px;
                color: $color-white;
                text-decoration: none;
                @include media(xxs, lg) {
                    font-size: 0;
                }
                @include media(lg) {
                    padding: 0px 5px;
                    margin: 0 10px;
                }
                &:before {
                    font-size: 20px;
                    @include media(xl) {
                        display: none;
                    }
                }
                &:after{
                    height: 2px;
                    left:0;
                    top: 100%;
                    margin-top: 4px;
                    width: 100%;
                    background: $color-white;
                    @include opacity(0);
                    visibility: hidden;
                    @include animate("opacity, visibility");
                    position:absolute;
                    @include media(xl) {
                        display: block;
                        content: "";
                        height: 0.2rem;
                        margin-top: 0.4rem;
                    }
                }
                &:focus,
                &:hover{
                    &:after{
                        @include media(xl) {
                            @include opacity(1);
                            visibility: visible;
                        }
                    }
                }
            }
            &-search {
                font-size:0;
                @include media(lg) {
                  display: none;
                }
                &:before{
                    content:"\e909";
                }
            }
            &-language {
                display: inline-block;
                cursor: pointer;
                &:before {
                    content:"\e905";
                    @include media(xl){
                        margin:0 0 0 5px;
                        vertical-align:-2px;
                        line-height:normal;
                    }
                }
                .header-language-inner {
                    position: absolute;
                    display: none;
                    min-width: 40px;
                    background:$color-secondary;
                    right: 0px;
                    @include media(lg) {
                        top: 45px;
                    }
                    ul, li {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        text-align: center;
                    }
                    a {
                        display: inline-block;
                        color: $color-white;
                        width: auto;
                        height:auto;
                        margin: 10px;
                        text-decoration: none;
                        position: relative;
                        padding-left: 10px;
                        padding-right: 10px;
                        &:after{
                            height: 2px;
                            left:0;
                            top: 100%;
                            margin-top: 4px;
                            width: 100%;
                            background: $color-white;
                            @include opacity(0);
                            visibility: hidden;
                            @include animate("opacity, visibility");
                            position:absolute;
                            @include media(xl) {
                                display: block;
                                content: "";
                                height: 0.2rem;
                                margin-top: 0.4rem;
                            }
                        }
                        &:focus,
                        &:hover{
                            &:after{
                                @include media(xl) {
                                    @include opacity(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
            &-logout,
            &-login {
                &:before{
                    content:"\e90f";
                    @include media(xl){
                        display:none;
                    }
                }
            }
            &-menu{
                position:relative;
                width: 32px;
                height: 32px;
                span{
                    width:26px;
                    position:absolute;
                    top:50%;
                    margin-top:-1px;
                    background:$color-white;
                    height:2px;
                    left:50%;
                    margin-left:-13px;
                    @include transition;
                    &:before,
                    &:after{
                        content:"";
                        width:26px;
                        height:2px;
                        position:absolute;
                        display:block;
                        background:$color-white;
                        @include transition;
                    }
                    &:before{
                        top:-8px;
                    }
                    &:after{
                        bottom:-8px;
                    }
                }
                @include media(xl){
                    display:none !important;
                }
                &.open{
                    span{
                        background:transparent;
                        &:before{
                            @include rotate(45deg);
                            top:0;
                        }
                        &:after{
                            @include rotate(-45deg);
                            top:0;
                        }

                    }
                }
            }
        }
    }
    .submenu{
        font-size: 0;
        white-space: nowrap;
        background: rgba($color-secondary, 0.8);
        z-index: 5;
        display:none;
        @include flex-direction(column);
        @include media(xl){
            position:absolute;
            @include flex-direction(row);
            padding: 0.5rem 0.7rem 2rem;
            left: 0;
            @include opacity(0);
            visibility: hidden;
            @include animate("opacity, visibility");
            @include flex-block;
        }
        ul{
            display:block;
            width: 100%;
            min-width: 187px;
            white-space: normal;
            font-size: 14px;
            border-top:1px solid #000;
            li{
                display:block;
                //border-bottom: 1px solid $color-base;
                a{
                    display: block;
                    padding: 12px 25px;
                    position: relative;
                    font-weight:400;
                    font-size: 16px;
                    &:hover{
                        color: $color-link-hover;
                    }
                    @include media(xl){
                        padding: 9px 0;
                        border-bottom: 1px solid $color-base;
                    }
                }
                span{
                    display: block;
                    font-weight:600;
                    padding: 9px 15px;
                    background:#000;
                    color:$color-primary;
                    border-bottom: 1px solid $color-base;
                    @include media(xl){
                        background:$color-secondary;
                        color:#000;
                        padding: 9px 0;
                    }
                }
                &:last-child{
                    border:none;
                    @include media(xl){
                        //border-bottom: 1px solid $color-base;
                    }
                }
                ul{
                    margin:0;
                    padding:0;
                    width:auto;
                    min-width:10px;
                }
            }
            @include media(xl){
                width: 187px;
                padding: 0 15px;
                border:none;
            }
        }
    }
    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 200ms linear;
        background: $color-primary;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        height: 200%;
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        @include transition;
        background: linear-gradient(to bottom, $color-primary, transparent);
    }
}

.EditMode,
.DesginMode{
    header{
        position:static;
        transform: translate(0,0);
    }
}

.quick-search{
		position:absolute;
		background: #e0e0e0;
		bottom: 0;
		left: 0;
		width: 100%;
    visibility:hidden;
    @include opacity(0);
    @include transition;
    transform: translateY(100%);
		@include media(xl) {
			 top: 5.4rem;
			 width: 50rem;
			 right: 0px;
       bottom: auto;
			 left: auto;
       transform: none;
		}
    &-inner{
        padding:10px;
        min-height:293px;
        @include opacity(1);
        @include transition;
    }
    .header-search-item{
        @include flex-block;
        @include align-items(center);
        color:#000;
        text-decoration:none !important;
        border-bottom:1px solid $color-white;
        &-image{
            position:relative;
            width:90px;
            min-width:90px;
            height:90px;
            background:#fff;
            text-align:center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-name{
            font-weight:bold;
            padding:0 15px;
            width:100%;
            font-size: 18px;
            word-break: break-all;
        }
        &-arrow{
            width:30px;
            height:30px;
            min-width:30px;
            background:transparent;
            color:#000;
            margin-right:10px;
            border:1px solid #000;
            text-align:center;
            @include border-radius(50%);
            @include transition;
            &:before{
                content:"\e908";
                line-height:28px;
                font-size: 24px;
                margin-left: -2px;
            }
        }
        &:focus,
        &:hover{
            background:$color-primary;
            .header-search-item-arrow{
                background:#000;
                color:#fff;
            }
        }
    }
    &.open{
        visibility:visible;
        @include opacity(1);
    }
}

.quick-search-btn{
    padding:10px 10px 20px 10px;
    text-align: center;
}

.loader {
  @include opacity(0);
  visibility: hidden;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
z-index: 33;
  left: 50%;
  margin-left: -60px;
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.loader:after {
  background: #e0e0e0;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.quick-search{
    &.opacity{
        .loader{
            @include opacity(1);
            visibility: visible;
        }
        .quick-search-inner{
            @include opacity(0);
        }
    }
}

.load-more-products{
    width:100%;
    .inner-flex{
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

footer{
  margin:auto;
  color: $color-black;
  padding: 30px 0 10px;
  @include media(sm) {
      padding: 30px 0 20px;
  }
  @include media(md) {
      padding: 5rem 0 6.5rem;
  }
  .copyright{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px -10px 0 -10px;
    font-size: 15px;
    @include media(xl) {
        font-size: 1.5rem;
        margin: 2rem -10px 0 -10px;
    }
    &-item{
      padding: 0 10px 20px 10px;
    }
  }
  ul{
    margin:0;
    padding:0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    a:not(.ico-social){
      color: $color-black;
      text-decoration: none;
      &:focus,
      &:hover{
        color:$color-black;
        text-decoration: underline;
      }
    }
  }
  .footer-menu{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    li{
      font-size: 15px;
      margin:10px 40px 10px 0;
      @include media(xl) {
          font-size: 1.5rem;
      }
    }
    a{
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  .footer-submenu{
    padding:15px 0 0 0;
    li{
      margin:0 30px 15px 0;
      &:last-child{
        margin:0 0 15px 0;
      }
    }
  }
}

body[product-page] {
    header {
        &:after {
            display: none;
        }
    }
}

img {
    max-width: 100%;
}

//admin
.WPTTableCell.WPTTableCellContent header {
  display: static;
  transform: unset;
  background-color: $main-color;
}

.IE {
  main {
    max-width: 100vw;
    overflow: hidden;
  }
}

.theme-blue {
    background-color: $colorv2-blue;
    min-height: 80vh;
}

.form-container{
  padding: 15px 20px 30px;
  background: $main-color;
  max-width: 500px;
  color: $color-white;
  @include media(xl){
    max-width: 50rem;
    padding: 1.5rem 2rem 3rem;
  }
  &.newsletter-box{
    padding: 30px 20px 35px;
    margin: auto;
    text-align: center;
    font-size: 15px;
    line-height: 1.6;
    @include media(xl){
      padding: 3rem 2rem 3.5rem;
    }
  }
}

.nwl-title {
    span {
        font-weight: 700;
        font-size: 17px;
        padding-bottom: 3rem;
        display: inline-block;
        color: $color-black;
        @include media(md) {
            line-height: 1.2;
            font-size: 2.2rem;
        }
    }
}

.footer-newsletter {
    background-color: $main-color;
}


.newsletter-row{
  padding: 2.5rem 0;
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  flex-direction: column;
  @include media(sm) {
      flex-direction: row;
  }
  //@include flex-wrap(wrap);
  .EditingFormErrorLabel,
  .ErrorLabel {
      color: $color-white;
  }
  .EditingFormErrorLabel {
      font-size: 14px;
      line-height: 1.2;
      text-align: left;
  }
  input {
      height: 50px;
      @include pux-scale-from("height", lg, 50px);
  }
  &-input{
    padding: 5px 0 !important;
    width: auto !important;
    input {
        @include media(sm) {
            width: 36rem !important;
        }
    }
  }
  &-btn{
    padding: 5px 0 !important;
    max-width: 110px;
    margin-top: 10px;
    @include media(sm) {
        margin-top: 0;
    }
    @include media(xl){
      max-width: 11rem;
    }
    .btn{
      padding: 1.5rem 20px;
      background-color: $color-white;
      border: 2px solid $color-white !important;
      color: $color-black !important;
      @include media(xl){
        padding: 1.5rem 2rem;
      }
      &:hover,
      &:focus {
          background-color: transparent;
      }
    }
  }
  &  * {
      @include media(xxs, xs) {
          width: 100% !important;
          max-width: 100% !important;
      }
  }
}

.newsletter-text {
    padding: 0 4rem;
}

.big-iframe{
    width: 100%;
    height: 100vh;
    border: none;
}

.biger-iframe{
    width: 100%;
    height: 120vh;
    border: none;
}