@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";


.sn-form-header {
		margin-top: 50px;
		line-height: 35px;
		padding: 10px;
		font-weight: bold;
		background-color: $color-primary;
		font-size: 30px;
		@include media(sm) {
				line-height: 40px;
				padding: 10px 20px;
				font-size: 35px;
		}
		@include media(lg) {
				line-height: 45px;
				padding: 10px 20px;
				font-size: 40px;
		}
}

.sn-form-body {
		background-color: $color-secondary-light;
		padding: 15px 7px;
		@include media(xs) {
				padding: 20px;
		}
		&-title {
				padding-bottom: 25px;
				font-size: 14px;
				font-weight: 600;
		}
		&-add-sn {
				font-size: 14px;
				font-weight: 800;
				padding: 20px 0;
				border-bottom: 2px solid $color-black;
				margin-bottom: 17px;
				@include media(lg) {
						padding: 40px 0 20px;
				}
				a {
						color: $color-black;
						text-decoration: none;
						&:hover,
						&:focus {
								text-decoration: underline;
						}
				}
		}
		&-btn {
				width: 100%;
			  a {
						@include flex-block;
						@include align-items(center);
						@include justify-content(center);
						width: 100%;
						padding: 0 15px;
						min-height: 40px;
						background-color: $color-primary;
						@include animate("background-color, color");
						font-size: 16px;
						font-weight: 600;
						text-decoration: none;
						color: black;
						text-align: center;
						border: 0px;
						cursor: pointer;
						@include border-radius(0);
						&:focus,
						&:hover {
							color: $color-white !important;
							background: $color-black;
							text-decoration: none !important;
						}
						@include media(md) {
							 font-size: 18px;
						}
			  }
		}
		&-sn-info {
				color: $input-darken;
				position: relative;
    		top: -5px;
				&:hover,
				&:focus {
						text-decoration: underline;
				}
				&+ span {
						top: 0 !important;
				}
		}
		&-terms {
				margin-bottom: 5px;
		}
		label {
				font-size: 15px;
				color: $color-black;
				margin-bottom: 7px;
				display: inline-block;
				letter-spacing: 0;
				line-height: 22px;
				font-size: 13px;
				margin-bottom: 5px;
				line-height: 19px;
				@include media(md) {
						font-size: 15px;
						line-height: 26px;
				}
				@include media(lg) {
            font-size: 18px;
						margin-bottom: 10px;
						line-height: 22px;
				}

		}
		input[type="text"],
		input[type="password"],
		select,
		textarea {
				background-color: $color-white;
				width: 100%;
				padding: 8px 10px;
				height: 38px;
				color: $color-black;
				margin-bottom: 15px;
				border-radius: 0 !important;
				border: 0;
				position: relative;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				font-weight: 200;
				@include transition;
				border: 1px solid $color-secondary-light;
				&:focus {
						border: 1px solid $input-dark;
						outline: 0;
						background-color: $input-light;
			}
		}
		span[style*="visible"] {
				position: relative;
				top: -10px;
				margin-bottom: 15px;
				display: block;
				color: red;
		}
		span[style*="hidden"] {
				display: none;
		}
}

.row-space {
		.row:not(:last-of-type) {
			 	margin-bottom: 30px;
		}
}

label {
	font-size: 15px;
	color: $color-black;
	margin-bottom: 7px;
	display: block;
	letter-spacing: 0;
	line-height: 22px;

	@include media(md) {
		font-size: 18px;
		line-height: 26px;
	}
}

.form-control,
input[type="text"],
input[type="password"],
select,
textarea {
  @include pux-scale-with-min('font-size', 15px, 14px);
	background-color: $color-secondary-light;
	width: 100%;
	padding: 8px 10px;
	height: 38px;
	color: $color-black;
	margin-bottom: 15px;
	border-radius: 0 !important;
	position: relative;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	font-weight: 200;
	@include transition();

	&:focus {
		border: 1px solid $input-dark;
		outline: 0;
	}
}

select::-ms-expand {
	display: none;
}

textarea.form-control {
	height: 100px;
	margin-bottom: 0px;

	&:-webkit-resizer {
		display: none;
	}
}

.newsletter-row-btn button,
input[type="submit"] {
	width: 100%;
	padding: 0 15px;
	min-height: 40px;
	background-color: $color-primary;
	@include animate("background-color, color");
	border: 1px solid $color-primary;
	font-size: 16px;
	font-weight: 800;
	line-height: 20px;
	text-decoration: none;
	color: $color-white;
	text-align: center;
	cursor: pointer;
	@include border-radius(0);

	&:focus,
	&:hover {
		color: $color-white !important;
		background: $color-black;
		text-decoration: none !important;
	}

	@include media(md) {
		font-size: 18px;
	}
}

.ErrorLabel,
.EditingFormErrorLabel {
	position: relative;
	display: block;
	width: 100%;
	margin: 20px 0px;
	padding-left: 15px;
	background-color: $color-primary;
}

.required,
.field-required {
  &:after {
    content: "*";
    color: #ff5445;
  }
}

.uploader-main {
  display: none;
}

.iframe-newsletter {
	width: 100%;
	height: 400px;
	border: none;
	@include media(sm) {
	  height: 350px;
	}
  }

.footer-newsletter {
	.newsletter-row-input {
		input {
			padding: 10px;
			font-size: 15px;
			color:  #fff;
			outline: none;
			&::placeholder {
				color: #fff;
			}
		}
	}
}
.newsletter-row{
	padding: 2.5rem 0;
	@include flex-block;
	@include align-items(center);
	@include justify-content(center);
	flex-direction: column;
	@include media(sm) {
		flex-direction: row;
	}
	//@include flex-wrap(wrap);
	.ErrorLabel {
	}
	input {
		height: 50px;
		@include pux-scale-from("height", lg, 50px);
	}
	&-input{
	  padding: 0 5px;
	  @include media(xl){
		padding: 0 0.5rem;
	  }
	  input {
		  width: 220px;
		  box-sizing: border-box;
		  @include media(xl) {
			  width: 22rem;
		  }
	  }
	}
	&-btn{
	  max-width: 110px;
	  padding: 0 5px;
	  margin-top: 10px;
	  @include media(sm) {
		  margin-top: 0;
	  }
	  @include media(xl){
		max-width: 11rem;
	  }
	  .btn {
		padding: 1.5rem 20px;
		@include media(xl){
		  padding: 1.5rem 2rem;
		}
	  }
	}
	&  * {
		@include media(xxs, xs) {
			width: 100% !important;
			max-width: 100% !important;
		}
	}
  }

	.sib-form-message-panel__text {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0.5rem;
}

.sib-form-message-panel {
    margin: 0 0 1.25rem 0;
    width: 100%;
    padding: 0.4375rem;
    border: 1px solid;
    display: none;
}

.sib-form-message-panel--active{
	display: inline-block;
}

.entry__error {
    display: none;
    margin-top: 6px;
    margin-bottom: 6px;
    background: transparent;
}

.entry__error.entry__error--primary{
	font-size: 16px;
  text-align: left;
  font-family: Helvetica, sans-serif;
  color: rgb(102, 29, 29);
  background-color: rgb(255, 237, 237);
  border-radius: 3px;
  border-color: rgb(255, 73, 73);
}

.sib-form{
		#error-message{
		font-size: 16px;
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #661d1d;
    background-color: #ffeded;
    border-radius: 3px;
    border-color: #ff4949;
    max-width: 540px;
		}
		#success-message{
			font-size: 16px;
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #085229;
    background-color: #e7faf0;
    border-radius: 3px;
    border-color: #13ce66;
    max-width: 540px;
		}
}

.sib-form .clickable__icon:not(.sib-icon_standalone) {
    margin-right: calc(0.7002rem - 1px);
}

.sib-form .progress-indicator__icon {
    -webkit-animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite;
    animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite;
}

@keyframes indicator-spin{
	0%{
		transform:rotate(0deg)
	}
	to{
		transform:rotate(1turn)
	}
}

.sib-hide-loader-icon {
    display: none;
}

#sib-container{
	padding: 0;
	background: none;
	.btn{
		cursor: pointer;
	}
}

.input--hidden{
	display: none !important;
}

.sib-form-block__button-disabled {
    opacity: 0.5;
		cursor: default;
}
