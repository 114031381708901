$path-font: "../fonts/graphik";
$color-black: #000;
$color-white: #fff;
$color-grey: #999;
$color-primary: #0078c0;
$color-secondary: #999999;
$color-secondary-light: #e0e0e0;
$color-line: #b2b2b2;
$grey-background: #f5f4f5;
$main-color: $color-primary;
$second-color: $color-secondary;
$color-bg: #000000;
$color-hover: #797777;
$color-red: #ff5445;
$color-blue: #365fd6;
$color-green: #30a24e;
$afitv-color: #ff0e2e;
$colorv2-gray: #ebebeb;
$colorv2-gray-2: #5e5d5c;
$colorv2-red: #f63c37;
$colorv2-gray-2: #8c8c8c;
$colorv2-gray-dark: #666666;
$colorv2-blue: #0086ce;
$color-base: #000;
$color-orange: #fe761c;
$colorv2-orange: #ff9100;
$color-link: $color-primary;
$color-link-hover: $color-primary;
$color-border: #e0e0e0;
$color-inactive: #f0f0f0;
$font-size: 16px;
$font-base: Arial, Helvetica, sans-serif;
$transition-function: ease;
$transition-duration: 250ms;
$btn-bg: $color-white;
$btn-border: $color-black;
$btn-color: $color-black;
$btn-bg-hover: darken($btn-bg,15%);
$btn-border-hover: darken($btn-border,10%);
$btn-height: 40px;
$btn-border-width: 1px;
$input-light: #f2f2f2;
$input-dark: #c7c7c7;
$input-darken: #7a7a7a; //arrays

$scale-base-size: 10px;

$scale-ratio-xxs: 0.5;
$scale-ratio-xs: 0.55;
$scale-ratio-sm: 0.6;
$scale-ratio-md: 0.75;
$scale-ratio-lg:  0.8;
$scale-ratio-xl: 0.9;
$scale-ratio-xxl: 1;
$scale-ratio-2048: 1.11;
$scale-ratio-2304: 1.22;
$scale-ratio-2560: 1.33;
$scale-ratio-2880: 1.5;
$scale-ratio-3440: 1.6;
$scale-ratio-3840: 1.7;
$share-icon-names: (
  google: "\e915",
  tumblr: "\e916",
  facebook: "\e914",
  mail: "\e911",
  twitter: "\e912",
  linkedin: "\e910",
  vine: "\e913",
  youtube: "\e906",
  instagram: "\e91a"
);
