@charset "UTF-8";
@import url(../fonts/icofont/style.css);
.pt-10 {
  padding-top: 10px; }

.pt-20 {
  padding-top: 20px; }

.pt-30 {
  padding-top: 30px; }

.pt-40 {
  padding-top: 40px; }

.pt-50 {
  padding-top: 50px; }

.pt-60 {
  padding-top: 60px; }

.pt-70 {
  padding-top: 70px; }

.pt-80 {
  padding-top: 80px; }

.pt-90 {
  padding-top: 90px; }

.pt-100 {
  padding-top: 100px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-100 {
  padding-bottom: 100px; }

.mt-10 {
  margin-top: 10px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.mt-50 {
  margin-top: 50px; }

.mt-60 {
  margin-top: 60px; }

.mt-70 {
  margin-top: 70px; }

.mt-80 {
  margin-top: 80px; }

.mt-90 {
  margin-top: 90px; }

.mt-100 {
  margin-top: 100px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-100 {
  margin-bottom: 100px; }

.line {
  width: 100%;
  margin: 20px 0;
  height: 2px;
  background: #000; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 15px; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #e0e0e0;
  border-radius: 0px;
  background: #e0e0e0;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 36px 0 10px;
    font-size: 15px;
    line-height: 36px;
    color: #000;
    height: 36px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: #000;
    color: #e0e0e0;
    text-align: center;
    font: 0/0 a;
    *font: 20px/36px Lucida Sans Unicode, Arial Unicode MS, Arial;
    transition: all ease 200ms; }
    .selectric .button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 30px;
      content: "\e903";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white; }

.selectric-focus .selectric {
  border-color: #e0e0e0; }

.selectric-hover .selectric {
  border-color: #e0e0e0; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #e0e0e0;
    border-bottom: 0px; }
    .selectric-open .selectric .button {
      color: #c7c7c7;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #c7c7c7;
  border: 1px solid #e0e0e0;
  border-top: 0;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #000;
    background: #999;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li.selected {
      color: #000; }
    .selectric-items li.highlighted {
      color: #000; }
    .selectric-items li:hover {
      background: #e0e0e0;
      color: #000; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

.hot-stuff-header-select .selectric-wrapper {
  width: 100%;
  margin-bottom: 0 !important; }

.hot-stuff-header-select .selectric {
  width: 100%;
  background: #fff;
  font-size: 13px; }
  .hot-stuff-header-select .selectric .label {
    color: #000 !important;
    font-weight: 800; }
  .hot-stuff-header-select .selectric .button {
    background: transparent !important; }
    .hot-stuff-header-select .selectric .button:after {
      color: #000; }

.hot-stuff-header-select .selectric-open .selectric {
  height: 38px; }

.hot-stuff-header-select li {
  background: #fff;
  color: #000 !important;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px; }
  .hot-stuff-header-select li.selected {
    color: #fff; }
  .hot-stuff-header-select li.highlighted {
    color: #fff; }
  .hot-stuff-header-select li:hover {
    background: #fff;
    color: #000;
    text-decoration: underline; }

html {
  font-size: 5px; }
  @media (min-width: 480px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }
  @media (min-width: 2048px) {
    html {
      font-size: 11.1px; } }
  @media (min-width: 2304px) {
    html {
      font-size: 12.2px; } }
  @media (min-width: 2560px) {
    html {
      font-size: 13.3px; } }
  @media (min-width: 2880px) {
    html {
      font-size: 15px; } }
  @media (min-width: 3440px) {
    html {
      font-size: 16px; } }
  @media (min-width: 3840px) {
    html {
      font-size: 17px; } }

.pt-5 {
  padding-top: calc(5rem / 10); }

.pb-5 {
  padding-bottom: calc(5rem / 10); }

.mt-5 {
  margin-top: calc(5rem / 10); }

.mb-5 {
  margin-bottom: calc(5rem / 10); }

.pt-10 {
  padding-top: calc(10rem / 10); }

.pb-10 {
  padding-bottom: calc(10rem / 10); }

.mt-10 {
  margin-top: calc(10rem / 10); }

.mb-10 {
  margin-bottom: calc(10rem / 10); }

.pt-15 {
  padding-top: calc(15rem / 10); }

.pb-15 {
  padding-bottom: calc(15rem / 10); }

.mt-15 {
  margin-top: calc(15rem / 10); }

.mb-15 {
  margin-bottom: calc(15rem / 10); }

.pt-20 {
  padding-top: calc(20rem / 10); }

.pb-20 {
  padding-bottom: calc(20rem / 10); }

.mt-20 {
  margin-top: calc(20rem / 10); }

.mb-20 {
  margin-bottom: calc(20rem / 10); }

.pt-25 {
  padding-top: calc(25rem / 10); }

.pb-25 {
  padding-bottom: calc(25rem / 10); }

.mt-25 {
  margin-top: calc(25rem / 10); }

.mb-25 {
  margin-bottom: calc(25rem / 10); }

.pt-30 {
  padding-top: calc(30rem / 10); }

.pb-30 {
  padding-bottom: calc(30rem / 10); }

.mt-30 {
  margin-top: calc(30rem / 10); }

.mb-30 {
  margin-bottom: calc(30rem / 10); }

.pt-35 {
  padding-top: calc(35rem / 10); }

.pb-35 {
  padding-bottom: calc(35rem / 10); }

.mt-35 {
  margin-top: calc(35rem / 10); }

.mb-35 {
  margin-bottom: calc(35rem / 10); }

.pt-40 {
  padding-top: calc(40rem / 10); }

.pb-40 {
  padding-bottom: calc(40rem / 10); }

.mt-40 {
  margin-top: calc(40rem / 10); }

.mb-40 {
  margin-bottom: calc(40rem / 10); }

.pt-45 {
  padding-top: calc(45rem / 10); }

.pb-45 {
  padding-bottom: calc(45rem / 10); }

.mt-45 {
  margin-top: calc(45rem / 10); }

.mb-45 {
  margin-bottom: calc(45rem / 10); }

.pt-50 {
  padding-top: calc(50rem / 10); }

.pb-50 {
  padding-bottom: calc(50rem / 10); }

.mt-50 {
  margin-top: calc(50rem / 10); }

.mb-50 {
  margin-bottom: calc(50rem / 10); }

.pt-55 {
  padding-top: calc(55rem / 10); }

.pb-55 {
  padding-bottom: calc(55rem / 10); }

.mt-55 {
  margin-top: calc(55rem / 10); }

.mb-55 {
  margin-bottom: calc(55rem / 10); }

.pt-60 {
  padding-top: calc(60rem / 10); }

.pb-60 {
  padding-bottom: calc(60rem / 10); }

.mt-60 {
  margin-top: calc(60rem / 10); }

.mb-60 {
  margin-bottom: calc(60rem / 10); }

.pt-65 {
  padding-top: calc(65rem / 10); }

.pb-65 {
  padding-bottom: calc(65rem / 10); }

.mt-65 {
  margin-top: calc(65rem / 10); }

.mb-65 {
  margin-bottom: calc(65rem / 10); }

.pt-70 {
  padding-top: calc(70rem / 10); }

.pb-70 {
  padding-bottom: calc(70rem / 10); }

.mt-70 {
  margin-top: calc(70rem / 10); }

.mb-70 {
  margin-bottom: calc(70rem / 10); }

.pt-75 {
  padding-top: calc(75rem / 10); }

.pb-75 {
  padding-bottom: calc(75rem / 10); }

.mt-75 {
  margin-top: calc(75rem / 10); }

.mb-75 {
  margin-bottom: calc(75rem / 10); }

.pt-80 {
  padding-top: calc(80rem / 10); }

.pb-80 {
  padding-bottom: calc(80rem / 10); }

.mt-80 {
  margin-top: calc(80rem / 10); }

.mb-80 {
  margin-bottom: calc(80rem / 10); }

.pt-85 {
  padding-top: calc(85rem / 10); }

.pb-85 {
  padding-bottom: calc(85rem / 10); }

.mt-85 {
  margin-top: calc(85rem / 10); }

.mb-85 {
  margin-bottom: calc(85rem / 10); }

.pt-90 {
  padding-top: calc(90rem / 10); }

.pb-90 {
  padding-bottom: calc(90rem / 10); }

.mt-90 {
  margin-top: calc(90rem / 10); }

.mb-90 {
  margin-bottom: calc(90rem / 10); }

.pt-95 {
  padding-top: calc(95rem / 10); }

.pb-95 {
  padding-bottom: calc(95rem / 10); }

.mt-95 {
  margin-top: calc(95rem / 10); }

.mb-95 {
  margin-bottom: calc(95rem / 10); }

.pt-100 {
  padding-top: calc(100rem / 10); }

.pb-100 {
  padding-bottom: calc(100rem / 10); }

.mt-100 {
  margin-top: calc(100rem / 10); }

.mb-100 {
  margin-bottom: calc(100rem / 10); }

.pt-130 {
  padding-top: calc(130rem / 10); }

.pb-130 {
  padding-bottom: calc(130rem / 10); }

.mt-130 {
  margin-top: calc(130rem / 10); }

.mb-130 {
  margin-bottom: calc(130rem / 10); }

.pt-140 {
  padding-top: calc(140rem / 10); }

.pb-140 {
  padding-bottom: calc(140rem / 10); }

.mt-140 {
  margin-top: calc(140rem / 10); }

.mb-140 {
  margin-bottom: calc(140rem / 10); }

.pt-170 {
  padding-top: calc(170rem / 10); }

.pb-170 {
  padding-bottom: calc(170rem / 10); }

.mt-170 {
  margin-top: calc(170rem / 10); }

.mb-170 {
  margin-bottom: calc(170rem / 10); }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@font-face {
  font-family: "Graphik";
  src: url("../fonts/graphik/Graphik-SemiBold.eot");
  src: url("../fonts/graphik/Graphik-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-SemiBold.woff2") format("woff2"), url("../fonts/graphik/Graphik-SemiBold.woff") format("woff"), url("../fonts/graphik/Graphik-SemiBold.ttf") format("truetype"), url("../fonts/graphik/Graphik-SemiBold.svg#Graphik") format("svg");
  font-weight: 600;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Graphik";
    src: url("../fonts/graphik/Graphik-SemiBold.svg") format("svg"); } }

@font-face {
  font-family: "Graphik";
  src: url("../fonts/graphik/Graphik-Regular.eot");
  src: url("../fonts/graphik/Graphik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Regular.woff2") format("woff2"), url("../fonts/graphik/Graphik-Regular.woff") format("woff"), url("../fonts/graphik/Graphik-Regular.ttf") format("truetype"), url("../fonts/graphik/Graphik-Regular.svg#Graphik") format("svg");
  font-weight: 400;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Graphik";
    src: url("../fonts/graphik/Graphik-Regular.svg") format("svg"); } }

@font-face {
  font-family: "Graphik";
  src: url("../fonts/graphik/Graphik-Medium.eot");
  src: url("../fonts/graphik/Graphik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/graphik/Graphik-Medium.woff2") format("woff2"), url("../fonts/graphik/Graphik-Medium.woff") format("woff"), url("../fonts/graphik/Graphik-Medium.ttf") format("truetype"), url("../fonts/graphik/Graphik-Medium.svg#Graphik") format("svg");
  font-weight: 500;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Graphik";
    src: url("../fonts/graphik/Graphik-Medium.svg") format("svg"); } }

body,
input,
select,
textarea {
  font-family: "Graphik", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 320px) {
    body,
    input,
    select,
    textarea {
      font-size: 3.4rem; } }
  @media (min-width: 480px) {
    body,
    input,
    select,
    textarea {
      font-size: 3.09091rem; } }
  @media (min-width: 545px) {
    body,
    input,
    select,
    textarea {
      font-size: 2.83333rem; } }
  @media (min-width: 768px) {
    body,
    input,
    select,
    textarea {
      font-size: 2.26667rem; } }
  @media (min-width: 992px) {
    body,
    input,
    select,
    textarea {
      font-size: 2.125rem; } }
  @media (min-width: 1200px) {
    body,
    input,
    select,
    textarea {
      font-size: 2rem; } }
  @media (min-width: 320px) {
    body,
    input,
    select,
    textarea {
      line-height: 3.4rem; } }
  @media (min-width: 480px) {
    body,
    input,
    select,
    textarea {
      line-height: 3.09091rem; } }
  @media (min-width: 545px) {
    body,
    input,
    select,
    textarea {
      line-height: 2.83333rem; } }
  @media (min-width: 768px) {
    body,
    input,
    select,
    textarea {
      line-height: 2.26667rem; } }
  @media (min-width: 992px) {
    body,
    input,
    select,
    textarea {
      line-height: 2.125rem; } }
  @media (min-width: 1200px) {
    body,
    input,
    select,
    textarea {
      line-height: 2rem; } }

body main {
  line-height: 1.6; }

table {
  margin: 20px 0 1rem; }
  table p {
    margin: 3px 0; }
  @media (min-width: 320px) {
    table {
      font-size: 2.4rem; } }
  @media (min-width: 480px) {
    table {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    table {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    table {
      font-size: 1.6rem; } }
  @media (min-width: 320px) {
    table {
      line-height: 2.4rem; } }
  @media (min-width: 480px) {
    table {
      line-height: 2.18182rem; } }
  @media (min-width: 545px) {
    table {
      line-height: 2rem; } }
  @media (min-width: 768px) {
    table {
      line-height: 1.6rem; } }
  table td {
    font-weight: 300;
    padding: 0.5rem; }
    @media (min-width: 320px) {
      table td big {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      table td big {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      table td big {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      table td big {
        font-size: 2.3rem; } }
    @media (min-width: 320px) {
      table td big {
        line-height: 3.4rem; } }
    @media (min-width: 480px) {
      table td big {
        line-height: 3.09091rem; } }
    @media (min-width: 545px) {
      table td big {
        line-height: 2.83333rem; } }
    @media (min-width: 768px) {
      table td big {
        line-height: 2.3rem; } }
  table th {
    text-align: unset;
    background-color: #0078c0;
    color: #fff;
    padding: 0.5rem 1rem; }
    @media (min-width: 320px) {
      table th {
        font-size: 3.4rem; } }
    @media (min-width: 480px) {
      table th {
        font-size: 3.09091rem; } }
    @media (min-width: 545px) {
      table th {
        font-size: 2.83333rem; } }
    @media (min-width: 768px) {
      table th {
        font-size: 2.26667rem; } }
    @media (min-width: 992px) {
      table th {
        font-size: 2.125rem; } }
    @media (min-width: 1200px) {
      table th {
        font-size: 1.9rem; } }
    @media (min-width: 320px) {
      table th {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      table th {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      table th {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      table th {
        line-height: 2.66667rem; } }
    @media (min-width: 992px) {
      table th {
        line-height: 2.5rem; } }
    @media (min-width: 1200px) {
      table th {
        line-height: 2.22222rem; } }
    @media (min-width: 1400px) {
      table th {
        line-height: 2.2rem; } }
  table .marker {
    color: #0078c0;
    font-weight: bold; }

.text-right {
  text-align: right; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 600; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block; }

@media (min-width: 320px) {
  h1, .h1 {
    font-size: 8rem; } }

@media (min-width: 480px) {
  h1, .h1 {
    font-size: 7.27273rem; } }

@media (min-width: 545px) {
  h1, .h1 {
    font-size: 7.1rem; } }

@media (min-width: 320px) {
  h1, .h1 {
    line-height: 9rem; } }

@media (min-width: 480px) {
  h1, .h1 {
    line-height: 8.18182rem; } }

@media (min-width: 545px) {
  h1, .h1 {
    line-height: 8rem; } }

h1.title-line, .h1.title-line {
  color: #0078c0;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #fe761c; }
  @media (min-width: 768px) {
    h1.title-line, .h1.title-line {
      text-decoration: underline;
      text-decoration-color: #fe761c; } }

@media (min-width: 320px) {
  h2, .h2 {
    font-size: 5.8rem; } }

h3, .h3 {
  margin: 15px 0 10px; }
  @media (min-width: 320px) {
    h3, .h3 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    h3, .h3 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    h3, .h3 {
      font-size: 3.6rem; } }
  @media (min-width: 1200px) {
    h3, .h3 {
      margin: 20px 0 10px; } }

h4, .h4 {
  margin: 15px 0 10px; }
  @media (min-width: 320px) {
    h4, .h4 {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    h4, .h4 {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    h4, .h4 {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    h4, .h4 {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.8rem; } }
  @media (min-width: 1200px) {
    h4, .h4 {
      margin: 20px 0 10px; } }

@media (min-width: 768px) and (min-width: 320px) {
  .new-h2 {
    font-size: 11rem; } }

@media (min-width: 768px) and (min-width: 320px) {
  .new-h2 {
    line-height: 11rem; } }

strong,
.bold,
b {
  font-weight: 600; }

.semibold {
  font-weight: 600; }

.no-href,
a {
  text-decoration: underline;
  color: #0078c0;
  cursor: pointer;
  outline: none; }
  .no-href:hover,
  a:hover {
    text-decoration: none;
    color: #0078c0; }

.logon-password-retrieval-button,
.btn {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  cursor: pointer;
  color: #000;
  border: 1px solid #000;
  height: 30px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: 0 !important; }

.logon-password-retrieval-button,
.btn-primary {
  background-color: #999; }
  .logon-password-retrieval-button:focus, .logon-password-retrieval-button:hover,
  .btn-primary:focus,
  .btn-primary:hover {
    background-color: #000;
    color: #999;
    border: 1px solid #000; }

.btn-primary,
.arrow-down-link {
  background-color: #0078c0;
  color: #fff !important;
  padding: 15px 25px;
  border: 2px solid #0078c0 !important;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  height: auto; }
  @media (min-width: 320px) {
    .btn-primary,
    .arrow-down-link {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn-primary,
    .arrow-down-link {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn-primary,
    .arrow-down-link {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn-primary,
    .arrow-down-link {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn-primary,
    .arrow-down-link {
      font-size: 2rem; } }
  .btn-primary:focus, .btn-primary:hover,
  .arrow-down-link:focus,
  .arrow-down-link:hover {
    text-decoration: none !important;
    background-color: #000;
    color: #fff; }

.btn-default {
  background-color: #fff;
  color: #0078c0 !important;
  padding: 15px 25px;
  border: 2px solid #0078c0 !important;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
  height: auto; }
  @media (min-width: 320px) {
    .btn-default {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .btn-default {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .btn-default {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .btn-default {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .btn-default {
      font-size: 2rem; } }
  .btn-default:focus, .btn-default:hover {
    text-decoration: none !important;
    background-color: #0078c0;
    color: #fff !important; }

.square-button,
.square-button-bw {
  display: inline-block;
  padding: 5px;
  margin: 0 2px;
  margin-top: 15px;
  position: relative;
  border: 1px solid #000;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  color: #fff;
  text-align: center; }
  .square-button:focus, .square-button:hover,
  .square-button-bw:focus,
  .square-button-bw:hover {
    color: #0078c0 !important;
    background: #000 !important;
    text-decoration: none !important; }

.square-button-bw:focus, .square-button-bw:hover {
  color: #fff !important;
  background: #000; }

.big-button-bw,
.big-button-bw-small {
  width: 100%;
  padding: 0 15px;
  min-height: 40px;
  line-height: 38px;
  vertical-align: middle;
  display: inline-block;
  font-size: 18px;
  color: #fff;
  background: #999;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  text-align: center;
  -webkit-transition-property: background, color;
  -moz-transition-property: background, color;
  -o-transition-property: background, color;
  transition-property: background, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  font-weight: 600; }
  .big-button-bw:hover, .big-button-bw:focus,
  .big-button-bw-small:hover,
  .big-button-bw-small:focus {
    color: #fff !important;
    background: #000;
    text-decoration: none; }

.big-button-bw-small {
  font-size: 15px;
  padding: 0 10px; }
  @media (min-width: 768px) {
    .big-button-bw-small {
      font-size: 18px;
      padding: 0 20px; } }

.btn-close {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #fff !important;
  color: #fff !important;
  text-shadow: 0;
  opacity: 1;
  font-size: 15px;
  -moz-transition: all ease-in-out 100ms;
  -o-transition: all ease-in-out 100ms;
  -webkit-transition: all ease-in-out 100ms;
  transition: all ease-in-out 100ms; }
  .btn-close:hover, .btn-close:focus {
    color: #000 !important;
    background-color: #fff; }

.colored-title h1,
.colored-title h2
h3,
.colored-title h4 {
  color: #0078c0; }

@keyframes spinner {
  to {
    transform: rotate(360deg); } }

.items-container.loading {
  position: relative;
  min-height: 130px; }
  .items-container.loading:before {
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0.2rem solid transparent;
    border-top-color: #0078c0;
    border-bottom-color: #0078c0;
    animation: spinner .8s ease infinite;
    position: absolute;
    left: 50%;
    top: calc(50% - 25px);
    z-index: 33; }
  .items-container.loading:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    background: rgba(255, 255, 255, 0.5); }

.contact-list {
  padding-top: 20px; }
  .contact-list a {
    color: #000 !important; }
    .contact-list a:hover, .contact-list a:focus {
      color: #000 !important; }
  .contact-list .contact-list-item {
    position: relative;
    background-color: #e0e0e0;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    line-height: 18px;
    margin-bottom: 15px; }
    @media (min-width: 480px) {
      .contact-list .contact-list-item {
        padding: 10px; } }
    .contact-list .contact-list-item .contact-item-map {
      position: relative;
      width: 100%;
      height: 0px;
      padding-bottom: 65%;
      order: 2; }
      @media (min-width: 480px) {
        .contact-list .contact-list-item .contact-item-map {
          width: 200px;
          height: 132px;
          padding-bottom: 0;
          order: 1; } }
      @media (min-width: 480px) and (max-width: 767px) {
        .contact-list .contact-list-item .contact-item-map {
          margin-bottom: 40px; } }
      @media (min-width: 545px) {
        .contact-list .contact-list-item .contact-item-map {
          width: 295px;
          height: 195px; } }
      @media (min-width: 768px) {
        .contact-list .contact-list-item .contact-item-map {
          width: 200px;
          height: 132px; } }
      @media (min-width: 992px) {
        .contact-list .contact-list-item .contact-item-map {
          width: 295px;
          height: 195px; } }
      .contact-list .contact-list-item .contact-item-map .contact-map {
        position: absolute;
        width: 100% !important;
        height: 100% !important; }
    .contact-list .contact-list-item .contact-list-item-content {
      width: 100%;
      order: 1;
      padding: 10px 10px 0 10px; }
      @media (min-width: 480px) {
        .contact-list .contact-list-item .contact-list-item-content {
          padding: 0;
          padding-left: 10px;
          width: -webkit-calc(100% - 200px);
          width: -moz-calc(100% - 200px);
          width: calc(100% - 200px);
          order: 2; } }
      @media (min-width: 545px) {
        .contact-list .contact-list-item .contact-list-item-content {
          width: -webkit-calc(100% - 295px);
          width: -moz-calc(100% - 295px);
          width: calc(100% - 295px); } }
      @media (min-width: 768px) {
        .contact-list .contact-list-item .contact-list-item-content {
          width: -webkit-calc(100% - 200px);
          width: -moz-calc(100% - 200px);
          width: calc(100% - 200px); } }
      @media (min-width: 992px) {
        .contact-list .contact-list-item .contact-list-item-content {
          width: -webkit-calc(100% - 295px);
          width: -moz-calc(100% - 295px);
          width: calc(100% - 295px); } }
      .contact-list .contact-list-item .contact-list-item-content .contact-title-line {
        width: 100%;
        border-bottom: 2px solid #000; }
      .contact-list .contact-list-item .contact-list-item-content .contact-data {
        padding-bottom: 10px; }
    .contact-list .contact-list-item .contact-data-button {
      position: absolute;
      bottom: 10px;
      width: 104px;
      height: 30px;
      display: none; }
      @media (min-width: 480px) {
        .contact-list .contact-list-item .contact-data-button {
          display: inline-block;
          left: 10px; } }
      @media (min-width: 540px) and (max-width: 615px) {
        .contact-list .contact-list-item .contact-data-button {
          width: 100px; } }
      @media (min-width: 768px) {
        .contact-list .contact-list-item .contact-data-button {
          left: 10px;
          right: auto;
          width: 132px; } }
      @media (min-width: 992px) {
        .contact-list .contact-list-item .contact-data-button {
          left: auto;
          right: 10px; } }

.hp-category .EditorWidgets {
  width: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }
  .hp-category .EditorWidgets .WebPart {
    display: block; }

.DesignMode header {
  position: static;
  transform: unset;
  background-color: #0078c0; }
  .DesignMode header:after {
    display: none; }

.fancybox-container * {
  outline: 0 !important; }

.fancybox-infobar__body {
  position: relative !important;
  max-width: -webkit-calc(100% - 132px);
  max-width: -moz-calc(100% - 132px);
  max-width: calc(100% - 132px);
  height: 44px !important;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block !important;
  background: transparent !important; }
  .fancybox-infobar__body .fancybox-caption {
    border: 0 !important;
    text-align: left;
    background: transparent !important;
    padding: 13px 10px;
    color: white; }

.fancybox-arrow {
  width: 40px !important;
  background-image: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important; }
  @media (min-width: 768px) {
    .fancybox-arrow {
      margin-left: 20px !important;
      margin-right: 20px !important; } }
  .fancybox-arrow:before {
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: relative;
    top: 50%;
    left: 50%;
    display: inline-block !important;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: #fff !important;
    font-size: 40px;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40px !important;
    border: 1px solid #fff !important;
    background-color: rgba(0, 0, 0, 0.95) !important;
    -moz-transition: all ease-in-out 150ms;
    -o-transition: all ease-in-out 150ms;
    -webkit-transition: all ease-in-out 150ms;
    transition: all ease-in-out 150ms;
    z-index: 9999999; }
  .fancybox-arrow:after {
    background-color: transparent !important;
    background-image: none !important; }

.fancy-video.fancybox-is-open .fancybox-bg {
  opacity: 1 !important;
  background-color: #000 !important; }

.fancy-video .fancybox-toolbar,
.fancy-video .fancybox-navigation {
  height: 50px !important; }

.fancy-video .fancybox-infobar {
  top: auto !important;
  bottom: 0px !important;
  text-align: center !important;
  font-size: 16px;
  margin-left: -50px !important;
  left: 50% !important; }

.fancy-video .fancybox-stage {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50px;
  bottom: 50px;
  left: 0;
  right: 0; }

.fancy-video .fancybox-content {
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  max-height: 100% !important; }

.fancy-video .fancybox-arrow {
  height: auto !important;
  top: auto;
  bottom: 0% !important; }

.fancy-video .fancybox-button--close {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  top: 5px;
  right: 5px; }
  @media (min-width: 768px) {
    .fancy-video .fancybox-button--close {
      right: 20px; } }
  .fancy-video .fancybox-button--close:hover, .fancy-video .fancybox-button--close:focus {
    color: #fff !important; }
    .fancy-video .fancybox-button--close:hover-before, .fancy-video .fancybox-button--close:focus-before {
      color: #fff !important; }

.modal,
.product-registration-box {
  background-color: rgba(0, 0, 0, 0.85);
  color: #000;
  padding-right: 0 !important;
  z-index: 9999; }
  .modal .modal-dialog,
  .product-registration-box .modal-dialog {
    position: relative;
    min-height: 95%;
    margin: 10rem auto;
    text-align: center;
    width: 100%;
    max-width: 100%; }
    .modal .modal-dialog .modal-content,
    .product-registration-box .modal-dialog .modal-content {
      background-color: #fff;
      padding: 0;
      width: 100%;
      margin: auto;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0;
      text-align: left; }
      @media (min-width: 768px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 95%; } }
      @media (min-width: 992px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 920px;
          max-width: 95%; } }
      @media (min-width: 1200px) {
        .modal .modal-dialog .modal-content,
        .product-registration-box .modal-dialog .modal-content {
          width: 1100px; } }
      .modal .modal-dialog .modal-content label,
      .product-registration-box .modal-dialog .modal-content label {
        font-size: 15px;
        color: #000;
        margin-bottom: 7px;
        display: block;
        letter-spacing: 0;
        line-height: 22px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content label,
          .product-registration-box .modal-dialog .modal-content label {
            font-size: 18px;
            line-height: 26px; } }
      .modal .modal-dialog .modal-content input[type="text"],
      .modal .modal-dialog .modal-content input[type="password"],
      .modal .modal-dialog .modal-content select,
      .modal .modal-dialog .modal-content textarea,
      .product-registration-box .modal-dialog .modal-content input[type="text"],
      .product-registration-box .modal-dialog .modal-content input[type="password"],
      .product-registration-box .modal-dialog .modal-content select,
      .product-registration-box .modal-dialog .modal-content textarea {
        background-color: #e0e0e0;
        width: 100%;
        padding: 8px 10px;
        height: 38px;
        color: #000;
        margin-bottom: 15px;
        border-radius: 0 !important;
        position: relative;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-weight: 200;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
        .modal .modal-dialog .modal-content input[type="text"]:focus,
        .modal .modal-dialog .modal-content input[type="password"]:focus,
        .modal .modal-dialog .modal-content select:focus,
        .modal .modal-dialog .modal-content textarea:focus,
        .product-registration-box .modal-dialog .modal-content input[type="text"]:focus,
        .product-registration-box .modal-dialog .modal-content input[type="password"]:focus,
        .product-registration-box .modal-dialog .modal-content select:focus,
        .product-registration-box .modal-dialog .modal-content textarea:focus {
          border: 1px solid #c7c7c7;
          outline: 0; }
      .modal .modal-dialog .modal-content select::-ms-expand,
      .product-registration-box .modal-dialog .modal-content select::-ms-expand {
        display: none; }
      .modal .modal-dialog .modal-content textarea,
      .product-registration-box .modal-dialog .modal-content textarea {
        height: 100px;
        margin-bottom: 0px; }
        .modal .modal-dialog .modal-content textarea:-webkit-resizer,
        .product-registration-box .modal-dialog .modal-content textarea:-webkit-resizer {
          display: none; }
      .modal .modal-dialog .modal-content .modal-header,
      .product-registration-box .modal-dialog .modal-content .modal-header {
        background-color: #0078c0;
        padding: 10px;
        margin-bottom: 10px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 20px;
            margin-bottom: 20px; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 30px; } }
        @media (min-width: 1400px) {
          .modal .modal-dialog .modal-content .modal-header,
          .product-registration-box .modal-dialog .modal-content .modal-header {
            padding: 15px 50px; } }
        .modal .modal-dialog .modal-content .modal-header .modal-title,
        .product-registration-box .modal-dialog .modal-content .modal-header .modal-title {
          margin: 0px;
          padding: 0px;
          border-bottom: 0; }
        .modal .modal-dialog .modal-content .modal-header h2,
        .product-registration-box .modal-dialog .modal-content .modal-header h2 {
          color: #000;
          font-weight: 800;
          font-size: 25px; }
          @media (min-width: 768px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 30px; } }
          @media (min-width: 1200px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 35px; } }
          @media (min-width: 1400px) {
            .modal .modal-dialog .modal-content .modal-header h2,
            .product-registration-box .modal-dialog .modal-content .modal-header h2 {
              font-size: 41px; } }
      .modal .modal-dialog .modal-content .modal-body,
      .modal .modal-dialog .modal-content .modal-footer,
      .product-registration-box .modal-dialog .modal-content .modal-body,
      .product-registration-box .modal-dialog .modal-content .modal-footer {
        margin-top: 0px;
        padding: 0 10px 20px 10px; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 20px 30px 20px; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 30px 30px 30px; } }
        @media (min-width: 1400px) {
          .modal .modal-dialog .modal-content .modal-body,
          .modal .modal-dialog .modal-content .modal-footer,
          .product-registration-box .modal-dialog .modal-content .modal-body,
          .product-registration-box .modal-dialog .modal-content .modal-footer {
            padding: 0 50px 50px 50px; } }
      .modal .modal-dialog .modal-content .modal-body,
      .product-registration-box .modal-dialog .modal-content .modal-body {
        text-align: left; }
        .modal .modal-dialog .modal-content .modal-body .modal-perex,
        .product-registration-box .modal-dialog .modal-content .modal-body .modal-perex {
          margin: 10px 0 30px;
          text-align: left;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600; }
          @media (min-width: 545px) {
            .modal .modal-dialog .modal-content .modal-body .modal-perex,
            .product-registration-box .modal-dialog .modal-content .modal-body .modal-perex {
              margin-bottom: 30px; } }
      .modal .modal-dialog .modal-content .contact-submit-cont,
      .product-registration-box .modal-dialog .modal-content .contact-submit-cont {
        width: 100%;
        margin: 0; }
      .modal .modal-dialog .modal-content .contact-submit-text,
      .product-registration-box .modal-dialog .modal-content .contact-submit-text {
        font-size: 12px;
        color: #999;
        text-align: left;
        margin-bottom: 5px; }
        @media (min-width: 545px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            margin-bottom: 10px; } }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            font-size: 13px; } }
        @media (min-width: 992px) {
          .modal .modal-dialog .modal-content .contact-submit-text,
          .product-registration-box .modal-dialog .modal-content .contact-submit-text {
            margin-bottom: 18px; } }
      .modal .modal-dialog .modal-content input[type="submit"],
      .product-registration-box .modal-dialog .modal-content input[type="submit"] {
        width: 100%;
        padding: 0 15px;
        min-height: 40px;
        background-color: #0078c0;
        -webkit-transition-property: background-color, color;
        -moz-transition-property: background-color, color;
        -o-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        font-size: 16px;
        font-weight: 800;
        line-height: 20px;
        text-decoration: none;
        color: black;
        text-align: center;
        border: 0px;
        cursor: pointer;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0; }
        .modal .modal-dialog .modal-content input[type="submit"]:focus, .modal .modal-dialog .modal-content input[type="submit"]:hover,
        .product-registration-box .modal-dialog .modal-content input[type="submit"]:focus,
        .product-registration-box .modal-dialog .modal-content input[type="submit"]:hover {
          color: #fff !important;
          background: #000;
          text-decoration: none !important; }
        @media (min-width: 768px) {
          .modal .modal-dialog .modal-content input[type="submit"],
          .product-registration-box .modal-dialog .modal-content input[type="submit"] {
            font-size: 18px; } }
  .modal .ErrorLabel,
  .modal .EditingFormErrorLabel,
  .product-registration-box .ErrorLabel,
  .product-registration-box .EditingFormErrorLabel {
    position: relative;
    display: block;
    width: 100%;
    margin: 20px 0px;
    padding-left: 15px;
    background-color: #0078c0; }

#modal-languages .modal-content {
  padding: 20px;
  background-color: #0086ce; }
  #modal-languages .modal-content .modal-header {
    background-color: #0086ce; }
    #modal-languages .modal-content .modal-header .modal-title {
      font-size: 22px;
      font-weight: 400;
      color: #fff; }
      @media (min-width: 768px) {
        #modal-languages .modal-content .modal-header .modal-title {
          font-size: 28px; } }

#modal-languages .modal-language-country h3 {
  font-size: 16px;
  font-weight: 800; }
  @media (min-width: 992px) {
    #modal-languages .modal-language-country h3 {
      font-size: 18px; } }

#modal-languages .modal-language-country h4 {
  font-size: 14px;
  font-weight: normal; }
  #modal-languages .modal-language-country h4:last-of-type {
    text-transform: uppercase; }

#modal-contact .modal-content {
  padding: 0; }
  #modal-contact .modal-content .modal-body {
    padding-bottom: 0px; }
    #modal-contact .modal-content .modal-body textarea {
      height: 88px;
      margin-bottom: 20px; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-body textarea {
          margin-bottom: 7px; } }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-body textarea {
          height: 138px; } }
    #modal-contact .modal-content .modal-body .recaptcha-cont {
      display: block;
      width: 100%;
      position: relative;
      text-align: center; }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-body .recaptcha-cont {
          text-align: left;
          min-height: 74px; } }
      #modal-contact .modal-content .modal-body .recaptcha-cont div {
        display: inline-block !important;
        padding: 0px !important;
        margin: 0px auto !important; }
      @media (min-width: 320px) and (max-width: 479px) {
        #modal-contact .modal-content .modal-body .recaptcha-cont > div {
          -webkit-transform-origin: "left top";
          -moz-transform-origin: "left top";
          -ms-transform-origin: "left top";
          -o-transform-origin: "left top";
          transform-origin: "left top";
          transform-origin: left top; } }
    #modal-contact .modal-content .modal-body > span:first-of-type {
      display: inline-block;
      padding-bottom: 4rem; }
  #modal-contact .modal-content .modal-footer {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column wrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: wrap;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
    padding-top: 20px; }
    @media (min-width: 545px) {
      #modal-contact .modal-content .modal-footer {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: flex-start;
        -moz-box-align: flex-start;
        -ms-flex-align: flex-start;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -moz-flex-flow: row nowrap;
        -ms-flex-direction: row;
        -ms-flex-wrap: nowrap;
        -webkit-flex-flow: row nowrap;
        flex-flow: row nowrap; } }
    #modal-contact .modal-content .modal-footer .contact-upload-cont {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      width: 100%;
      margin: 0px;
      margin: 20px 0;
      margin-top: auto; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          display: block;
          width: 35%;
          margin: 0;
          margin-top: auto;
          padding-right: 13px; } }
      @media (min-width: 768px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          padding-right: 44px; } }
      @media (min-width: 992px) {
        #modal-contact .modal-content .modal-footer .contact-upload-cont {
          width: 25%;
          padding-right: 20px;
          margin-top: auto; } }
      #modal-contact .modal-content .modal-footer .contact-upload-cont input[type="file"] {
        display: none; }
      #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
        font-size: 14px;
        text-align: left;
        order: 2;
        padding-left: 5px;
        line-height: 19px; }
        @media (min-width: 545px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
            font-size: 14px;
            margin-bottom: 10px;
            padding-left: 0; } }
        @media (min-width: 992px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-text {
            font-size: 18px;
            margin-bottom: 18px; } }
      #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-button {
        min-width: 125px;
        font-size: 16px;
        order: 1;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
        @media (min-width: 545px) {
          #modal-contact .modal-content .modal-footer .contact-upload-cont .contact-upload-button {
            width: 100%; } }
    @media (min-width: 545px) {
      #modal-contact .modal-content .modal-footer .contact-submit-cont {
        width: 68%; } }
    @media (min-width: 992px) {
      #modal-contact .modal-content .modal-footer .contact-submit-cont {
        width: 75%; } }
    #modal-contact .modal-content .modal-footer .contact-submit-cont .contact-submit-text {
      line-height: 19px; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-footer .contact-submit-cont .contact-submit-text {
          text-align: right; } }
    #modal-contact .modal-content .modal-footer .contact-submit-cont .gdpr {
      margin-bottom: 3px; }
      @media (min-width: 545px) {
        #modal-contact .modal-content .modal-footer .contact-submit-cont .gdpr {
          text-align: right; } }

#modal-sign-up .modal-content {
  padding: 0; }

#modal-login .form-control-error {
  padding: 5px 10px;
  color: #000;
  background: #ffd100;
  font-size: 14px;
  margin: -10px 0 15px 0; }

#modal-login .form-control-error[style*="display: inline"] {
  display: block !important; }

#modal-login .modal-content {
  padding: 0;
  max-width: 800px; }
  #modal-login .modal-content .modal-body {
    padding-bottom: 10px; }
    #modal-login .modal-content .modal-body table {
      width: 100%; }
  #modal-login .modal-content .contact-submit-text {
    margin-top: 7px;
    margin-bottom: 0px; }
    @media (min-width: 545px) {
      #modal-login .modal-content .contact-submit-text {
        margin-top: 10px;
        margin-bottom: 0px; } }
    @media (min-width: 992px) {
      #modal-login .modal-content .contact-submit-text {
        margin-bottom: 0px; } }

.product-registration-box {
  margin-top: 50px; }
  .product-registration-box .modal-dialog .modal-content {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-color: #e0e0e0; }
    .product-registration-box .modal-dialog .modal-content .modal-header {
      padding: 0; }
      .product-registration-box .modal-dialog .modal-content .modal-header .modal-title {
        padding: 10px;
        font-size: 30px;
        line-height: 35px; }
        @media (min-width: 545px) {
          .product-registration-box .modal-dialog .modal-content .modal-header .modal-title {
            padding: 10px 20px;
            font-size: 35px;
            line-height: 40px; } }
        @media (min-width: 992px) {
          .product-registration-box .modal-dialog .modal-content .modal-header .modal-title {
            font-size: 40px;
            line-height: 45px; } }
    .product-registration-box .modal-dialog .modal-content .modal-body,
    .product-registration-box .modal-dialog .modal-content .modal-footer {
      margin-top: 0px;
      padding: 0 7px 15px 7px; }
      @media (min-width: 768px) {
        .product-registration-box .modal-dialog .modal-content .modal-body,
        .product-registration-box .modal-dialog .modal-content .modal-footer {
          padding: 0 20px 20px 20px; } }
    .product-registration-box .modal-dialog .modal-content .modal-body {
      padding-bottom: 5px; }
      .product-registration-box .modal-dialog .modal-content .modal-body label {
        font-size: 13px;
        margin-bottom: 5px;
        line-height: 19px; }
        @media (min-width: 768px) {
          .product-registration-box .modal-dialog .modal-content .modal-body label {
            font-size: 15px; } }
        @media (min-width: 992px) {
          .product-registration-box .modal-dialog .modal-content .modal-body label {
            font-size: 18px;
            margin-bottom: 10px;
            line-height: 22px; } }
      .product-registration-box .modal-dialog .modal-content .modal-body input[type="text"],
      .product-registration-box .modal-dialog .modal-content .modal-body input[type="password"],
      .product-registration-box .modal-dialog .modal-content .modal-body select,
      .product-registration-box .modal-dialog .modal-content .modal-body textarea {
        background: #fff; }
        .product-registration-box .modal-dialog .modal-content .modal-body input[type="text"]:focus,
        .product-registration-box .modal-dialog .modal-content .modal-body input[type="password"]:focus,
        .product-registration-box .modal-dialog .modal-content .modal-body select:focus,
        .product-registration-box .modal-dialog .modal-content .modal-body textarea:focus {
          background: #f2f2f2; }
    .product-registration-box .modal-dialog .modal-content .modal-footer {
      margin: 0; }
  .product-registration-box-add-inputs {
    display: inline-block;
    font-size: 13px;
    color: #7a7a7a !important;
    margin-bottom: 15px; }
  .product-registration-box-add-more-product {
    padding-top: 5px;
    padding-bottom: 10px;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
    border-bottom: 2px solid #000; }
    @media (min-width: 768px) {
      .product-registration-box-add-more-product {
        padding-bottom: 20px; } }
    @media (min-width: 992px) {
      .product-registration-box-add-more-product {
        padding-top: 25px; } }

#modal-serial-number .modal-content {
  max-width: 800px; }

@media (min-width: 1400px) {
  #modal-serial-number .modal-header {
    padding: 25px 50px; } }

#modal-serial-number .modal-header .modal-title {
  font-size: 25px;
  line-height: 25px; }
  @media (min-width: 768px) {
    #modal-serial-number .modal-header .modal-title {
      font-size: 30px;
      line-height: 30px; } }
  @media (min-width: 992px) {
    #modal-serial-number .modal-header .modal-title {
      font-size: 35px;
      line-height: 35px; } }
  @media (min-width: 1400px) {
    #modal-serial-number .modal-header .modal-title {
      font-size: 41px;
      line-height: 41px; } }

#modal-serial-number .modal-body .modal-serial-number-image {
  width: 100%; }

.field-required:after {
  content: "*";
  color: #ff5445; }

.close,
.close-yellow,
.close-black {
  position: relative;
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  opacity: 1 !important;
  border: 1px solid #fff !important;
  outline: 0;
  text-shadow: none !important; }
  .close:after,
  .close-yellow:after,
  .close-black:after {
    content: "\e919";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    -moz-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    -webkit-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    cursor: pointer;
    font-size: 14px; }
  .close:focus, .close:hover,
  .close-yellow:focus,
  .close-yellow:hover,
  .close-black:focus,
  .close-black:hover {
    background-color: #fff !important;
    outline: 0; }
    .close:focus:after, .close:hover:after,
    .close-yellow:focus:after,
    .close-yellow:hover:after,
    .close-black:focus:after,
    .close-black:hover:after {
      color: #000; }

.close-yellow {
  border: 1px solid #000 !important; }
  .close-yellow:after {
    color: #000 !important; }
  .close-yellow:focus, .close-yellow:hover {
    background-color: #000 !important; }
    .close-yellow:focus:after, .close-yellow:hover:after {
      color: #0078c0 !important; }

.padding-footer {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.hp-heroimage {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .hp-heroimage-container {
    background-position: center;
    background-size: cover;
    margin: 0 auto;
    position: relative; }
    .hp-heroimage-container:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.43);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .hp-heroimage-content {
    z-index: 9;
    flex-grow: 1;
    display: flex;
    max-width: 100%;
    overflow: hidden; }
    .hp-heroimage-content .pux-container {
      display: flex;
      width: 100%; }
    .hp-heroimage-content-inner {
      position: relative;
      width: 100%;
      padding-top: 35px;
      display: flex; }
    .hp-heroimage-content-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      padding: 60px 0; }
      @media (min-width: 768px) {
        .hp-heroimage-content-title {
          flex-grow: 1;
          padding-right: 5%;
          padding: 0 0 80px; } }
      .hp-heroimage-content-title .btn-primary {
        margin-top: 15px; }
    .hp-heroimage-content-circles {
      display: none; }
      @media (min-width: 768px) {
        .hp-heroimage-content-circles {
          display: block;
          flex: 0 0 110px;
          max-width: 110px;
          padding-top: 10%; } }
      @media (min-width: 1200px) {
        .hp-heroimage-content-circles {
          flex: 0 0 150px;
          max-width: 150px; } }
      .hp-heroimage-content-circles .colored-circle:not(:first-of-type) {
        margin-top: 15px; }
        @media (min-width: 992px) {
          .hp-heroimage-content-circles .colored-circle:not(:first-of-type) {
            margin-top: 20px; } }
        @media (min-width: 1200px) {
          .hp-heroimage-content-circles .colored-circle:not(:first-of-type) {
            margin-top: 25px; } }
        @media (min-width: 1400px) {
          .hp-heroimage-content-circles .colored-circle:not(:first-of-type) {
            margin-top: 28px; } }

.colored-box {
  padding: 23px 25px 13px;
  line-height: 1.4;
  font-size: 16px;
  text-decoration: none;
  display: block;
  flex: 0 1 100%;
  max-width: 100%; }
  @media (min-width: 545px) {
    .colored-box {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 768px) {
    .colored-box {
      font-size: 18px;
      flex: 0 1 33.33%;
      max-width: 33.33%; } }
  @media (min-width: 1200px) {
    .colored-box {
      font-size: 2rem; } }
  @media (min-width: 1500px) {
    .colored-box {
      max-width: 35rem; } }
  .colored-box h1,
  .colored-box h2,
  .colored-box h3,
  .colored-box h4,
  .colored-box big {
    font-family: 24px;
    padding: 0;
    margin: 0;
    line-height: inherit;
    font-size: 24px;
    font-weight: 600; }
    @media (min-width: 768px) {
      .colored-box h1,
      .colored-box h2,
      .colored-box h3,
      .colored-box h4,
      .colored-box big {
        font-size: 26px; } }
    @media (min-width: 1200px) {
      .colored-box h1,
      .colored-box h2,
      .colored-box h3,
      .colored-box h4,
      .colored-box big {
        font-size: 3.2rem; } }
  .colored-box:not(.white-type) h1,
  .colored-box:not(.white-type) h2,
  .colored-box:not(.white-type) h3,
  .colored-box:not(.white-type) h4,
  .colored-box:not(.white-type) big {
    color: #fff; }
  .colored-box:not(.white-type) .arrow-link {
    color: #fff; }
    .colored-box:not(.white-type) .arrow-link:after {
      color: #fff;
      border-color: #fff; }
    .colored-box:not(.white-type) .arrow-link:hover:after, .colored-box:not(.white-type) .arrow-link:focus:after {
      color: #000;
      border-color: #fff;
      background-color: #fff; }
  .colored-box .arrow-link {
    margin-top: 15px; }
  .colored-box.black-type {
    background-color: #000;
    color: #fff; }
  .colored-box.blue-type {
    background-color: #0078c0;
    color: #fff; }
    .colored-box.blue-type .arrow-link:hover, .colored-box.blue-type .arrow-link:focus {
      color: #fff; }
      .colored-box.blue-type .arrow-link:hover:after, .colored-box.blue-type .arrow-link:focus:after {
        color: #000;
        border-color: #fff; }
  .colored-box.white-type {
    background-color: #fff;
    color: #000; }
    @media (min-width: 545px) and (max-width: 767px) {
      .colored-box.white-type {
        flex: 0 1 100%;
        max-width: 100%; } }

.division-box {
  margin: 0 -15px; }

.division-item-news-box {
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 545px) {
    .division-item-news-box {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1200px) {
    .division-item-news-box {
      flex: 0 0 25%;
      max-width: 25%; } }

.hp-news-container {
  display: flex;
  flex-wrap: wrap; }

.division-item-news {
  display: block;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
  position: relative;
  text-decoration: underline;
  color: #fff;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  transition: 0.3s ease all;
  /*&.black-text {
    color: $color-black;

    .division-item-news-text h3 {
      border-bottom: 1px solid $color-black;
    }
  }*/ }
  .division-item-news:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 11;
    background: #0078c0;
    background: linear-gradient(0deg, #0078c0 0%, rgba(0, 120, 192, 0.5) 35%, rgba(0, 0, 0, 0) 100%); }
  .division-item-news:hover, .division-item-news:focus {
    color: #fff; }
    .division-item-news:hover h3, .division-item-news:focus h3 {
      color: #fff; }
    .division-item-news:hover:before, .division-item-news:focus:before {
      background: linear-gradient(0deg, #0078c0 0%, rgba(0, 120, 192, 0.5) 55%, rgba(0, 0, 0, 0) 100%); }
    .division-item-news:hover.black-text, .division-item-news:focus.black-text {
      color: #fff; }
      .division-item-news:hover.black-text h3, .division-item-news:focus.black-text h3 {
        color: #fff; }
  .division-item-news .division-item-news-text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    z-index: 22; }
    @media (min-width: 992px) {
      .division-item-news .division-item-news-text {
        padding: 3rem 6rem; } }
    .division-item-news .division-item-news-text h3 {
      font-weight: 400;
      border-bottom: 1px solid #fff;
      padding-bottom: 1rem;
      padding-right: 2rem; }
      @media (min-width: 320px) {
        .division-item-news .division-item-news-text h3 {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .division-item-news .division-item-news-text h3 {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .division-item-news .division-item-news-text h3 {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .division-item-news .division-item-news-text h3 {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .division-item-news .division-item-news-text h3 {
          font-size: 2.6rem; } }
      @media (min-width: 320px) {
        .division-item-news .division-item-news-text h3 {
          line-height: 5rem; } }
      @media (min-width: 480px) {
        .division-item-news .division-item-news-text h3 {
          line-height: 4.54545rem; } }
      @media (min-width: 545px) {
        .division-item-news .division-item-news-text h3 {
          line-height: 4.16667rem; } }
      @media (min-width: 768px) {
        .division-item-news .division-item-news-text h3 {
          line-height: 3.33333rem; } }
      @media (min-width: 992px) {
        .division-item-news .division-item-news-text h3 {
          line-height: 3.2rem; } }
    .division-item-news .division-item-news-text .division-item-news-date {
      padding-right: 2rem; }

.colored-box-container {
  position: relative;
  z-index: 8;
  width: 100%; }
  .colored-box-container-inner {
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1500px) {
      .colored-box-container-inner {
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
        width: 56%;
        right: 0; } }

.talents-testimonial-item {
  max-width: 1055px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; }
  @media (min-width: 545px) {
    .talents-testimonial-item {
      flex-wrap: nowrap; } }
  .talents-testimonial-item .talents-testimonial-item-image {
    min-width: 165px;
    width: 165px;
    margin: 0 auto 6rem auto; }
    @media (min-width: 545px) {
      .talents-testimonial-item .talents-testimonial-item-image {
        margin: 0 4rem 0 0; } }
  .talents-testimonial-item .talents-testimonial-item-desc {
    background-color: #A5A5A5;
    padding: 2.7rem 2.7rem 1.5rem 2.7rem;
    border-radius: 10px;
    position: relative;
    width: 100%; }
    @media (min-width: 545px) {
      .talents-testimonial-item .talents-testimonial-item-desc {
        width: auto;
        border-radius: 0px 10px 10px 10px; } }
    .talents-testimonial-item .talents-testimonial-item-desc:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 20px 20px 20px;
      border-color: transparent transparent #A5A5A5 transparent;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%); }
      @media (min-width: 545px) {
        .talents-testimonial-item .talents-testimonial-item-desc:before {
          top: 0;
          left: -20px;
          transform: translateX(0);
          border-width: 0 20px 20px 0;
          border-color: transparent #A5A5A5 transparent transparent; } }
  .talents-testimonial-item .talents-testimonial-item-bottom {
    margin-top: 10px; }
  .talents-testimonial-item .talents-testimonial-item-name {
    display: inline-block;
    color: #000;
    font-weight: 600; }

.talents-testimonial {
  margin: 30px 0; }
  @media (min-width: 768px) {
    .talents-testimonial {
      margin: 50px 0; } }

.devider-title {
  margin-top: 40px; }
  @media (min-width: 768px) {
    .devider-title {
      margin-top: 50px; } }
  @media (min-width: 1200px) {
    .devider-title {
      margin-top: 65px; } }
  .devider-title .pux-container {
    padding: 0; }
  .devider-title .flex-box {
    flex-wrap: wrap; }
  .devider-title a {
    color: #000; }

.division-box {
  flex-wrap: wrap; }

.division-box-container.two-cards .products-item-card-box,
.division-box-container.four-cards .products-item-card-box {
  padding: 1.5rem; }

.division-box-container.two-cards .product-item-card,
.division-box-container.four-cards .product-item-card {
  padding-bottom: 80%; }
  .division-box-container.two-cards .product-item-card-inner,
  .division-box-container.four-cards .product-item-card-inner {
    display: block; }
  .division-box-container.two-cards .product-item-card-img,
  .division-box-container.four-cards .product-item-card-img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    max-height: 100%; }
    .division-box-container.two-cards .product-item-card-img img,
    .division-box-container.four-cards .product-item-card-img img {
      padding-top: 0;
      height: auto !important; }

.division-box-container.two-cards h4,
.division-box-container.four-cards h4 {
  position: absolute;
  left: 0;
  bottom: 0; }

.two-cards .division-box .products-item-card-box,
.two-cards .division-box .download-item-box {
  flex: 0 0 50%;
  max-width: 50%; }

.two-cards .division-box h4 {
  display: none; }

.three-cards .division-box {
  margin: 0 -7px;
  padding-top: 10px; }
  .three-cards .division-box .products-item-card-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 7px; }
    @media (min-width: 545px) {
      .three-cards .division-box .products-item-card-box {
        flex: 0 0 33.333%;
        max-width: 33.333%; } }
  .three-cards .division-box .products-item-card {
    height: 0;
    padding-bottom: 62%; }

.four-cards .division-box .products-item-card-box {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 768px) {
    .four-cards .division-box .products-item-card-box {
      flex: 0 0 25%;
      max-width: 25%; } }

.four-cards .division-box h4 {
  display: none; }

.innovation {
  background-color: #f5f4f5;
  padding: 1.5rem; }
  .innovation .devider-title {
    margin-top: 0; }
  .innovation .division-box {
    margin: 3rem 0 0 0; }
    @media (min-width: 768px) {
      .innovation .division-box {
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-end; } }
    .innovation .division-box .row {
      margin-left: 0;
      margin-right: 0; }
      @media (min-width: 545px) {
        .innovation .division-box .row {
          margin: 0 -7px; } }
    .innovation .division-box-perex, .innovation .division-box-perex-respo {
      font-weight: 800;
      margin-bottom: 2rem;
      display: none; }
      @media (min-width: 320px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          font-size: 6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          font-size: 5.45455rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          font-size: 5.1rem; } }
      @media (min-width: 320px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          line-height: 6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          line-height: 5.45455rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          line-height: 5rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          line-height: 4.8rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-perex, .innovation .division-box-perex-respo {
          display: block; } }
      .innovation .division-box-perex-respo, .innovation .division-box-perex-respo-respo {
        display: block;
        padding: 0 0 10px 0; }
        @media (min-width: 768px) {
          .innovation .division-box-perex-respo, .innovation .division-box-perex-respo-respo {
            display: none; } }
  .innovation .division-box-products {
    width: 100%; }
    @media (min-width: 768px) {
      .innovation .division-box-products {
        width: auto; } }
  .innovation .products-item-card-box {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 7px 0; }
    @media (min-width: 545px) {
      .innovation .products-item-card-box {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 7px; } }
  .innovation .product-item-card {
    position: relative;
    padding-bottom: 47%;
    border-color: transparent;
    height: 100%; }
    .innovation .product-item-card h4 {
      color: #fff; }
  .innovation .division-box-innovator {
    position: relative; }
    .innovation .division-box-innovator-person {
      display: flex;
      align-items: flex-end;
      margin: 3rem 0; }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-person {
          display: block;
          margin: 0; } }
    .innovation .division-box-innovator img {
      max-width: 20rem; }
      @media (min-width: 768px) {
        .innovation .division-box-innovator img {
          margin-bottom: 7px;
          max-width: 30rem; } }
      @media (min-width: 1400px) {
        .innovation .division-box-innovator img {
          min-width: 38rem;
          max-width: none; } }
    .innovation .division-box-innovator-text {
      width: 100%;
      z-index: 2;
      font-weight: 800; }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-text {
          color: #fff;
          text-align: center;
          position: absolute;
          bottom: 2rem;
          left: 0; } }
      @media (min-width: 320px) {
        .innovation .division-box-innovator-text-name {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-innovator-text-name {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-innovator-text-name {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-text-name {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .innovation .division-box-innovator-text-name {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .innovation .division-box-innovator-text-name {
          font-size: 2.1rem; } }
      @media (min-width: 320px) {
        .innovation .division-box-innovator-text-name {
          line-height: 3.6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-innovator-text-name {
          line-height: 3.27273rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-innovator-text-name {
          line-height: 3rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-text-name {
          line-height: 2.4rem; } }
      @media (min-width: 992px) {
        .innovation .division-box-innovator-text-name {
          line-height: 2.25rem; } }
      @media (min-width: 1200px) {
        .innovation .division-box-innovator-text-name {
          line-height: 2.1rem; } }
      @media (min-width: 320px) {
        .innovation .division-box-innovator-text-position {
          font-size: 2.6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-innovator-text-position {
          font-size: 2.36364rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-innovator-text-position {
          font-size: 2.16667rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-text-position {
          font-size: 1.73333rem; } }
      @media (min-width: 992px) {
        .innovation .division-box-innovator-text-position {
          font-size: 1.625rem; } }
      @media (min-width: 1200px) {
        .innovation .division-box-innovator-text-position {
          font-size: 1.44444rem; } }
      @media (min-width: 1400px) {
        .innovation .division-box-innovator-text-position {
          font-size: 1.4rem; } }
      @media (min-width: 320px) {
        .innovation .division-box-innovator-text-position {
          line-height: 2.6rem; } }
      @media (min-width: 480px) {
        .innovation .division-box-innovator-text-position {
          line-height: 2.36364rem; } }
      @media (min-width: 545px) {
        .innovation .division-box-innovator-text-position {
          line-height: 2.16667rem; } }
      @media (min-width: 768px) {
        .innovation .division-box-innovator-text-position {
          line-height: 1.73333rem; } }
      @media (min-width: 992px) {
        .innovation .division-box-innovator-text-position {
          line-height: 1.625rem; } }
      @media (min-width: 1200px) {
        .innovation .division-box-innovator-text-position {
          line-height: 1.44444rem; } }
      @media (min-width: 1400px) {
        .innovation .division-box-innovator-text-position {
          line-height: 1.4rem; } }

.solutions-container {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem -3.5px 0 -3.5px; }
  @media (min-width: 768px) {
    .solutions-container {
      flex-wrap: nowrap;
      margin: 2rem 0 0 0;
      width: 100%; } }
  .solutions-container .main-card {
    width: 100%; }
    @media (min-width: 768px) {
      .solutions-container .main-card {
        -webkit-flex: 0 0 40%;
        -moz-flex: 0 0 40%;
        -ms-flex: 0 0 40%;
        -webkit-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
        width: 40%;
        margin: 0; } }
    @media (min-width: 768px) {
      .solutions-container .main-card .products-item-card-box {
        height: 100%;
        width: 100%;
        padding: 0 4px 0 0; } }
    @media (min-width: 768px) {
      .solutions-container .main-card .products-item-card-box .product-item-card {
        padding-bottom: 0%;
        height: 100%;
        width: 100%; } }
  .solutions-container .other-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media (min-width: 768px) {
      .solutions-container .other-cards {
        flex: 0 0 60%;
        max-width: 60%;
        width: 60%; } }
    .solutions-container .other-cards .products-item-card-box {
      flex: 0 0 50%;
      max-width: 50%;
      padding-top: 7px; }
      @media (min-width: 768px) {
        .solutions-container .other-cards .products-item-card-box {
          flex: 0 0 33.333%;
          max-width: 33.3333%;
          padding-top: 0; }
          .solutions-container .other-cards .products-item-card-box:first-child,
          .solutions-container .other-cards .products-item-card-box:nth-child(2) .solutions-container .other-cards .products-item-card-box:nth-child(3) {
            padding-bottom: 7px; } }
  .solutions-container .products-item-card-box {
    padding: 0 3.5px; }
    @media (min-width: 768px) {
      .solutions-container .products-item-card-box {
        padding: 0 0 0 14px; } }
  .solutions-container .products-item-card {
    position: relative;
    display: block;
    padding-bottom: 60%;
    height: 0;
    border-color: transparent; }
    .solutions-container .products-item-card-img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .solutions-container .products-item-card-img img {
        width: 100%; }
    .solutions-container .products-item-card h4 {
      position: absolute;
      color: #fff; }

.hp-download-widget {
  display: flex;
  flex-wrap: wrap; }
  .hp-download-widget .download-item-box {
    flex: 0 0 100%; }
    @media (min-width: 545px) {
      .hp-download-widget .download-item-box {
        flex: 0 0 50%;
        max-width: 50%; } }
    .hp-download-widget .download-item-box:nth-child(odd) .colored-title {
      margin-left: auto;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 1200px) {
        .hp-download-widget .download-item-box:nth-child(odd) .colored-title {
          padding-left: 17%; } }
  .hp-download-widget .download-item.locked {
    position: relative; }
    .hp-download-widget .download-item.locked:before {
      display: none; }
    .hp-download-widget .download-item.locked h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      .hp-download-widget .download-item.locked h3:before {
        content: "\e90a"; }
  .hp-download-widget .download-item {
    display: block;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 0;
    padding-bottom: 65%;
    position: relative; }
    .hp-download-widget .download-item:hover .btn-primary, .hp-download-widget .download-item:focus .btn-primary {
      color: #0078c0 !important;
      border-color: #0078c0 !important; }
    .hp-download-widget .download-item .perex {
      display: none; }
    .hp-download-widget .download-item .colored-title {
      padding-top: 4rem;
      padding-left: 15px;
      padding-right: 15px; }
      @media (min-width: 545px) {
        .hp-download-widget .download-item .colored-title {
          padding-right: 1.5rem;
          padding-left: 10rem; } }
      @media (min-width: 1200px) {
        .hp-download-widget .download-item .colored-title {
          padding-top: 8rem;
          padding-left: 14rem; } }
      .hp-download-widget .download-item .colored-title h3 {
        text-decoration: none;
        color: #fff;
        margin: 0; }
        @media (min-width: 320px) {
          .hp-download-widget .download-item .colored-title h3 {
            font-size: 9rem; } }
        @media (min-width: 320px) {
          .hp-download-widget .download-item .colored-title h3 {
            line-height: 9rem; } }
    .hp-download-widget .download-item .btn-primary {
      position: absolute;
      right: 7rem;
      bottom: 4rem;
      background: none !important;
      font-weight: 400;
      border-bottom: 1px solid #fff !important;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      padding: 0 2rem 1rem 0;
      max-width: 100%; }
      @media (min-width: 320px) {
        .hp-download-widget .download-item .btn-primary {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .hp-download-widget .download-item .btn-primary {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .hp-download-widget .download-item .btn-primary {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .hp-download-widget .download-item .btn-primary {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .hp-download-widget .download-item .btn-primary {
          font-size: 2.6rem; } }
      @media (min-width: 320px) {
        .hp-download-widget .download-item .btn-primary {
          line-height: 5rem; } }
      @media (min-width: 480px) {
        .hp-download-widget .download-item .btn-primary {
          line-height: 4.54545rem; } }
      @media (min-width: 545px) {
        .hp-download-widget .download-item .btn-primary {
          line-height: 4.16667rem; } }
      @media (min-width: 768px) {
        .hp-download-widget .download-item .btn-primary {
          line-height: 3.33333rem; } }
      @media (min-width: 992px) {
        .hp-download-widget .download-item .btn-primary {
          line-height: 3.2rem; } }
  .hp-download-widget .download-item-image {
    display: none; }

.testimonial-container {
  padding: 9rem 0;
  background-position: top left, bottom right;
  background-size: 80% auto;
  background-repeat: no-repeat; }
  @media (min-width: 1500px) {
    .testimonial-container {
      margin-left: -50px;
      margin-right: -50px; } }

.testimonial {
  position: relative;
  display: flex;
  background-color: #0078c0;
  color: #fff;
  border-radius: 20px;
  padding: 4rem 3rem 3rem 3rem;
  margin: 0 2rem;
  font-weight: 500;
  box-shadow: 0 0 75px 1px rgba(0, 0, 0, 0.09);
  line-height: 1.25; }
  @media (min-width: 320px) {
    .testimonial {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    .testimonial {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    .testimonial {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    .testimonial {
      font-size: 2.9rem; } }
  @media (min-width: 1500px) {
    .testimonial {
      margin: 0 50px;
      padding: 2rem 3rem 3rem 3rem; } }
  .testimonial:before {
    content: '';
    position: absolute;
    top: 100%;
    left: 7rem;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 83px 130px 0 0;
    border-color: #0078c0 transparent transparent transparent; }
  .testimonial .devider-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    margin: 0 0 1.5rem 0; }
    .testimonial .devider-title h2,
    .testimonial .devider-title h3,
    .testimonial .devider-title a {
      color: #fff; }
      .testimonial .devider-title h2:after,
      .testimonial .devider-title h3:after,
      .testimonial .devider-title a:after {
        color: #fff;
        border: 2px solid #fff; }
      .testimonial .devider-title h2:hover:after, .testimonial .devider-title h2:focus:after,
      .testimonial .devider-title h3:hover:after,
      .testimonial .devider-title h3:focus:after,
      .testimonial .devider-title a:hover:after,
      .testimonial .devider-title a:focus:after {
        color: #0078c0;
        background-color: #fff; }
  .testimonial-people {
    text-align: center;
    margin: 0 -3rem; }
    @media (min-width: 768px) {
      .testimonial-people {
        display: flex; } }
    .testimonial-people-container {
      width: 100%; }
    @media (min-width: 768px) {
      .testimonial-people .person-item-box {
        flex: 0 0 33.333%;
        max-width: 33.3333%;
        padding: 0 3rem; } }
    .testimonial-people .person-item {
      font-weight: 400;
      max-width: 50rem;
      margin: 0 auto; }
      @media (min-width: 320px) {
        .testimonial-people .person-item {
          font-size: 2.8rem; } }
      @media (min-width: 480px) {
        .testimonial-people .person-item {
          font-size: 2.54545rem; } }
      @media (min-width: 545px) {
        .testimonial-people .person-item {
          font-size: 2.33333rem; } }
      @media (min-width: 768px) {
        .testimonial-people .person-item {
          font-size: 1.86667rem; } }
      @media (min-width: 992px) {
        .testimonial-people .person-item {
          font-size: 1.75rem; } }
      @media (min-width: 1200px) {
        .testimonial-people .person-item {
          font-size: 1.6rem; } }
      .testimonial-people .person-item-image {
        border-radius: 50%;
        width: 25rem;
        height: 25rem;
        overflow: hidden;
        margin: 3rem auto 1rem auto;
        position: relative; }
        .testimonial-people .person-item-image img {
          min-width: 100%; }
        .testimonial-people .person-item-image h4 {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          text-shadow: 0 5px 9px rgba(3, 4, 3, 0.37);
          max-width: 100%;
          margin-bottom: 4.5rem; }
          @media (min-width: 320px) {
            .testimonial-people .person-item-image h4 {
              font-size: 3.6rem; } }
          @media (min-width: 480px) {
            .testimonial-people .person-item-image h4 {
              font-size: 3.27273rem; } }
          @media (min-width: 545px) {
            .testimonial-people .person-item-image h4 {
              font-size: 3rem; } }
          @media (min-width: 768px) {
            .testimonial-people .person-item-image h4 {
              font-size: 2.4rem; } }
          @media (min-width: 992px) {
            .testimonial-people .person-item-image h4 {
              font-size: 2.3rem; } }
          @media (min-width: 320px) {
            .testimonial-people .person-item-image h4 {
              line-height: 3.6rem; } }
          @media (min-width: 480px) {
            .testimonial-people .person-item-image h4 {
              line-height: 3.27273rem; } }
          @media (min-width: 545px) {
            .testimonial-people .person-item-image h4 {
              line-height: 3rem; } }
          @media (min-width: 768px) {
            .testimonial-people .person-item-image h4 {
              line-height: 2.4rem; } }
          @media (min-width: 992px) {
            .testimonial-people .person-item-image h4 {
              line-height: 2.3rem; } }
  .testimonial-background {
    display: none; }
    @media (min-width: 1400px) {
      .testimonial-background {
        position: static;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6rem; }
        .testimonial-background img {
          flex-shrink: 0; } }

.product-preview {
  padding: 0 0 30px 0; }
  @media (min-width: 480px) {
    .product-preview {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; } }
  .product-preview-image {
    width: 100%; }
    @media (min-width: 480px) {
      .product-preview-image {
        width: 40%; } }
    @media (min-width: 768px) {
      .product-preview-image {
        width: 50%; } }
    @media (min-width: 992px) {
      .product-preview-image {
        width: 50%; } }
    .product-preview-image .img-cont {
      position: relative;
      border: 5px solid #e0e0e0;
      height: 0;
      padding-bottom: -webkit-calc(100% - 10px);
      padding-bottom: -moz-calc(100% - 10px);
      padding-bottom: calc(100% - 10px);
      overflow: hidden; }
      .product-preview-image .img-cont img {
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%; }
        .product-preview-image .img-cont img:not(:first-of-type) {
          display: none; }
  .product-preview-contact {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: column nowrap;
    -ms-flex-direction: column;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    justify-content: space-between; }
    @media (min-width: 480px) {
      .product-preview-contact {
        width: -webkit-calc(60% - 20px);
        width: -moz-calc(60% - 20px);
        width: calc(60% - 20px);
        margin-top: 0px; } }
    @media (min-width: 768px) {
      .product-preview-contact {
        width: -webkit-calc(50% - 20px);
        width: -moz-calc(50% - 20px);
        width: calc(50% - 20px); } }
    @media (min-width: 992px) {
      .product-preview-contact {
        width: -webkit-calc(50% - 35px);
        width: -moz-calc(50% - 35px);
        width: calc(50% - 35px); } }
    .product-preview-contact-perex {
      order: 2;
      line-height: 1.6;
      margin-top: 20px; }
      @media (min-width: 480px) {
        .product-preview-contact-perex {
          margin-top: 0px; } }
    .product-preview-contact-types {
      order: 3;
      margin-top: 8px;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
      @media (min-width: 480px) {
        .product-preview-contact-types {
          border-bottom: 0; } }
      .product-preview-contact-types .product-line {
        width: 100%;
        height: 1px;
        background: #000; }
      .product-preview-contact-types-format, .product-preview-contact-types-load {
        width: 45%;
        padding: 10px 0 14px; }
        @media (min-width: 480px) {
          .product-preview-contact-types-format, .product-preview-contact-types-load {
            width: 48%;
            padding: 13px 0 7px; } }
        @media (min-width: 1200px) {
          .product-preview-contact-types-format, .product-preview-contact-types-load {
            width: 53%;
            padding: 16px 0 12px; } }
        .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
          font-size: 15px;
          font-weight: 600; }
          @media (min-width: 545px) and (max-width: 767px) {
            .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
              font-size: 18px; } }
          @media (min-width: 992px) {
            .product-preview-contact-types-format-title, .product-preview-contact-types-load-title {
              font-size: 18px; } }
        .product-preview-contact-types-format a, .product-preview-contact-types-load a {
          display: inline-block;
          color: #000;
          text-decoration: none;
          padding: 2px 0;
          margin-top: 2px;
          -moz-transition: all ease-in-out 100ms;
          -o-transition: all ease-in-out 100ms;
          -webkit-transition: all ease-in-out 100ms;
          transition: all ease-in-out 100ms;
          border-bottom: 1px solid transparent;
          font-size: 13px; }
          @media (min-width: 545px) and (max-width: 767px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 15px; } }
          @media (min-width: 992px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 15px; } }
          @media (min-width: 1200px) {
            .product-preview-contact-types-format a, .product-preview-contact-types-load a {
              font-size: 17px;
              margin-top: 5px; } }
          .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
            margin-right: 8px; }
            @media (min-width: 992px) {
              .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
                margin-right: 15px; } }
            @media (min-width: 1200px) {
              .product-preview-contact-types-format a:not(:last-of-type), .product-preview-contact-types-load a:not(:last-of-type) {
                margin-right: 18px; } }
          .product-preview-contact-types-format a:hover, .product-preview-contact-types-format a:focus, .product-preview-contact-types-format a.active, .product-preview-contact-types-load a:hover, .product-preview-contact-types-load a:focus, .product-preview-contact-types-load a.active {
            border-bottom: 1px solid #000;
            text-decoration: none !important; }
      @media (min-width: 1200px) {
        .product-preview-contact-types-load {
          width: 47%; } }
    .product-preview-contact-colors {
      order: 0;
      position: relative;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -moz-flex-flow: row nowrap;
      -ms-flex-direction: row;
      -ms-flex-wrap: nowrap;
      -webkit-flex-flow: row nowrap;
      flex-flow: row nowrap;
      padding: 0 2px;
      margin: 12px 0;
      margin-top: 20px; }
      @media (min-width: 992px) {
        .product-preview-contact-colors {
          padding: 14px 0; } }
      @media (min-width: 1200px) {
        .product-preview-contact-colors {
          padding: 8px 5px; } }
      .product-preview-contact-colors:before {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000;
        z-index: 1; }
        @media (min-width: 545px) {
          .product-preview-contact-colors:before {
            content: "";
            position: absolute;
            top: -12px;
            left: 0px; } }
        @media (min-width: 992px) {
          .product-preview-contact-colors:before {
            top: -10px; } }
      .product-preview-contact-colors-item {
        width: 31px;
        height: 31px;
        border-radius: 31px;
        cursor: pointer; }
        .product-preview-contact-colors-item:not(:first-of-type) {
          margin-left: 15px; }
        @media (min-width: 1200px) {
          .product-preview-contact-colors-item {
            width: 39px;
            height: 39px; }
            .product-preview-contact-colors-item:not(:first-of-type) {
              margin-left: 22px; } }

@keyframes loading-animate {
  100% {
    transform: rotate(360deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center; } }

.loading-animation:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 35px;
  position: absolute;
  content: "\e917";
  color: gray;
  top: 47%;
  left: 48%;
  animation: loading-animate 1s linear infinite;
  z-index: 200; }
  @media (min-width: 320px) and (max-width: 991px) {
    .loading-animation:after {
      top: 43%;
      left: 48%; } }

.tech-info {
  padding: 5px 0 25px;
  font-size: 0;
  column-count: 1;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-gap: 22px;
  -webkit-column-gap: 22px;
  -moz-column-gap: 22px; }
  .tech-info dl {
    display: table;
    width: 100%;
    table-layout: fixed;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    border-bottom: 2px solid #8c8c8c;
    padding: 0;
    margin: 0; }
    .tech-info dl:last-of-type {
      border-bottom: 0; }
    .tech-info dl:nth-of-type(2), .tech-info dl:nth-of-type(3) {
      border-bottom: 2px solid #8c8c8c; }
  .tech-info dt {
    background-color: transparent;
    font-weight: 600;
    padding-right: 10px; }
  .tech-info dt, .tech-info dd {
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 4px 3px;
    width: auto; }
  .tech-info dd {
    text-align: right;
    padding-right: 0;
    overflow: visible;
    text-overflow: inherit; }
  .tech-info:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff; }
  .no-columns .tech-info {
    column-count: 1;
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-gap: 0;
    -webkit-column-gap: 0;
    -moz-column-gap: 0; }
  @media (min-width: 545px) {
    .tech-info {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2; }
      .tech-info dt, .tech-info dd {
        font-size: 13px; } }
  @media (min-width: 768px) {
    .tech-info {
      column-count: 1;
      -webkit-column-count: 1;
      -moz-column-count: 1; }
      .tech-info dt, .tech-info dd {
        font-size: 14px; } }
  @media (min-width: 992px) {
    .tech-info {
      column-count: 2;
      -webkit-column-count: 2;
      -moz-column-count: 2; }
      .tech-info dt, .tech-info dd {
        font-size: 13px; } }
  @media (min-width: 1200px) {
    .tech-info {
      column-gap: 14px;
      -webkit-column-gap: 14px;
      -moz-column-gap: 14px; }
      .tech-info dt, .tech-info dd {
        padding: 4px 5px; }
      .tech-info dt {
        width: 43%; }
      .tech-info dd {
        width: 57%; } }

.product-detail {
  display: flex;
  flex-direction: column; }
  .product-detail-container {
    position: relative;
    padding-top: 60px; }
    @media (min-width: 768px) {
      .product-detail-container {
        padding-top: 80px; } }
    @media (min-width: 1200px) {
      .product-detail-container {
        padding-top: 105px; } }
    .product-detail-container .arrow-link-back-in {
      margin-bottom: 2.5rem; }
    .product-detail-container .btn-primary {
      display: inline-block;
      margin: 3rem 0; }
  .product-detail .title-line {
    border-bottom: 0; }
  .product-detail .product-preview {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    @media (min-width: 768px) {
      .product-detail .product-preview {
        flex-direction: row; } }
    .product-detail .product-preview-contact {
      order: 2;
      width: 100%; }
      @media (min-width: 768px) {
        .product-detail .product-preview-contact {
          flex: 0 1 49%;
          max-width: 49%;
          width: auto; } }
      .product-detail .product-preview-contact-perex {
        order: 0 !important; }
    .product-detail .product-preview-image {
      order: 1;
      width: 100%; }
      @media (min-width: 768px) {
        .product-detail .product-preview-image {
          flex: 0 1 49%;
          max-width: 49%;
          width: 100%; } }
      .product-detail .product-preview-image .img-cont {
        width: 100%;
        max-width: 100%;
        margin: 0 auto 15px auto;
        padding-bottom: 80%; }
    .product-detail .product-preview-technical-info h2 {
      margin-bottom: 2px; }
  .product-detail-info {
    padding-top: 14px; }
  .product-detail .product-preview-image .img-cont {
    border: 1px solid #b2b2b2; }
  .product-detail .product-preview-contact-colors {
    margin: 0;
    padding: 0; }
    .product-detail .product-preview-contact-colors:before {
      display: none; }
    .product-detail .product-preview-contact-colors-title {
      font-weight: 600;
      padding-right: 4px;
      padding-top: 1px;
      display: flex;
      align-items: center; }
    .product-detail .product-preview-contact-colors-container {
      display: flex;
      align-items: center;
      padding: 15px 0 20px; }
      @media (min-width: 768px) {
        .product-detail .product-preview-contact-colors-container {
          padding: 17px 0 22px; } }
    .product-detail .product-preview-contact-colors-item {
      width: 27px;
      height: 27px;
      margin-left: 12px;
      border-radius: 50%; }
  .product-detail ul {
    padding-left: 25px; }
    .product-detail ul li {
      text-indent: 4px; }
  .product-detail.accessories-type .title-line {
    border-bottom: 2px solid #000; }
  .product-detail.accessories-type .product-preview {
    margin-top: 16px; }
    @media (min-width: 768px) {
      .product-detail.accessories-type .product-preview-image {
        order: 1; } }
    @media (min-width: 768px) {
      .product-detail.accessories-type .product-preview-contact {
        order: 2; } }
    .product-detail.accessories-type .product-preview .product-preview-technical-info {
      margin-top: 25px; }
  .product-detail .content-gallery {
    margin: 0 -1rem;
    position: relative; }
  .product-detail .content-gallery-list-box {
    padding: 0 1rem;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .product-detail .content-gallery-list-box {
        margin-bottom: 0; } }
  .product-detail .content-gallery-list-item {
    display: block;
    height: 100%;
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    overflow: hidden;
    position: relative;
    border: 1px solid #b2b2b2; }
    .product-detail .content-gallery-list-item img {
      position: absolute;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto; }

.product-detail .slick-track,
.product-detail-accessories .slick-track {
  min-width: 100%; }

.product-detail .slick-arrow,
.product-detail-accessories .slick-arrow {
  font-size: 0;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 1px solid #000;
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  transition: 0.3s ease all;
  outline: 0; }
  .product-detail .slick-arrow:active,
  .product-detail-accessories .slick-arrow:active {
    background-color: #000;
    outline: 0; }
    .product-detail .slick-arrow:active:before,
    .product-detail-accessories .slick-arrow:active:before {
      color: #fff; }
  .product-detail .slick-arrow:before,
  .product-detail-accessories .slick-arrow:before {
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.product-detail .slick-prev,
.product-detail-accessories .slick-prev {
  left: -7px; }
  .product-detail .slick-prev:before,
  .product-detail-accessories .slick-prev:before {
    content: '\e90d'; }

.product-detail .slick-next,
.product-detail-accessories .slick-next {
  right: -7px; }
  .product-detail .slick-next:before,
  .product-detail-accessories .slick-next:before {
    content: '\e908'; }

.product-detail .slick-disabled,
.product-detail-accessories .slick-disabled {
  opacity: 0; }

.product-accessories-title {
  padding: 1rem 0px;
  display: block; }
  .product-accessories-title h3 {
    padding: 0;
    margin: 0; }

.product-accessories-list {
  position: relative;
  padding-top: 1rem; }
  .product-accessories-list.hidden {
    max-width: 100%;
    overflow: hidden; }
    .product-accessories-list.hidden .row {
      flex-wrap: nowrap; }
  .product-accessories-list [data-accessories] + .product-accessories-title {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block; }
  .product-accessories-list .row,
  .product-accessories-list .items-container {
    margin-left: -10px;
    margin-right: -10px; }
    .product-accessories-list .row [class*="col-"],
    .product-accessories-list .items-container [class*="col-"] {
      padding-left: 10px;
      padding-right: 10px; }

.product-accessories-item {
  text-decoration: none !important;
  color: #000;
  display: block; }
  .product-accessories-item-image {
    position: relative;
    overflow: hidden;
    border: 1px solid #ebebeb; }
    .product-accessories-item-image:before {
      content: "";
      display: block;
      padding-top: 66%; }
    .product-accessories-item-image img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      max-height: 80%; }
  .product-accessories-item-text {
    font-size: 14px;
    color: #000;
    text-align: left; }

.product-accessories-btn {
  font-size: 14px;
  padding: 5px 20px;
  border: 1px solid #000;
  font-weight: 600;
  display: none;
  position: relative;
  margin-top: 40px;
  cursor: pointer;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .product-accessories-btn:hover, .product-accessories-btn:focus {
    background-color: #8c8c8c; }
  .product-accessories-btn.show {
    display: inline-block; }

.product-accessories + .product-v2-accessories-container-title {
  display: block; }

.product-accessories-others .product-accessories-title {
  display: inline-block; }

.product-level-1 .title-line {
  border-bottom: 2px solid #000; }

.product-back-btn {
  margin-bottom: 10px; }
  @media (min-width: 992px) {
    .product-back-btn {
      margin: 0; } }
  .product-back-btn .content-button-level-up {
    position: relative;
    font-size: 14px;
    color: #000;
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-left: 36px;
    padding: 0;
    text-decoration: none !important; }
    .product-back-btn .content-button-level-up:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
      width: 100%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      height: 2px;
      background-color: #8f8f8f; }
    .product-back-btn .content-button-level-up:before {
      content: "\e90d";
      font-size: 26px;
      color: #000;
      margin-right: 4px;
      border: 0;
      width: 50px;
      position: absolute;
      top: -2px;
      left: 0;
      transform: translate(-100%, 0); }
      @media (min-width: 992px) {
        .product-back-btn .content-button-level-up:before {
          font-size: 32px; } }
    .product-back-btn .content-button-level-up:hover, .product-back-btn .content-button-level-up:focus {
      color: #000; }
      .product-back-btn .content-button-level-up:hover:before, .product-back-btn .content-button-level-up:focus:before {
        background-color: transparent; }
      .product-back-btn .content-button-level-up:hover:after, .product-back-btn .content-button-level-up:focus:after {
        background-color: #000; }

.contact {
  display: flex;
  flex-wrap: wrap; }
  .contact-cell {
    display: flex;
    font-size: 14px;
    line-height: 21px;
    padding-top: 5px; }
    @media (min-width: 992px) {
      .contact-cell {
        padding-top: 7px; } }
    .contact-cell-container {
      flex: 0 1 100%;
      max-width: 100%; }
      @media (min-width: 545px) {
        .contact-cell-container {
          flex: 0 1 50%;
          max-width: 50%; } }
      @media (min-width: 768px) {
        .contact-cell-container {
          flex: 0 1 100%;
          max-width: 100%; } }
      @media (min-width: 992px) {
        .contact-cell-container {
          flex: 0 1 50%;
          max-width: 50%; } }
    .contact-cell-country {
      flex: 0 0 50px;
      max-width: 50px;
      font-weight: 700; }
    .contact-cell-phone {
      flex: 1 1 100%;
      max-width: calc(100% - 50px); }
      .contact-cell-phone a {
        color: #000;
        text-decoration: none;
        font-weight: 400; }
        .contact-cell-phone a:hover, .contact-cell-phone a:focus {
          text-decoration: underline; }

.product-accessories-container {
  margin-top: 23px;
  display: flex;
  flex-direction: column; }
  .product-accessories-container-title {
    order: -1;
    padding-bottom: 5px; }

.product-variant-btn-list,
.info-tabs-btn-list {
  margin-top: 20px;
  /*         &.first-init {
            .product-tab-btn {
                &:first-of-type {
                    background-color: $color-primary;
                    color: $color-black !important;
                    &:after {
                        transform: translate(-50%, 100%);
                        @include triangle-bottom(20px, 20px, 20px, $color-primary);
                        @include transition(250ms);
                    }
                }
            }
        } */ }
  @media (min-width: 768px) {
    .product-variant-btn-list,
    .info-tabs-btn-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; } }
  .product-variant-btn-list .h3,
  .info-tabs-btn-list .h3 {
    margin: 20px 0 15px;
    margin-right: 6.8rem;
    color: #0078c0;
    text-decoration: none; }
    @media (min-width: 320px) {
      .product-variant-btn-list .h3,
      .info-tabs-btn-list .h3 {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .product-variant-btn-list .h3,
      .info-tabs-btn-list .h3 {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .product-variant-btn-list .h3,
      .info-tabs-btn-list .h3 {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .product-variant-btn-list .h3,
      .info-tabs-btn-list .h3 {
        font-size: 2.8rem; } }
    .product-variant-btn-list .h3:hover, .product-variant-btn-list .h3:focus, .product-variant-btn-list .h3.active,
    .info-tabs-btn-list .h3:hover,
    .info-tabs-btn-list .h3:focus,
    .info-tabs-btn-list .h3.active {
      text-decoration: underline;
      text-decoration-color: #fe761c; }

.info-tabs {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .info-tabs-item {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -moz-transition: all ease-in-out 100ms;
    -o-transition: all ease-in-out 100ms;
    -webkit-transition: all ease-in-out 100ms;
    transition: all ease-in-out 100ms;
    padding-top: 5px; }
    @media (min-width: 992px) {
      .info-tabs-item {
        padding-top: 15px; } }
    .info-tabs-item.active {
      position: static;
      height: auto;
      visibility: visible;
      opacity: 1;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      transition-delay: 100ms; }
  .info-tabs.first-init .info-tabs-item:first-of-type {
    position: static;
    height: auto;
    visibility: visible;
    opacity: 1; }
  .info-tabs .big-table {
    margin-top: 3rem; }

.product-black {
  background-color: #000; }

.product-grey {
  background-color: #919191; }

.product-video-holder {
  position: relative;
  margin-bottom: 20px;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .product-video-holder {
      margin-top: 0;
      margin-bottom: 30px; } }
  .product-video-holder:before {
    content: "";
    display: block;
    padding-bottom: 56.3%; }
  .product-video-holder iframe {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.product-video-text {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .product-video-text {
      margin-bottom: 30px; } }

iframe {
  max-width: 100%; }

.info-tabs-item .download-section {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px; }

.items-container,
.category-item-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem 0 -1.5rem; }
  .items-container .products-item-card-box,
  .category-item-container .products-item-card-box {
    padding: 1.5rem;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 480px) {
      .items-container .products-item-card-box,
      .category-item-container .products-item-card-box {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 768px) {
      .items-container .products-item-card-box,
      .category-item-container .products-item-card-box {
        flex: 0 0 33.3333%;
        max-width: 33.3333%; } }
    @media (min-width: 992px) {
      .items-container .products-item-card-box,
      .category-item-container .products-item-card-box {
        flex: 0 0 25%;
        max-width: 25%; } }

.product-accessories-list {
  margin: 0 -1.5rem 0 -1.5rem;
  display: flex;
  flex-wrap: wrap; }
  .product-accessories-list .products-item-card-box {
    padding: 1.5rem;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 480px) {
      .product-accessories-list .products-item-card-box {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 768px) {
      .product-accessories-list .products-item-card-box {
        flex: 0 0 33.3333%;
        max-width: 33.3333%; } }
    @media (min-width: 992px) {
      .product-accessories-list .products-item-card-box {
        flex: 0 0 25%;
        max-width: 25%; } }

.category-list {
  margin: 2rem 0; }

.category-item {
  margin-top: 8rem; }
  .category-item-container {
    padding-top: 2rem; }

.product-item-card {
  transition: 0.3s ease all;
  width: 100%;
  position: relative;
  display: flex; }
  .product-item-card-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #b2b2b2; }
    .product-item-card-inner:hover, .product-item-card-inner:focus {
      border: 1px solid;
      border-image-slice: 1;
      border-width: 1px;
      border-image-source: linear-gradient(135deg, #f86809, #10f4b9, #2088db); }
  .product-item-card h4 {
    text-shadow: 0 3px 5px #b1b1b1;
    color: #000;
    font-size: 16px;
    max-width: 100%;
    margin: 0 1.5rem 1.5rem 1.5rem; }
    @media (min-width: 320px) {
      .product-item-card h4 {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .product-item-card h4 {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .product-item-card h4 {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .product-item-card h4 {
        font-size: 2.4rem; } }
    @media (min-width: 992px) {
      .product-item-card h4 {
        font-size: 2.3rem; } }
  .product-item-card-img {
    position: relative;
    overflow: hidden; }
    .product-item-card-img:before {
      content: "";
      display: block;
      padding-top: 65%; }
    .product-item-card-img img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; }
  .product-item-card-content {
    display: flex;
    align-items: flex-end; }
  .product-item-card.background-picture {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    padding-top: 65%; }
    .product-item-card.background-picture .product-item-card-inner {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      border: 1px solid transparent; }
      .product-item-card.background-picture .product-item-card-inner:hover, .product-item-card.background-picture .product-item-card-inner:focus {
        border: 1px solid;
        border-image-slice: 1;
        border-width: 1px;
        border-image-source: linear-gradient(135deg, #f86809, #10f4b9, #2088db); }
    .product-item-card.background-picture h4 {
      color: #fff;
      text-shadow: 0 3px 5px rgba(22, 22, 22, 0.678); }

.IE .product-item-card-img {
  overflow: hidden; }
  .IE .product-item-card-img img {
    max-height: 15rem; }

.show-more-container {
  margin: 4rem 0 6rem 0;
  text-align: center; }

.solution-category .products-item-card-box {
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 480px) {
    .solution-category .products-item-card-box {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1400px) {
    .solution-category .products-item-card-box {
      flex: 0 0 33.3333%;
      max-width: 33.333%; } }

.middle-container {
  max-width: 1585px;
  margin: 0 auto; }

.page-helper {
  padding: 150px 0 40px 0; }
  @media (min-width: 768px) {
    .page-helper {
      padding: 200px 0 80px 0; } }
  .page-helper h2,
  .page-helper .h2 {
    font-size: 35px;
    line-height: 45px;
    color: #0078c0;
    margin: 0; }
    @media (min-width: 768px) {
      .page-helper h2,
      .page-helper .h2 {
        font-size: 50px;
        line-height: 65px; } }

h3,
.h3,
.promo-item-top {
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
  margin: 0; }

.promo-item {
  margin: 0 0 40px 0; }
  @media (min-width: 992px) {
    .promo-item {
      margin: 0 0 100px 0; } }

.promo-item-inner {
  display: flex;
  margin-top: 20px;
  gap: 30px;
  flex-direction: column; }
  @media (min-width: 992px) {
    .promo-item-inner {
      flex-direction: row; } }
  .promo-item-inner .promo-item-video {
    width: 100%; }
  .promo-item-inner .promo-item-nwl {
    width: 100%;
    max-width: 390px;
    margin: 0 auto; }
    @media (min-width: 992px) {
      .promo-item-inner .promo-item-nwl {
        min-width: 390px; } }

.promo-item-nwl {
  background-color: #0078c0;
  color: #fff;
  text-align: center; }
  .promo-item-nwl .promo-item-nwl-title {
    color: #fff; }
  .promo-item-nwl .promo-item-nwl-text {
    font-size: 23px;
    line-height: 30px;
    font-weight: 600; }
  .promo-item-nwl .promo-item-nwl-content {
    padding: 15px 10px 15px 10px; }
  .promo-item-nwl .iframe-newsletter {
    height: auto; }

.promo-item-nwl-form {
  margin-top: 15px; }
  .promo-item-nwl-form input[type="text"] {
    width: 100%;
    background: #fff;
    height: 50px;
    border-radius: 10px !important;
    border: none !important; }
  .promo-item-nwl-form label {
    display: none; }
  .promo-item-nwl-form input[type="submit"] {
    height: 50px;
    border-radius: 10px !important;
    border: none !important;
    width: 100%;
    background: #3FA503;
    color: #fff;
    font-weight: 600;
    font-size: 23px;
    line-height: 30px; }
  .promo-item-nwl-form .ErrorLabel, .promo-item-nwl-form .EditingFormErrorLabel {
    color: red;
    font-size: 18px;
    line-height: 25px; }

.promo-box .sib-form-message-panel--active {
  animation: fadeOut 0.5s forwards;
  animation-delay: 2s; }

@keyframes fadeOut {
  0% {
    display: inline-block; }
  100% {
    display: none; } }

.promo-item-video {
  aspect-ratio: 16/9;
  display: flex; }
  .promo-item-video iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9; }

.promo-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 40px; }
  @media (min-width: 992px) {
    .promo-card-container {
      margin-bottom: 100px; } }
  .promo-card-container .promo-card-item {
    width: 100%; }
    @media (min-width: 545px) {
      .promo-card-container .promo-card-item {
        width: calc(50% - 23px); } }
    @media (min-width: 768px) {
      .promo-card-container .promo-card-item {
        width: calc(33.33% - 23px); } }
    @media (min-width: 992px) {
      .promo-card-container .promo-card-item {
        width: calc(25% - 23px); } }

.promo-card-item {
  border: 1px solid #b2b2b2;
  padding: 10px; }

.promo-carr-item-name {
  color: #000;
  font-weight: 600; }

.promo-card-item-image {
  height: 200px;
  position: relative; }
  .promo-card-item-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center; }

.promo-video-item {
  position: relative;
  border: none;
  overflow: hidden; }
  .promo-video-item .promo-carr-item-name {
    display: none; }
  .promo-video-item .promo-card-item-image {
    position: static; }
  .promo-video-item img {
    object-fit: cover;
    transition: 250ms; }
  .promo-video-item:before {
    content: "\e906";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ff0000;
    font-size: 100px;
    z-index: 33; }
  .promo-video-item:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    background-color: #fff;
    z-index: 22; }
  .promo-video-item:hover img {
    transform: scale(1.05, 1.05); }

.solution-item {
  background-color: #C9C9C9;
  padding: 20px;
  display: flex;
  gap: 30px;
  font-size: 21px;
  line-height: 23px;
  flex-wrap: wrap;
  flex-direction: column; }
  @media (min-width: 992px) {
    .solution-item {
      flex-wrap: nowrap;
      gap: 30px 100px;
      flex-direction: row; } }

.solution-item-brand-title {
  font-weight: 900;
  padding-left: 80px; }
  .solution-item-brand-title .marker {
    color: #F7403A; }

.solution-item-brand-logo {
  margin: -20px 0 0 -20px; }

.solution-item-title {
  font-weight: 900;
  margin-bottom: 10px; }

.solution-item-content ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (min-width: 1200px) {
    .solution-item-content ul {
      columns: 2; } }
  .solution-item-content ul li {
    margin-bottom: 15px; }

.solution-item-content a {
  color: #F7403A;
  font-weight: 900;
  text-decoration: none; }
  .solution-item-content a:focus, .solution-item-content a:hover {
    color: #f4110a;
    text-decoration: none; }

.search-form {
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px; }
  @media (min-width: 768px) {
    .search-form {
      margin-top: 0px; } }
  .search-form-input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    padding-right: 45px;
    outline: 0;
    background: #e0e0e0;
    border: 0px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    font-size: 18px; }
    @media (min-width: 992px) {
      .search-form-input {
        height: 50px;
        padding-right: 75px;
        font-size: 22px; } }
    .search-form-input:focus {
      outline: 0;
      background: #d3d3d3; }
  .search-form-button {
    position: absolute;
    top: 20px;
    right: 10px;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    display: inline-block;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 992px) {
      .search-form-button {
        right: 20px;
        width: 25px;
        height: 25px; } }
    .search-form-button:focus, .search-form-button:hover {
      background-color: #000; }
      .search-form-button:focus:after, .search-form-button:hover:after {
        color: #fff; }
    .search-form-button:after {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e909";
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 18px;
      color: #000; }
      @media (min-width: 992px) {
        .search-form-button:after {
          font-size: 22px; } }
    .search-form-button.cancel:after {
      content: "\e919";
      font-size: 10px; }
      @media (min-width: 992px) {
        .search-form-button.cancel:after {
          font-size: 10px; } }

.search-list {
  border-top: 1px solid #000; }
  .search-list h2 {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 320px) {
      .search-list h2 {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .search-list h2 {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .search-list h2 {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .search-list h2 {
        font-size: 3rem; } }
    .search-list h2:after {
      content: "\e908";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      font-size: 24px;
      color: #000;
      font-weight: 600;
      border: 2px solid #000;
      transition: all ease-in-out 200ms;
      border-radius: 100%;
      margin-left: 11px; }
  .search-list .full-width-item {
    display: block;
    text-decoration: none !important;
    color: #000 !important;
    padding: 3rem 0;
    border-bottom: 1px solid #000; }
    @media (min-width: 545px) {
      .search-list .full-width-item {
        display: flex; } }
    .search-list .full-width-item-content {
      flex-basis: calc(100% - 30rem); }
    .search-list .full-width-item-img {
      flex-basis: 30rem;
      flex-shrink: 0; }
    .search-list .full-width-item:hover h2:after {
      color: #fff;
      background-color: #000; }

#text-list .full-width-item-img {
  display: none; }

.big-button-bw-small {
  max-width: 30rem; }

.sn-form-header {
  margin-top: 50px;
  line-height: 35px;
  padding: 10px;
  font-weight: bold;
  background-color: #0078c0;
  font-size: 30px; }
  @media (min-width: 545px) {
    .sn-form-header {
      line-height: 40px;
      padding: 10px 20px;
      font-size: 35px; } }
  @media (min-width: 992px) {
    .sn-form-header {
      line-height: 45px;
      padding: 10px 20px;
      font-size: 40px; } }

.sn-form-body {
  background-color: #e0e0e0;
  padding: 15px 7px; }
  @media (min-width: 480px) {
    .sn-form-body {
      padding: 20px; } }
  .sn-form-body-title {
    padding-bottom: 25px;
    font-size: 14px;
    font-weight: 600; }
  .sn-form-body-add-sn {
    font-size: 14px;
    font-weight: 800;
    padding: 20px 0;
    border-bottom: 2px solid #000;
    margin-bottom: 17px; }
    @media (min-width: 992px) {
      .sn-form-body-add-sn {
        padding: 40px 0 20px; } }
    .sn-form-body-add-sn a {
      color: #000;
      text-decoration: none; }
      .sn-form-body-add-sn a:hover, .sn-form-body-add-sn a:focus {
        text-decoration: underline; }
  .sn-form-body-btn {
    width: 100%; }
    .sn-form-body-btn a {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      width: 100%;
      padding: 0 15px;
      min-height: 40px;
      background-color: #0078c0;
      -webkit-transition-property: background-color, color;
      -moz-transition-property: background-color, color;
      -o-transition-property: background-color, color;
      transition-property: background-color, color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      font-size: 16px;
      font-weight: 600;
      text-decoration: none;
      color: black;
      text-align: center;
      border: 0px;
      cursor: pointer;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      border-radius: 0; }
      .sn-form-body-btn a:focus, .sn-form-body-btn a:hover {
        color: #fff !important;
        background: #000;
        text-decoration: none !important; }
      @media (min-width: 768px) {
        .sn-form-body-btn a {
          font-size: 18px; } }
  .sn-form-body-sn-info {
    color: #7a7a7a;
    position: relative;
    top: -5px; }
    .sn-form-body-sn-info:hover, .sn-form-body-sn-info:focus {
      text-decoration: underline; }
    .sn-form-body-sn-info + span {
      top: 0 !important; }
  .sn-form-body-terms {
    margin-bottom: 5px; }
  .sn-form-body label {
    font-size: 15px;
    color: #000;
    margin-bottom: 7px;
    display: inline-block;
    letter-spacing: 0;
    line-height: 22px;
    font-size: 13px;
    margin-bottom: 5px;
    line-height: 19px; }
    @media (min-width: 768px) {
      .sn-form-body label {
        font-size: 15px;
        line-height: 26px; } }
    @media (min-width: 992px) {
      .sn-form-body label {
        font-size: 18px;
        margin-bottom: 10px;
        line-height: 22px; } }
  .sn-form-body input[type="text"],
  .sn-form-body input[type="password"],
  .sn-form-body select,
  .sn-form-body textarea {
    background-color: #fff;
    width: 100%;
    padding: 8px 10px;
    height: 38px;
    color: #000;
    margin-bottom: 15px;
    border-radius: 0 !important;
    border: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 200;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border: 1px solid #e0e0e0; }
    .sn-form-body input[type="text"]:focus,
    .sn-form-body input[type="password"]:focus,
    .sn-form-body select:focus,
    .sn-form-body textarea:focus {
      border: 1px solid #c7c7c7;
      outline: 0;
      background-color: #f2f2f2; }
  .sn-form-body span[style*="visible"] {
    position: relative;
    top: -10px;
    margin-bottom: 15px;
    display: block;
    color: red; }
  .sn-form-body span[style*="hidden"] {
    display: none; }

.row-space .row:not(:last-of-type) {
  margin-bottom: 30px; }

label {
  font-size: 15px;
  color: #000;
  margin-bottom: 7px;
  display: block;
  letter-spacing: 0;
  line-height: 22px; }
  @media (min-width: 768px) {
    label {
      font-size: 18px;
      line-height: 26px; } }

.form-control,
input[type="text"],
input[type="password"],
select,
textarea {
  background-color: #e0e0e0;
  width: 100%;
  padding: 8px 10px;
  height: 38px;
  color: #000;
  margin-bottom: 15px;
  border-radius: 0 !important;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-weight: 200;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 320px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .form-control,
    input[type="text"],
    input[type="password"],
    select,
    textarea {
      font-size: 1.5rem; } }
  .form-control:focus,
  input[type="text"]:focus,
  input[type="password"]:focus,
  select:focus,
  textarea:focus {
    border: 1px solid #c7c7c7;
    outline: 0; }

select::-ms-expand {
  display: none; }

textarea.form-control {
  height: 100px;
  margin-bottom: 0px; }
  textarea.form-control:-webkit-resizer {
    display: none; }

.newsletter-row-btn button,
input[type="submit"] {
  width: 100%;
  padding: 0 15px;
  min-height: 40px;
  background-color: #0078c0;
  -webkit-transition-property: background-color, color;
  -moz-transition-property: background-color, color;
  -o-transition-property: background-color, color;
  transition-property: background-color, color;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  border: 1px solid #0078c0;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0; }
  .newsletter-row-btn button:focus, .newsletter-row-btn button:hover,
  input[type="submit"]:focus,
  input[type="submit"]:hover {
    color: #fff !important;
    background: #000;
    text-decoration: none !important; }
  @media (min-width: 768px) {
    .newsletter-row-btn button,
    input[type="submit"] {
      font-size: 18px; } }

.ErrorLabel,
.EditingFormErrorLabel {
  position: relative;
  display: block;
  width: 100%;
  margin: 20px 0px;
  padding-left: 15px;
  background-color: #0078c0; }

.required:after,
.field-required:after {
  content: "*";
  color: #ff5445; }

.uploader-main {
  display: none; }

.iframe-newsletter {
  width: 100%;
  height: 400px;
  border: none; }
  @media (min-width: 545px) {
    .iframe-newsletter {
      height: 350px; } }

.footer-newsletter .newsletter-row-input input {
  padding: 10px;
  font-size: 15px;
  color: #fff;
  outline: none; }
  .footer-newsletter .newsletter-row-input input::placeholder {
    color: #fff; }

.newsletter-row {
  padding: 2.5rem 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  flex-direction: column; }
  @media (min-width: 545px) {
    .newsletter-row {
      flex-direction: row; } }
  .newsletter-row input {
    height: 50px; }
    @media (min-width: 992px) {
      .newsletter-row input {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .newsletter-row input {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .newsletter-row input {
        height: 5rem; } }
  .newsletter-row-input {
    padding: 0 5px; }
    @media (min-width: 1200px) {
      .newsletter-row-input {
        padding: 0 0.5rem; } }
    .newsletter-row-input input {
      width: 220px;
      box-sizing: border-box; }
      @media (min-width: 1200px) {
        .newsletter-row-input input {
          width: 22rem; } }
  .newsletter-row-btn {
    max-width: 110px;
    padding: 0 5px;
    margin-top: 10px; }
    @media (min-width: 545px) {
      .newsletter-row-btn {
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .newsletter-row-btn {
        max-width: 11rem; } }
    .newsletter-row-btn .btn {
      padding: 1.5rem 20px; }
      @media (min-width: 1200px) {
        .newsletter-row-btn .btn {
          padding: 1.5rem 2rem; } }
  @media (min-width: 320px) and (max-width: 544px) {
    .newsletter-row * {
      width: 100% !important;
      max-width: 100% !important; } }

.sib-form-message-panel__text {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0.5rem; }

.sib-form-message-panel {
  margin: 0 0 1.25rem 0;
  width: 100%;
  padding: 0.4375rem;
  border: 1px solid;
  display: none; }

.sib-form-message-panel--active {
  display: inline-block; }

.entry__error {
  display: none;
  margin-top: 6px;
  margin-bottom: 6px;
  background: transparent; }

.entry__error.entry__error--primary {
  font-size: 16px;
  text-align: left;
  font-family: Helvetica, sans-serif;
  color: #661d1d;
  background-color: #ffeded;
  border-radius: 3px;
  border-color: #ff4949; }

.sib-form #error-message {
  font-size: 16px;
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #661d1d;
  background-color: #ffeded;
  border-radius: 3px;
  border-color: #ff4949;
  max-width: 540px; }

.sib-form #success-message {
  font-size: 16px;
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #085229;
  background-color: #e7faf0;
  border-radius: 3px;
  border-color: #13ce66;
  max-width: 540px; }

.sib-form .clickable__icon:not(.sib-icon_standalone) {
  margin-right: calc(0.7002rem - 1px); }

.sib-form .progress-indicator__icon {
  -webkit-animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite;
  animation: indicator-spin 1.3s cubic-bezier(0.46, 0.35, 0.39, 0.85) infinite; }

@keyframes indicator-spin {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }

.sib-hide-loader-icon {
  display: none; }

#sib-container {
  padding: 0;
  background: none; }
  #sib-container .btn {
    cursor: pointer; }

.input--hidden {
  display: none !important; }

.sib-form-block__button-disabled {
  opacity: 0.5;
  cursor: default; }

html {
  box-sizing: border-box; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1200px) {
    .pux-container {
      max-width: 97.5%; } }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }

.pux-container-narrow {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1200px) {
    .pux-container-narrow {
      padding: 0;
      max-width: 83%; } }
  .pux-container-narrow:after {
    display: block;
    content: "";
    clear: both; }

@media (min-width: 480px) and (max-width: 544px) {
  .col-xs-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 545px) and (max-width: 767px) {
  .col-sm-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) and (max-width: 1399px) {
  .col-xl-five-grid {
    -webkit-flex: 0 0 20%;
    -moz-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    -webkit-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; } }

.flex-as-end {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  -webkit-align-self: flex-end;
  align-self: flex-end; }

.flex-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.jc-space {
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.align-center {
  align-items: center; }

.align-bottom {
  align-items: flex-end; }

.full-container {
  margin-left: auto;
  margin-right: auto;
  background-color: inherit; }
  .full-container:after {
    display: block;
    content: "";
    clear: both; }

.small-container {
  max-width: 1000px; }

.header-helper {
  height: 50px;
  width: 100%; }

.AdminLayoutContainer {
  display: flex;
  width: 100%; }
  .AdminLayoutContainer .products-item-card {
    min-width: 80px; }
  .AdminLayoutContainer .main-card .products-item-card {
    padding-bottom: 60% !important; }

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  width: 100%;
  min-height: 50px;
  left: 50%;
  -ms-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }
  @media (min-width: 768px) {
    header {
      min-height: 70px; } }
  header .logo {
    padding: 11px 13px 10px 0; }
    @media (min-width: 1200px) {
      header .logo {
        flex: 0 0 17.8rem;
        max-width: 17.8rem; } }
    header .logo a {
      display: inline-block; }
    header .logo img {
      height: 29px; }
      @media (min-width: 320px) and (max-width: 340px) {
        header .logo img {
          height: 22px; } }
      @media (min-width: 768px) {
        header .logo img {
          height: 35px; } }
      @media (min-width: 1200px) {
        header .logo img {
          height: 3.5rem; } }
    header .logo span,
    header .logo h1 {
      display: none; }
  header .main-menu {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    display: none;
    max-height: -webkit-calc(100vh - 50px);
    max-height: -moz-calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    overflow: auto; }
    @media (min-width: 768px) {
      header .main-menu {
        top: 56px;
        max-height: -webkit-calc(100vh - 56px);
        max-height: -moz-calc(100vh - 56px);
        max-height: calc(100vh - 56px); } }
    @media (min-width: 1200px) {
      header .main-menu {
        position: relative;
        top: 0;
        overflow: visible;
        display: block !important;
        padding-left: 3rem; } }
    header .main-menu ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; }
      @media (min-width: 1200px) {
        header .main-menu ul {
          justify-content: flex-start;
          margin-right: 15px;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          -webkit-align-items: center;
          align-items: center; } }
      header .main-menu ul > li {
        border-bottom: 1px solid #000;
        position: relative; }
        @media (min-width: 1200px) {
          header .main-menu ul > li {
            border: none; } }
        header .main-menu ul > li > a {
          cursor: pointer;
          display: block;
          font-size: 18px;
          line-height: 1;
          position: relative;
          padding: 18px 15px;
          color: #fff;
          background-color: #999999;
          text-decoration: none;
          font-weight: 300; }
          @media (min-width: 1200px) {
            header .main-menu ul > li > a {
              background-color: transparent;
              padding: 2.2rem 15px;
              font-size: 18px; } }
          @media (min-width: 1400px) {
            header .main-menu ul > li > a {
              padding: 2.2rem 2.5rem;
              font-size: 2.1rem; } }
          @media (min-width: 1500px) {
            header .main-menu ul > li > a {
              padding: 2.2rem 3.4rem; } }
          header .main-menu ul > li > a > span {
            display: inline-block;
            position: relative; }
            header .main-menu ul > li > a > span:after {
              display: block;
              content: "";
              height: 2px;
              left: 0;
              top: 100%;
              margin-top: 4px;
              width: 100%;
              background: #fff;
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              filter: alpha(opacity=0);
              visibility: hidden;
              -webkit-transition-property: opacity, visibility;
              -moz-transition-property: opacity, visibility;
              -o-transition-property: opacity, visibility;
              transition-property: opacity, visibility;
              -webkit-transition-duration: 250ms;
              -moz-transition-duration: 250ms;
              -o-transition-duration: 250ms;
              transition-duration: 250ms;
              -webkit-transition-timing-function: ease;
              -moz-transition-timing-function: ease;
              -o-transition-timing-function: ease;
              transition-timing-function: ease;
              position: absolute; }
              @media (min-width: 1200px) {
                header .main-menu ul > li > a > span:after {
                  height: 0.2rem;
                  margin-top: 0.4rem; } }
          @media (min-width: 1200px) {
            header .main-menu ul > li > a:focus span:after, header .main-menu ul > li > a:hover span:after {
              opacity: 1;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              filter: alpha(opacity=100);
              visibility: visible; } }
        header .main-menu ul > li.Highlighted > a span:after {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        header .main-menu ul > li:hover .submenu {
          opacity: 1;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          filter: alpha(opacity=100);
          visibility: visible; }
        header .main-menu ul > li .menu-mobile {
          position: absolute;
          right: 0;
          top: 0;
          width: 65px;
          height: 53px;
          text-align: center;
          background: none; }
          header .main-menu ul > li .menu-mobile:before {
            content: "";
            width: 26px;
            height: 2px;
            background: #000;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -1px;
            margin-left: -13px; }
          header .main-menu ul > li .menu-mobile:after {
            content: "";
            width: 2px;
            height: 26px;
            background: #000;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -13px;
            margin-left: -1px;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .main-menu ul > li .menu-mobile.open:after {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
          @media (min-width: 1200px) {
            header .main-menu ul > li .menu-mobile {
              display: none; } }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 1200px) {
      header .header {
        justify-content: flex-start; } }
    header .header-search {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      position: absolute;
      width: 100%;
      padding: 0 15px;
      left: 0;
      top: 45px;
      display: none;
      text-transform: uppercase;
      -moz-transition: all ease-in-out 300ms;
      -o-transition: all ease-in-out 300ms;
      -webkit-transition: all ease-in-out 300ms;
      transition: all ease-in-out 300ms; }
      @media (min-width: 480px) {
        header .header-search {
          top: 55px; } }
      @media (min-width: 1200px) {
        header .header-search {
          position: static;
          max-width: 21rem;
          overflow: visible;
          display: block;
          padding: 0; } }
      header .header-search.open {
        display: block; }
      header .header-search .searchBox {
        position: relative; }
      header .header-search label {
        display: none; }
      header .header-search input[type="text"] {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        border-radius: 0px;
        width: 100%;
        height: 30px;
        padding: 8px 9px;
        border: none;
        outline: 0;
        font-size: 16px;
        color: #fff !important;
        border: 1px solid #fff !important;
        background-color: #0086ce !important;
        height: 50px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-bottom: 0; }
        @media (min-width: 1200px) {
          header .header-search input[type="text"] {
            background-color: transparent !important;
            height: 2.6rem;
            font-size: 1.2rem;
            padding: 0.7rem 0.6rem;
            width: 21rem;
            height: 26px;
            text-transform: uppercase; } }
        header .header-search input[type="text"]::-webkit-input-placeholder {
          color: #fff; }
        header .header-search input[type="text"]:-ms-input-placeholder {
          color: #fff; }
        header .header-search input[type="text"]::placeholder {
          color: #fff; }
        header .header-search input[type="text"]:focus {
          border: none;
          outline: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none; }
      header .header-search .btn-search {
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 0;
        width: 30px;
        text-align: center;
        transform: translateY(-50%); }
        header .header-search .btn-search:before {
          content: "\e909";
          font-size: 26px;
          line-height: 32px; }
        @media (min-width: 1200px) {
          header .header-search .btn-search {
            right: 4px; } }
        header .header-search .btn-search-more {
          padding: 0.8rem 1rem;
          line-height: 1.3; }
      header .header-search-inner {
        position: relative;
        padding: 10px 0 15px 0; }
        @media (min-width: 1200px) {
          header .header-search-inner {
            padding: 0; } }
    header .header-login {
      position: absolute;
      background: #999999;
      bottom: 2px;
      right: 0px;
      width: 100%;
      display: none;
      color: #fff;
      transform: translateY(100%); }
      @media (min-width: 768px) and (max-width: 1199px) {
        header .header-login {
          bottom: 12px; } }
      header .header-login-inner {
        position: relative;
        padding: 10px; }
        header .header-login-inner input[type="password"],
        header .header-login-inner input[type="text"] {
          width: 100%;
          height: 40px;
          border: none;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          border-radius: 0;
          color: #999;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-login-inner input[type="password"]:focus,
          header .header-login-inner input[type="text"]:focus {
            color: #000;
            background: #f2f2f2;
            border-color: #c7c7c7; }
      @media (min-width: 1200px) {
        header .header-login {
          right: 100px;
          width: 360px; } }
      header .header-login .logon-remember-me-checkbox {
        display: none; }
      header .header-login .logon-password-retrieval-link,
      header .header-login .no-href {
        font-size: 18px;
        color: #000 !important;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        text-decoration: none !important; }
        header .header-login .logon-password-retrieval-link:focus, header .header-login .logon-password-retrieval-link:hover,
        header .header-login .no-href:focus,
        header .header-login .no-href:hover {
          color: #0078c0 !important; }
      header .header-login .login-btn {
        position: absolute;
        right: 10px; }
      header .header-login .logon-panel-password-retrieval {
        padding: 10px 0 0 0; }
    header .header-nav {
      -webkit-flex: 0 0 auto;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      justify-content: space-between;
      width: 150px;
      height: 32px;
      text-align: center;
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      line-height: 1;
      margin: 0 0 0 10px; }
      @media (min-width: 545px) {
        header .header-nav {
          width: 200px; } }
      @media (min-width: 1200px) {
        header .header-nav {
          width: auto;
          height: auto;
          display: block;
          line-height: inherit; } }
      header .header-nav-search, header .header-nav-language, header .header-nav-login, header .header-nav-logout {
        position: relative;
        margin: 0 5px;
        color: #fff;
        text-decoration: none; }
        @media (min-width: 320px) and (max-width: 1199px) {
          header .header-nav-search, header .header-nav-language, header .header-nav-login, header .header-nav-logout {
            font-size: 0; } }
        @media (min-width: 992px) {
          header .header-nav-search, header .header-nav-language, header .header-nav-login, header .header-nav-logout {
            padding: 0px 5px;
            margin: 0 10px; } }
        header .header-nav-search:before, header .header-nav-language:before, header .header-nav-login:before, header .header-nav-logout:before {
          font-size: 20px; }
          @media (min-width: 1200px) {
            header .header-nav-search:before, header .header-nav-language:before, header .header-nav-login:before, header .header-nav-logout:before {
              display: none; } }
        header .header-nav-search:after, header .header-nav-language:after, header .header-nav-login:after, header .header-nav-logout:after {
          height: 2px;
          left: 0;
          top: 100%;
          margin-top: 4px;
          width: 100%;
          background: #fff;
          opacity: 0;
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
          filter: alpha(opacity=0);
          visibility: hidden;
          -webkit-transition-property: opacity, visibility;
          -moz-transition-property: opacity, visibility;
          -o-transition-property: opacity, visibility;
          transition-property: opacity, visibility;
          -webkit-transition-duration: 250ms;
          -moz-transition-duration: 250ms;
          -o-transition-duration: 250ms;
          transition-duration: 250ms;
          -webkit-transition-timing-function: ease;
          -moz-transition-timing-function: ease;
          -o-transition-timing-function: ease;
          transition-timing-function: ease;
          position: absolute; }
          @media (min-width: 1200px) {
            header .header-nav-search:after, header .header-nav-language:after, header .header-nav-login:after, header .header-nav-logout:after {
              display: block;
              content: "";
              height: 0.2rem;
              margin-top: 0.4rem; } }
        @media (min-width: 1200px) {
          header .header-nav-search:focus:after, header .header-nav-search:hover:after, header .header-nav-language:focus:after, header .header-nav-language:hover:after, header .header-nav-login:focus:after, header .header-nav-login:hover:after, header .header-nav-logout:focus:after, header .header-nav-logout:hover:after {
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible; } }
      header .header-nav-search {
        font-size: 0; }
        @media (min-width: 992px) {
          header .header-nav-search {
            display: none; } }
        header .header-nav-search:before {
          content: "\e909"; }
      header .header-nav-language {
        display: inline-block;
        cursor: pointer; }
        header .header-nav-language:before {
          content: "\e905"; }
          @media (min-width: 1200px) {
            header .header-nav-language:before {
              margin: 0 0 0 5px;
              vertical-align: -2px;
              line-height: normal; } }
        header .header-nav-language .header-language-inner {
          position: absolute;
          display: none;
          min-width: 40px;
          background: #999999;
          right: 0px; }
          @media (min-width: 992px) {
            header .header-nav-language .header-language-inner {
              top: 45px; } }
          header .header-nav-language .header-language-inner ul, header .header-nav-language .header-language-inner li {
            padding: 0;
            margin: 0;
            list-style: none;
            text-align: center; }
          header .header-nav-language .header-language-inner a {
            display: inline-block;
            color: #fff;
            width: auto;
            height: auto;
            margin: 10px;
            text-decoration: none;
            position: relative;
            padding-left: 10px;
            padding-right: 10px; }
            header .header-nav-language .header-language-inner a:after {
              height: 2px;
              left: 0;
              top: 100%;
              margin-top: 4px;
              width: 100%;
              background: #fff;
              opacity: 0;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              filter: alpha(opacity=0);
              visibility: hidden;
              -webkit-transition-property: opacity, visibility;
              -moz-transition-property: opacity, visibility;
              -o-transition-property: opacity, visibility;
              transition-property: opacity, visibility;
              -webkit-transition-duration: 250ms;
              -moz-transition-duration: 250ms;
              -o-transition-duration: 250ms;
              transition-duration: 250ms;
              -webkit-transition-timing-function: ease;
              -moz-transition-timing-function: ease;
              -o-transition-timing-function: ease;
              transition-timing-function: ease;
              position: absolute; }
              @media (min-width: 1200px) {
                header .header-nav-language .header-language-inner a:after {
                  display: block;
                  content: "";
                  height: 0.2rem;
                  margin-top: 0.4rem; } }
            @media (min-width: 1200px) {
              header .header-nav-language .header-language-inner a:focus:after, header .header-nav-language .header-language-inner a:hover:after {
                opacity: 1;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                filter: alpha(opacity=100);
                visibility: visible; } }
      header .header-nav-logout:before, header .header-nav-login:before {
        content: "\e90f"; }
        @media (min-width: 1200px) {
          header .header-nav-logout:before, header .header-nav-login:before {
            display: none; } }
      header .header-nav-menu {
        position: relative;
        width: 32px;
        height: 32px; }
        header .header-nav-menu span {
          width: 26px;
          position: absolute;
          top: 50%;
          margin-top: -1px;
          background: #fff;
          height: 2px;
          left: 50%;
          margin-left: -13px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before, header .header-nav-menu span:after {
            content: "";
            width: 26px;
            height: 2px;
            position: absolute;
            display: block;
            background: #fff;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-nav-menu span:before {
            top: -8px; }
          header .header-nav-menu span:after {
            bottom: -8px; }
        @media (min-width: 1200px) {
          header .header-nav-menu {
            display: none !important; } }
        header .header-nav-menu.open span {
          background: transparent; }
          header .header-nav-menu.open span:before {
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            top: 0; }
          header .header-nav-menu.open span:after {
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 0; }
  header .submenu {
    font-size: 0;
    white-space: nowrap;
    background: rgba(153, 153, 153, 0.8);
    z-index: 5;
    display: none;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    @media (min-width: 1200px) {
      header .submenu {
        position: absolute;
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 0.5rem 0.7rem 2rem;
        left: 0;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
        visibility: hidden;
        -webkit-transition-property: opacity, visibility;
        -moz-transition-property: opacity, visibility;
        -o-transition-property: opacity, visibility;
        transition-property: opacity, visibility;
        -webkit-transition-duration: 250ms;
        -moz-transition-duration: 250ms;
        -o-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-timing-function: ease;
        -moz-transition-timing-function: ease;
        -o-transition-timing-function: ease;
        transition-timing-function: ease;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex; } }
    header .submenu ul {
      display: block;
      width: 100%;
      min-width: 187px;
      white-space: normal;
      font-size: 14px;
      border-top: 1px solid #000; }
      header .submenu ul li {
        display: block; }
        header .submenu ul li a {
          display: block;
          padding: 12px 25px;
          position: relative;
          font-weight: 400;
          font-size: 16px; }
          header .submenu ul li a:hover {
            color: #0078c0; }
          @media (min-width: 1200px) {
            header .submenu ul li a {
              padding: 9px 0;
              border-bottom: 1px solid #000; } }
        header .submenu ul li span {
          display: block;
          font-weight: 600;
          padding: 9px 15px;
          background: #000;
          color: #0078c0;
          border-bottom: 1px solid #000; }
          @media (min-width: 1200px) {
            header .submenu ul li span {
              background: #999999;
              color: #000;
              padding: 9px 0; } }
        header .submenu ul li:last-child {
          border: none; }
        header .submenu ul li ul {
          margin: 0;
          padding: 0;
          width: auto;
          min-width: 10px; }
      @media (min-width: 1200px) {
        header .submenu ul {
          width: 187px;
          padding: 0 15px;
          border: none; } }
  header:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 200ms linear;
    background: #0078c0; }
  header:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    height: 200%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    background: linear-gradient(to bottom, #0078c0, transparent); }

.EditMode header,
.DesginMode header {
  position: static;
  transform: translate(0, 0); }

.quick-search {
  position: absolute;
  background: #e0e0e0;
  bottom: 0;
  left: 0;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  transform: translateY(100%); }
  @media (min-width: 1200px) {
    .quick-search {
      top: 5.4rem;
      width: 50rem;
      right: 0px;
      bottom: auto;
      left: auto;
      transform: none; } }
  .quick-search-inner {
    padding: 10px;
    min-height: 293px;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .quick-search .header-search-item {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    color: #000;
    text-decoration: none !important;
    border-bottom: 1px solid #fff; }
    .quick-search .header-search-item-image {
      position: relative;
      width: 90px;
      min-width: 90px;
      height: 90px;
      background: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .quick-search .header-search-item-name {
      font-weight: bold;
      padding: 0 15px;
      width: 100%;
      font-size: 18px;
      word-break: break-all; }
    .quick-search .header-search-item-arrow {
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: transparent;
      color: #000;
      margin-right: 10px;
      border: 1px solid #000;
      text-align: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .quick-search .header-search-item-arrow:before {
        content: "\e908";
        line-height: 28px;
        font-size: 24px;
        margin-left: -2px; }
    .quick-search .header-search-item:focus, .quick-search .header-search-item:hover {
      background: #0078c0; }
      .quick-search .header-search-item:focus .header-search-item-arrow, .quick-search .header-search-item:hover .header-search-item-arrow {
        background: #000;
        color: #fff; }
  .quick-search.open {
    visibility: visible;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.quick-search-btn {
  padding: 10px 10px 20px 10px;
  text-align: center; }

.loader {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 33;
  left: 50%;
  margin-left: -60px; }

.loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.loader:after {
  background: #e0e0e0;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.quick-search.opacity .loader {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible; }

.quick-search.opacity .quick-search-inner {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0); }

.load-more-products {
  width: 100%; }
  .load-more-products .inner-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

footer {
  margin: auto;
  color: #000;
  padding: 30px 0 10px; }
  @media (min-width: 545px) {
    footer {
      padding: 30px 0 20px; } }
  @media (min-width: 768px) {
    footer {
      padding: 5rem 0 6.5rem; } }
  footer .copyright {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px -10px 0 -10px;
    font-size: 15px; }
    @media (min-width: 1200px) {
      footer .copyright {
        font-size: 1.5rem;
        margin: 2rem -10px 0 -10px; } }
    footer .copyright-item {
      padding: 0 10px 20px 10px; }
  footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    footer ul a:not(.ico-social) {
      color: #000;
      text-decoration: none; }
      footer ul a:not(.ico-social):focus, footer ul a:not(.ico-social):hover {
        color: #000;
        text-decoration: underline; }
  footer .footer-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    footer .footer-menu li {
      font-size: 15px;
      margin: 10px 40px 10px 0; }
      @media (min-width: 1200px) {
        footer .footer-menu li {
          font-size: 1.5rem; } }
    footer .footer-menu a {
      font-weight: 700;
      text-transform: uppercase; }
  footer .footer-submenu {
    padding: 15px 0 0 0; }
    footer .footer-submenu li {
      margin: 0 30px 15px 0; }
      footer .footer-submenu li:last-child {
        margin: 0 0 15px 0; }

body[product-page] header:after {
  display: none; }

img {
  max-width: 100%; }

.WPTTableCell.WPTTableCellContent header {
  display: static;
  transform: unset;
  background-color: #0078c0; }

.IE main {
  max-width: 100vw;
  overflow: hidden; }

.theme-blue {
  background-color: #0086ce;
  min-height: 80vh; }

.form-container {
  padding: 15px 20px 30px;
  background: #0078c0;
  max-width: 500px;
  color: #fff; }
  @media (min-width: 1200px) {
    .form-container {
      max-width: 50rem;
      padding: 1.5rem 2rem 3rem; } }
  .form-container.newsletter-box {
    padding: 30px 20px 35px;
    margin: auto;
    text-align: center;
    font-size: 15px;
    line-height: 1.6; }
    @media (min-width: 1200px) {
      .form-container.newsletter-box {
        padding: 3rem 2rem 3.5rem; } }

.nwl-title span {
  font-weight: 700;
  font-size: 17px;
  padding-bottom: 3rem;
  display: inline-block;
  color: #000; }
  @media (min-width: 768px) {
    .nwl-title span {
      line-height: 1.2;
      font-size: 2.2rem; } }

.footer-newsletter {
  background-color: #0078c0; }

.newsletter-row {
  padding: 2.5rem 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  flex-direction: column; }
  @media (min-width: 545px) {
    .newsletter-row {
      flex-direction: row; } }
  .newsletter-row .EditingFormErrorLabel,
  .newsletter-row .ErrorLabel {
    color: #fff; }
  .newsletter-row .EditingFormErrorLabel {
    font-size: 14px;
    line-height: 1.2;
    text-align: left; }
  .newsletter-row input {
    height: 50px; }
    @media (min-width: 992px) {
      .newsletter-row input {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .newsletter-row input {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .newsletter-row input {
        height: 5rem; } }
  .newsletter-row-input {
    padding: 5px 0 !important;
    width: auto !important; }
    @media (min-width: 545px) {
      .newsletter-row-input input {
        width: 36rem !important; } }
  .newsletter-row-btn {
    padding: 5px 0 !important;
    max-width: 110px;
    margin-top: 10px; }
    @media (min-width: 545px) {
      .newsletter-row-btn {
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .newsletter-row-btn {
        max-width: 11rem; } }
    .newsletter-row-btn .btn {
      padding: 1.5rem 20px;
      background-color: #fff;
      border: 2px solid #fff !important;
      color: #000 !important; }
      @media (min-width: 1200px) {
        .newsletter-row-btn .btn {
          padding: 1.5rem 2rem; } }
      .newsletter-row-btn .btn:hover, .newsletter-row-btn .btn:focus {
        background-color: transparent; }
  @media (min-width: 320px) and (max-width: 544px) {
    .newsletter-row * {
      width: 100% !important;
      max-width: 100% !important; } }

.newsletter-text {
  padding: 0 4rem; }

.big-iframe {
  width: 100%;
  height: 100vh;
  border: none; }

.biger-iframe {
  width: 100%;
  height: 120vh;
  border: none; }

.social {
  font-size: 0; }
  .social ul {
    display: flex; }
    .social ul li a {
      margin-left: 5px; }
  .social .ico-social {
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    margin: 5px;
    height: 27px;
    width: 27px;
    text-decoration: none !important;
    position: relative; }
    .social .ico-social:before {
      color: #000;
      -webkit-transition-property: color;
      -moz-transition-property: color;
      -o-transition-property: color;
      transition-property: color;
      -webkit-transition-duration: 250ms;
      -moz-transition-duration: 250ms;
      -o-transition-duration: 250ms;
      transition-duration: 250ms;
      -webkit-transition-timing-function: ease;
      -moz-transition-timing-function: ease;
      -o-transition-timing-function: ease;
      transition-timing-function: ease;
      font-size: 30px;
      position: absolute;
      top: 0;
      left: 0; }
    .social .ico-social:focus:before, .social .ico-social:hover:before {
      color: #0078c0; }
    .social .ico-social:first-child {
      margin: 5px 5px 5px 0; }
      @media (min-width: 768px) {
        .social .ico-social:first-child {
          margin: 5px; } }
    .social .ico-social.s-google:before {
      content: ""; }
    .social .ico-social.s-tumblr:before {
      content: ""; }
    .social .ico-social.s-facebook:before {
      content: ""; }
    .social .ico-social.s-mail:before {
      content: ""; }
    .social .ico-social.s-twitter:before {
      content: ""; }
    .social .ico-social.s-linkedin:before {
      content: ""; }
    .social .ico-social.s-vine:before {
      content: ""; }
    .social .ico-social.s-youtube:before {
      content: ""; }
    .social .ico-social.s-instagram:before {
      content: ""; }

.dark-box {
  padding: 0 8px 15px 8px;
  width: 100%; }
  @media (min-width: 768px) {
    .dark-box {
      flex: 0 1 50%;
      max-width: 50%; } }

.nwl-box .ErrorLabel {
  color: #000;
  display: block;
  transform: translateY(10px);
  font-size: 14px; }
  @media (min-width: 992px) {
    .nwl-box .ErrorLabel {
      font-size: 18px; } }

.newsletter-row {
  padding: 0 0 25px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  flex-direction: column; }
  @media (min-width: 545px) {
    .newsletter-row {
      flex-direction: row; } }
  .newsletter-row .ErrorLabel {
    color: #000; }
  .newsletter-row .EditingFormErrorLabel {
    margin-top: 5px;
    padding: 5px 7px; }
  .newsletter-row-input {
    padding: 5px 5px;
    width: 230px; }
    .newsletter-row-input input {
      width: 220px;
      background-color: #000;
      border: 2px solid #fff;
      margin: 0;
      height: 54px;
      color: #fff; }
      .newsletter-row-input input:focus {
        background-color: transparent;
        color: #fff;
        border: 2px solid #fff; }
  .newsletter-row-btn {
    width: 120px;
    padding: 5px 5px; }
    .newsletter-row-btn .btn {
      padding: 15px 10px;
      height: 54px;
      color: #fff;
      font-size: 14px;
      border: 2px solid #0078c0; }
  @media (min-width: 320px) and (max-width: 544px) {
    .newsletter-row * {
      width: 100% !important;
      max-width: 100% !important; } }

@media (min-width: 1400px) {
  .slim-title {
    padding-left: 15%;
    padding-right: 15%; } }

.category-bottom-text,
.category-top-text {
  max-width: 990px; }

.category-item-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem; }

.box-header {
  position: relative;
  min-height: 87px;
  margin: 0 auto;
  margin-bottom: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  color: #fff; }
  @media (min-width: 480px) {
    .box-header {
      min-height: 200px; } }
  @media (min-width: 545px) {
    .box-header {
      min-height: 250px; } }
  @media (min-width: 768px) {
    .box-header {
      min-height: 300px;
      margin-bottom: 40px; } }
  @media (min-width: 1200px) {
    .box-header {
      min-height: 352px;
      margin-bottom: 70px; } }
  .box-header:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0; }
  .box-header .box-header-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 70px 0 10px; }
    @media (min-width: 480px) {
      .box-header .box-header-content {
        padding: 80px 0 20px; } }
    @media (min-width: 768px) {
      .box-header .box-header-content {
        padding: 90px 0 20px; } }
    @media (min-width: 1200px) {
      .box-header .box-header-content {
        padding: 100px 0 20px; } }
    .box-header .box-header-content h1 {
      display: none; }
    .box-header .box-header-content .svg-title {
      margin-top: 30px; }
      @media (min-width: 768px) {
        .box-header .box-header-content .svg-title {
          margin-top: 45px; } }
      @media (min-width: 1200px) {
        .box-header .box-header-content .svg-title {
          margin-top: 55px; } }

.svg-title {
  width: 100%;
  filter: contrast(1.8);
  overflow: visible !important; }
  .svg-title text {
    font-size: 50px;
    stroke-width: 2px;
    text-shadow: 0 12px 11px rgba(3, 4, 3, 0.5);
    font-weight: 600;
    fill: transparent; }
    @media (min-width: 768px) {
      .svg-title text {
        font-size: 60px; } }
    @media (min-width: 1200px) {
      .svg-title text {
        font-size: 73px; } }
    .svg-title text:not(.generated-text) {
      visibility: hidden; }

.full-height-image .pux-container {
  height: 100%; }

@media (min-width: 1200px) {
  .full-height-image .box-header-content {
    min-height: 100vh;
    height: 100vh; } }

.full-height-image .svg-title-container {
  height: 100%;
  display: flex;
  align-items: center; }

.full-height-image .svg-title {
  margin-top: -115px !important; }

.breadcrumbs {
  z-index: 9997;
  color: inherit;
  line-height: 18px;
  padding-right: 80px;
  position: relative;
  margin-bottom: 2.5rem; }
  .breadcrumbs a,
  .breadcrumbs span {
    margin-top: -5px;
    color: #fff !important; }
  .breadcrumbs .CMSBreadCrumbsLink,
  .breadcrumbs .CMSBreadCrumbsCurrentItem {
    color: inherit;
    font-size: 16px; }
    @media (min-width: 768px) {
      .breadcrumbs .CMSBreadCrumbsLink,
      .breadcrumbs .CMSBreadCrumbsCurrentItem {
        font-size: 19px; } }
  .breadcrumbs .breadcrumb-separator {
    font-size: 16px;
    display: inline-block;
    margin: 0 5px; }
    @media (min-width: 768px) {
      .breadcrumbs .breadcrumb-separator {
        font-size: 19px; } }
  .breadcrumbs a {
    color: #fff;
    text-decoration: none; }
    .breadcrumbs a:hover, .breadcrumbs a:focus {
      text-decoration: underline; }

.colored-circle {
  overflow: hidden;
  position: relative;
  background-image: url("/Prolyte/media/system/img/circle.svg"); }
  .colored-circle:before {
    content: "";
    padding-top: 100%;
    display: block; }
  .colored-circle-bg {
    display: block;
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1; }
  .colored-circle-inner {
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1; }
    @media (min-width: 1200px) {
      .colored-circle-inner {
        font-size: 19px; } }
    .colored-circle-inner h1,
    .colored-circle-inner h2,
    .colored-circle-inner h3,
    .colored-circle-inner h4,
    .colored-circle-inner big {
      font-family: 24px;
      padding: 0;
      margin: 0;
      line-height: inherit; }
      @media (min-width: 1200px) {
        .colored-circle-inner h1,
        .colored-circle-inner h2,
        .colored-circle-inner h3,
        .colored-circle-inner h4,
        .colored-circle-inner big {
          font-size: 31px; } }

.arrow-link-in a,
.devider-title a,
.arrow-link {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: lowercase;
  text-decoration-color: none;
  text-decoration: none;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1200px) {
    .arrow-link-in a,
    .devider-title a,
    .arrow-link {
      font-size: 20px; } }
  .arrow-link-in a:after,
  .devider-title a:after,
  .arrow-link:after {
    content: "\e908";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    border: 2px solid #000;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-radius: 100%;
    margin-left: 11px; }
    @media (min-width: 768px) {
      .arrow-link-in a:after,
      .devider-title a:after,
      .arrow-link:after {
        font-size: 26px; } }
    @media (min-width: 992px) {
      .arrow-link-in a:after,
      .devider-title a:after,
      .arrow-link:after {
        font-size: 30px; } }
  .arrow-link-in a:hover, .arrow-link-in a:focus,
  .devider-title a:hover,
  .devider-title a:focus,
  .arrow-link:hover,
  .arrow-link:focus {
    text-decoration: none; }
    .arrow-link-in a:hover:after, .arrow-link-in a:focus:after,
    .devider-title a:hover:after,
    .devider-title a:focus:after,
    .arrow-link:hover:after,
    .arrow-link:focus:after {
      background-color: #000;
      color: #fff; }

.arrow-link-back,
.content-button-level-up,
.arrow-link-back-in a {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  text-transform: lowercase;
  text-decoration-color: none;
  text-decoration: none;
  color: #000;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1200px) {
    .arrow-link-back,
    .content-button-level-up,
    .arrow-link-back-in a {
      font-size: 20px; } }
  .arrow-link-back:before,
  .content-button-level-up:before,
  .arrow-link-back-in a:before {
    content: "\e90d";
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    font-size: 24px;
    color: #000;
    font-weight: 600;
    border: 2px solid #000;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    border-radius: 100%;
    margin-right: 11px; }
    @media (min-width: 768px) {
      .arrow-link-back:before,
      .content-button-level-up:before,
      .arrow-link-back-in a:before {
        font-size: 26px; } }
    @media (min-width: 992px) {
      .arrow-link-back:before,
      .content-button-level-up:before,
      .arrow-link-back-in a:before {
        font-size: 30px; } }
  .arrow-link-back:hover, .arrow-link-back:focus,
  .content-button-level-up:hover,
  .content-button-level-up:focus,
  .arrow-link-back-in a:hover,
  .arrow-link-back-in a:focus {
    text-decoration: none;
    color: #000; }
    .arrow-link-back:hover:before, .arrow-link-back:focus:before,
    .content-button-level-up:hover:before,
    .content-button-level-up:focus:before,
    .arrow-link-back-in a:hover:before,
    .arrow-link-back-in a:focus:before {
      background-color: #000;
      color: #fff; }

.content-button-level-up {
  margin-bottom: 15px; }

.devider-title h2,
.devider-title h3,
.colored-title h2,
.colored-title h3 {
  font-size: 28px;
  color: #0078c0;
  background-size: 100% 32px;
  background-position: 0 28px;
  line-height: 32px;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #fe761c; }
  @media (min-width: 768px) {
    .devider-title h2,
    .devider-title h3,
    .colored-title h2,
    .colored-title h3 {
      font-size: 32px;
      background-size: 100% 36px;
      background-position: 0 32px;
      line-height: 36px; } }
  @media (min-width: 1200px) {
    .devider-title h2,
    .devider-title h3,
    .colored-title h2,
    .colored-title h3 {
      font-size: 42px;
      background-size: 100% 46px;
      background-position: 0 42px;
      line-height: 46px; } }

.colored-title-small h2,
.colored-title-small h3 {
  font-size: 22px;
  color: #0078c0;
  line-height: 25px;
  display: inline-block;
  text-decoration: underline;
  text-decoration-color: #fe761c; }
  @media (min-width: 768px) {
    .colored-title-small h2,
    .colored-title-small h3 {
      font-size: 24px;
      line-height: 28px; } }
  @media (min-width: 1200px) {
    .colored-title-small h2,
    .colored-title-small h3 {
      font-size: 28px;
      line-height: 32px; } }

.content-mobile-menu {
  display: none;
  margin: 2rem 0 4rem 0;
  font-size: 16px; }
  .content-mobile-menu:hover {
    color: #000 !important;
    background: #fff; }
  .content-mobile-menu:after {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e903";
    margin-left: 5px;
    margin-top: -3px;
    font-size: 35px;
    -webkit-transition-property: transform;
    -moz-transition-property: transform;
    -o-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
  .content-mobile-menu.active:after {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  @media (min-width: 320px) and (max-width: 767px) {
    .content-mobile-menu {
      display: block; } }

.content-menu,
.products-menu {
  width: 100.25%;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 4rem 0;
  color: #000; }
  @media (min-width: 768px) {
    .content-menu,
    .products-menu {
      display: block !important; } }
  @media (min-width: 768px) {
    .content-menu,
    .products-menu {
      margin-top: 0px; } }
  .content-menu .content-menu-item > a,
  .content-menu .products-menu-item > a,
  .products-menu .content-menu-item > a,
  .products-menu .products-menu-item > a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 17px;
    color: inherit;
    font-weight: 600;
    padding: 5px 5px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    @media (min-width: 768px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        font-size: 16px;
        font-weight: 800;
        padding: 5px 10px; } }
    @media (min-width: 1200px) {
      .content-menu .content-menu-item > a,
      .content-menu .products-menu-item > a,
      .products-menu .content-menu-item > a,
      .products-menu .products-menu-item > a {
        font-size: 18px;
        line-height: 20px; } }
    .content-menu .content-menu-item > a:hover, .content-menu .content-menu-item > a:focus,
    .content-menu .products-menu-item > a:hover,
    .content-menu .products-menu-item > a:focus,
    .products-menu .content-menu-item > a:hover,
    .products-menu .content-menu-item > a:focus,
    .products-menu .products-menu-item > a:hover,
    .products-menu .products-menu-item > a:focus {
      background-color: #0078c0;
      color: #000; }
  .content-menu .content-menu-item.active > a,
  .content-menu .products-menu-item.active > a,
  .products-menu .content-menu-item.active > a,
  .products-menu .products-menu-item.active > a {
    background-color: #0078c0;
    color: #000; }
  .content-menu .content-menu-item.active .content-submenu,
  .content-menu .content-menu-item.active .products-submenu,
  .content-menu .products-menu-item.active .content-submenu,
  .content-menu .products-menu-item.active .products-submenu,
  .products-menu .content-menu-item.active .content-submenu,
  .products-menu .content-menu-item.active .products-submenu,
  .products-menu .products-menu-item.active .content-submenu,
  .products-menu .products-menu-item.active .products-submenu {
    display: block; }
  .content-menu .content-menu-item .content-submenu,
  .content-menu .content-menu-item .products-submenu,
  .content-menu .products-menu-item .content-submenu,
  .content-menu .products-menu-item .products-submenu,
  .products-menu .content-menu-item .content-submenu,
  .products-menu .content-menu-item .products-submenu,
  .products-menu .products-menu-item .content-submenu,
  .products-menu .products-menu-item .products-submenu {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #e0e0e0;
    padding: 0 10px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
    .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
    .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
    .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
    .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
    .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
    .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
    .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
    .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
    .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
    .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
    .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
    .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
    .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
    .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
    .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
      position: relative;
      display: block;
      padding: 7px 0 7px 10px;
      color: #000;
      font-size: 16px;
      line-height: 17px;
      text-decoration: none; }
      @media (min-width: 768px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          padding: 5px 0 5px 10px;
          font-size: 13px; } }
      @media (min-width: 992px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          font-size: 16px; } }
      @media (min-width: 1200px) {
        .content-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .content-menu .products-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .content-menu-item .products-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .content-submenu .products-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .content-submenu-item > a,
        .products-menu .products-menu-item .products-submenu .products-submenu-item > a {
          font-size: 18px;
          line-height: 20px; } }
      .content-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
      .content-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
      .content-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
      .content-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
      .content-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
      .content-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
      .content-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
      .content-menu .products-menu-item .products-submenu .products-submenu-item > a:before,
      .products-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
      .products-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
      .products-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
      .products-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
      .products-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
      .products-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
      .products-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a:before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        background: #000;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 11px; }
        @media (min-width: 1200px) {
          .content-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
          .content-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
          .content-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
          .content-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
          .content-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
          .content-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
          .content-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
          .content-menu .products-menu-item .products-submenu .products-submenu-item > a:before,
          .products-menu .content-menu-item .content-submenu .content-submenu-item > a:before,
          .products-menu .content-menu-item .content-submenu .products-submenu-item > a:before,
          .products-menu .content-menu-item .products-submenu .content-submenu-item > a:before,
          .products-menu .content-menu-item .products-submenu .products-submenu-item > a:before,
          .products-menu .products-menu-item .content-submenu .content-submenu-item > a:before,
          .products-menu .products-menu-item .content-submenu .products-submenu-item > a:before,
          .products-menu .products-menu-item .products-submenu .content-submenu-item > a:before,
          .products-menu .products-menu-item .products-submenu .products-submenu-item > a:before {
            top: 14px; } }
      .content-menu .content-menu-item .content-submenu .content-submenu-item > a:hover, .content-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
      .content-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
      .content-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
      .content-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
      .content-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
      .content-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
      .content-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
      .content-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
      .content-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
      .content-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
      .content-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
      .content-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
      .content-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
      .content-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
      .content-menu .products-menu-item .products-submenu .products-submenu-item > a:focus,
      .products-menu .content-menu-item .content-submenu .content-submenu-item > a:hover,
      .products-menu .content-menu-item .content-submenu .content-submenu-item > a:focus,
      .products-menu .content-menu-item .content-submenu .products-submenu-item > a:hover,
      .products-menu .content-menu-item .content-submenu .products-submenu-item > a:focus,
      .products-menu .content-menu-item .products-submenu .content-submenu-item > a:hover,
      .products-menu .content-menu-item .products-submenu .content-submenu-item > a:focus,
      .products-menu .content-menu-item .products-submenu .products-submenu-item > a:hover,
      .products-menu .content-menu-item .products-submenu .products-submenu-item > a:focus,
      .products-menu .products-menu-item .content-submenu .content-submenu-item > a:hover,
      .products-menu .products-menu-item .content-submenu .content-submenu-item > a:focus,
      .products-menu .products-menu-item .content-submenu .products-submenu-item > a:hover,
      .products-menu .products-menu-item .content-submenu .products-submenu-item > a:focus,
      .products-menu .products-menu-item .products-submenu .content-submenu-item > a:hover,
      .products-menu .products-menu-item .products-submenu .content-submenu-item > a:focus,
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a:hover,
      .products-menu .products-menu-item .products-submenu .products-submenu-item > a:focus {
        text-decoration: underline; }
    .content-menu .content-menu-item .content-submenu .content-submenu-item .active,
    .content-menu .content-menu-item .content-submenu .products-submenu-item .active,
    .content-menu .content-menu-item .products-submenu .content-submenu-item .active,
    .content-menu .content-menu-item .products-submenu .products-submenu-item .active,
    .content-menu .products-menu-item .content-submenu .content-submenu-item .active,
    .content-menu .products-menu-item .content-submenu .products-submenu-item .active,
    .content-menu .products-menu-item .products-submenu .content-submenu-item .active,
    .content-menu .products-menu-item .products-submenu .products-submenu-item .active,
    .products-menu .content-menu-item .content-submenu .content-submenu-item .active,
    .products-menu .content-menu-item .content-submenu .products-submenu-item .active,
    .products-menu .content-menu-item .products-submenu .content-submenu-item .active,
    .products-menu .content-menu-item .products-submenu .products-submenu-item .active,
    .products-menu .products-menu-item .content-submenu .content-submenu-item .active,
    .products-menu .products-menu-item .content-submenu .products-submenu-item .active,
    .products-menu .products-menu-item .products-submenu .content-submenu-item .active,
    .products-menu .products-menu-item .products-submenu .products-submenu-item .active {
      font-weight: 800; }

.content-menu-generated {
  width: 100.25%;
  margin-top: 20px;
  color: #000; }
  .content-menu-generated ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  @media (min-width: 768px) {
    .content-menu-generated {
      display: block !important; } }
  @media (min-width: 768px) {
    .content-menu-generated {
      margin-top: 0px; } }
  .content-menu-generated li > a {
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 17px;
    color: inherit;
    font-weight: 600;
    padding: 5px 5px;
    -webkit-transition-property: background-color, color;
    -moz-transition-property: background-color, color;
    -o-transition-property: background-color, color;
    transition-property: background-color, color;
    -webkit-transition-duration: 250ms;
    -moz-transition-duration: 250ms;
    -o-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-timing-function: ease;
    -moz-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease; }
    @media (min-width: 768px) {
      .content-menu-generated li > a {
        font-size: 14px; } }
    @media (min-width: 992px) {
      .content-menu-generated li > a {
        font-size: 16px;
        font-weight: 800;
        padding: 5px 10px; } }
    @media (min-width: 1200px) {
      .content-menu-generated li > a {
        font-size: 18px;
        line-height: 20px; } }
    .content-menu-generated li > a:hover, .content-menu-generated li > a:focus {
      background-color: #0078c0;
      color: #000; }
  .content-menu-generated li.Highlighted > a {
    background-color: #0078c0;
    color: #000; }
  .content-menu-generated li.Highlighted .content-submenu {
    display: block; }
  .content-menu-generated li > ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #e0e0e0;
    padding: 0 10px;
    margin-bottom: 10px;
    text-decoration: none;
    display: none; }
    .content-menu-generated li > ul > li > a {
      position: relative;
      display: block;
      padding: 7px 0 7px 10px;
      color: #000;
      font-size: 16px;
      line-height: 17px;
      text-decoration: none; }
      @media (min-width: 768px) {
        .content-menu-generated li > ul > li > a {
          padding: 5px 0 5px 10px;
          font-size: 13px; } }
      @media (min-width: 992px) {
        .content-menu-generated li > ul > li > a {
          font-size: 16px; } }
      @media (min-width: 1200px) {
        .content-menu-generated li > ul > li > a {
          font-size: 18px;
          line-height: 20px; } }
      .content-menu-generated li > ul > li > a:before {
        display: block;
        content: "";
        width: 4px;
        height: 4px;
        background: #000;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: 14px; }
      .content-menu-generated li > ul > li > a:hover, .content-menu-generated li > ul > li > a:focus {
        text-decoration: underline; }
    .content-menu-generated li > ul > li .Highlighted {
      font-weight: 800; }

@media (min-width: 545px) {
  .faq-tabs {
    margin-top: 10px; } }

@media (min-width: 992px) {
  .faq-tabs {
    margin-top: 25px; } }

.faq-tabs-item {
  background-color: #e0e0e0;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  margin-bottom: 10px; }
  .faq-tabs-item-link {
    padding: 10px;
    cursor: pointer;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
    @media (min-width: 545px) {
      .faq-tabs-item-link {
        padding: 15px; } }
    @media (min-width: 992px) {
      .faq-tabs-item-link {
        padding: 17px 20px; } }
    .faq-tabs-item-link h2 {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 16px;
      line-height: 22px;
      border: 0px !important; }
      @media (min-width: 480px) {
        .faq-tabs-item-link h2 {
          font-weight: 800; } }
      @media (min-width: 545px) {
        .faq-tabs-item-link h2 {
          font-size: 18px;
          line-height: 27px; } }
      @media (min-width: 992px) {
        .faq-tabs-item-link h2 {
          font-size: 22px;
          line-height: 35px; } }
    .faq-tabs-item-link.active h2 {
      background-image: linear-gradient(#000 2px, transparent 2px);
      background-size: 100% 22px;
      background-position: 0 20px; }
      @media (min-width: 545px) {
        .faq-tabs-item-link.active h2 {
          background-size: 100% 27px;
          background-position: 0 25px; } }
      @media (min-width: 992px) {
        .faq-tabs-item-link.active h2 {
          background-size: 100% 35px;
          background-position: 0 33px; } }
    .faq-tabs-item-link.active:focus, .faq-tabs-item-link.active:hover {
      background-color: transparent; }
  .faq-tabs-item-content {
    display: none;
    padding: 0 10px 10px 10px;
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 545px) {
      .faq-tabs-item-content {
        padding: 0 15px 15px 15px; } }
    @media (min-width: 992px) {
      .faq-tabs-item-content {
        padding: 0 20px 17px 20px; } }
    .faq-tabs-item-content a {
      color: blue;
      text-decoration: underline; }
      .faq-tabs-item-content a:focus, .faq-tabs-item-content a:hover {
        color: blue;
        text-decoration: none; }
  .faq-tabs-item:focus, .faq-tabs-item:hover {
    background-color: #999999; }
  .faq-tabs-item.active {
    background-color: #0078c0; }
    .faq-tabs-item.active:focus, .faq-tabs-item.active:hover {
      background-color: #0078c0; }

.logos-gallery-item {
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
  font-weight: 600; }
  .logos-gallery-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .logos-gallery-item-image {
        margin-bottom: 25px; } }
    .logos-gallery-item-image img {
      max-width: 100%;
      max-height: 100%; }
  .logos-gallery-item-links {
    font-weight: 400; }
    .logos-gallery-item-links a {
      color: #0078c0;
      text-decoration: underline;
      display: inline-block;
      margin-top: 10px; }
      @media (min-width: 768px) {
        .logos-gallery-item-links a {
          margin-top: 0px; } }
      .logos-gallery-item-links a:focus, .logos-gallery-item-links a:hover {
        color: #0078c0;
        text-decoration: none; }

.contact-box {
  background-color: #f5f4f5;
  padding: 0 3rem;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 30px; }
  @media (min-width: 320px) {
    .contact-box {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .contact-box {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .contact-box {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .contact-box {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .contact-box {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .contact-box {
      font-size: 1.6rem; } }
  @media (min-width: 320px) {
    .contact-box {
      line-height: 3.2rem; } }
  @media (min-width: 480px) {
    .contact-box {
      line-height: 2.90909rem; } }
  @media (min-width: 545px) {
    .contact-box {
      line-height: 2.66667rem; } }
  @media (min-width: 768px) {
    .contact-box {
      line-height: 2.13333rem; } }
  @media (min-width: 992px) {
    .contact-box {
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    .contact-box {
      line-height: 1.8rem; } }
  .contact-box-content {
    align-self: flex-start; }
  .contact-box-button {
    margin: 1rem 0 1.5rem 0;
    padding: 1rem 5rem;
    color: #fff !important;
    background-color: #999;
    border: 2px solid transparent;
    font-size: 2.8rem;
    height: auto;
    font-weight: 800; }
    @media (min-width: 480px) {
      .contact-box-button {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .contact-box-button {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .contact-box-button {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .contact-box-button {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .contact-box-button {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .contact-box-button {
        font-size: 1.4rem; } }
    .contact-box-button:hover, .contact-box-button:focus {
      color: #000 !important;
      border-color: #000 !important;
      background-color: transparent; }
  .contact-box-image {
    margin-top: -30px;
    padding: 0 2rem;
    display: none; }
    @media (min-width: 480px) {
      .contact-box-image {
        display: block; } }
    .contact-box-image img {
      max-height: 180px;
      max-width: none; }

.about-us-peoples-title {
  background-color: #e0e0e0;
  padding: 5px 10px;
  margin: 0;
  border-bottom: 0 !important;
  margin-bottom: 0 !important; }

.about-us-peoples-item {
  margin-bottom: 30px;
  line-height: 18px;
  font-size: 13px; }
  @media (min-width: 992px) {
    .about-us-peoples-item {
      font-size: 14px; } }
  .about-us-peoples-item-title {
    font-size: 14px;
    margin-bottom: 3px; }
    @media (min-width: 768px) {
      .about-us-peoples-item-title {
        font-size: 16px;
        margin-bottom: 5px; } }
    @media (min-width: 992px) {
      .about-us-peoples-item-title {
        font-size: 18px; } }
  .about-us-peoples-item-image {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 67.5%;
    margin-bottom: 5px;
    background-color: #0078c0;
    overflow: hidden; }
    @media (min-width: 768px) {
      .about-us-peoples-item-image {
        margin-bottom: 15px;
        padding-bottom: 105%; } }
    .about-us-peoples-item-image img {
      position: absolute;
      bottom: 0;
      left: 50%;
      -ms-transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0);
      transform: translate(-50%, 0);
      width: auto;
      height: 100%; }
      @media (min-width: 768px) {
        .about-us-peoples-item-image img {
          width: 100%;
          height: auto; } }

.content-tabs {
  margin-top: 25px; }
  .content-tabs-title {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-flow: row nowrap;
    -ms-flex-direction: row;
    -ms-flex-wrap: nowrap;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    border-bottom: 2px solid #000; }
    .content-tabs-title-link {
      display: inline-block;
      padding: 2px 10px;
      font-size: 14px;
      color: #000;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600; }
      @media (min-width: 545px) {
        .content-tabs-title-link {
          padding: 2px 10px;
          font-size: 16px; } }
      @media (min-width: 768px) {
        .content-tabs-title-link {
          font-size: 18px; } }
      .content-tabs-title-link.active, .content-tabs-title-link:focus, .content-tabs-title-link:hover {
        color: #000;
        background-color: #e0e0e0;
        text-decoration: none !important; }
  @media (min-width: 545px) {
    .content-tabs-list {
      padding-top: 15px; } }
  .content-tabs-list-item {
    display: inline-block;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    text-decoration: none; }
    .content-tabs-list-item:focus, .content-tabs-list-item:hover {
      text-decoration: underline;
      color: #000; }
    .content-tabs-list-item-image {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 65%;
      border: 1px solid #000;
      overflow: hidden; }
      .content-tabs-list-item-image img {
        display: block;
        position: absolute;
        position: absolute;
        left: -200%;
        right: -200%;
        margin: auto;
        bottom: -200%;
        top: -200%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover; }
    .content-tabs-list-item-name {
      margin-top: 5px;
      font-size: 11px;
      text-transform: uppercase;
      color: #000; }
      @media (min-width: 480px) {
        .content-tabs-list-item-name {
          font-size: 13px; } }
      @media (min-width: 768px) {
        .content-tabs-list-item-name {
          margin-top: 10px; } }
      @media (min-width: 1200px) {
        .content-tabs-list-item-name {
          font-size: 14px; } }
      .content-tabs-list-item-name-overflow {
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis; }

.events-tabs .content-tabs-title-link {
  background-color: transparent;
  text-transform: none; }
  @media (min-width: 320px) and (max-width: 479px) {
    .events-tabs .content-tabs-title-link {
      font-size: 12px; } }
  .events-tabs .content-tabs-title-link:focus, .events-tabs .content-tabs-title-link:hover {
    background-color: #e0e0e0; }
  .events-tabs .content-tabs-title-link.active {
    background-color: #0078c0; }

.events-tabs .content-tabs-list-cont {
  padding-top: 10px; }
  @media (min-width: 768px) {
    .events-tabs .content-tabs-list-cont {
      padding-top: 25px; } }

.events-tabs-item {
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  background-color: #e0e0e0; }
  .events-tabs-item-image {
    width: 40%;
    min-height: 95px;
    padding: 5px;
    text-align: center; }
    @media (min-width: 480px) {
      .events-tabs-item-image {
        min-height: 135px;
        padding: 15px 12.5px; } }
    @media (min-width: 768px) {
      .events-tabs-item-image {
        padding: 25px 15.5px 25px 19.5px; } }
    @media (min-width: 992px) {
      .events-tabs-item-image {
        width: 27.5%;
        min-height: 168px; } }
    .events-tabs-item-image img {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      max-width: 100%;
      max-height: 100%;
      margin: 0px; }
  .events-tabs-item-text {
    position: relative;
    width: 75%;
    padding: 5px 5px 35px 5px;
    font-size: 13px;
    line-height: 15px; }
    @media (min-width: 545px) {
      .events-tabs-item-text {
        padding: 10px;
        padding-bottom: 50px;
        font-size: 14px;
        line-height: 18px; } }
    .events-tabs-item-text-title {
      font-size: 14px;
      font-weight: bold;
      line-height: 18px; }
      @media (min-width: 480px) {
        .events-tabs-item-text-title {
          font-size: 16px;
          line-height: 25px; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-title {
          font-size: 18px;
          line-height: 35px; } }
    .events-tabs-item-text-date {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-size: 13px;
      font-weight: 600; }
      @media (min-width: 480px) {
        .events-tabs-item-text-date {
          bottom: 20px;
          right: 15px;
          font-size: 14px; } }
      @media (min-width: 992px) {
        .events-tabs-item-text-date {
          font-size: 18px; } }

.locked-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  background-color: #e0e0e0;
  padding: 10px;
  margin-top: 45px;
  margin-bottom: 35px; }
  @media (min-width: 1200px) {
    .locked-box {
      padding: 15px; } }
  .locked-box-text {
    position: relative;
    padding-left: 30px;
    font-size: 15px;
    line-height: 17px; }
    @media (min-width: 480px) {
      .locked-box-text {
        font-size: 17px;
        line-height: 19px; } }
    @media (min-width: 545px) {
      .locked-box-text {
        font-size: 19px;
        line-height: 21px; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .locked-box-text {
        padding-left: 40px;
        font-size: 17px;
        line-height: 19px; } }
    @media (min-width: 1200px) {
      .locked-box-text {
        font-size: 22px;
        line-height: 25px; } }
    .locked-box-text:before {
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      left: -5px;
      font-size: 20px;
      -ms-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #000; }
      @media (min-width: 545px) {
        .locked-box-text:before {
          font-size: 22px; } }
      @media (min-width: 1200px) {
        .locked-box-text:before {
          font-size: 25px; } }
  .locked-box-submit {
    -webkit-flex: 0 0 90px;
    -moz-flex: 0 0 90px;
    -ms-flex: 0 0 90px;
    -webkit-flex: 0 0 90px;
    flex: 0 0 90px;
    padding-left: 10px; }
    @media (min-width: 545px) {
      .locked-box-submit {
        -webkit-flex-basis: 110px;
        -moz-flex-basis: 110px;
        -ms-flex-basis: 110px;
        -webkit-flex-basis: 110px;
        flex-basis: 110px; } }
    @media (min-width: 992px) {
      .locked-box-submit {
        -webkit-flex-basis: 160px;
        -moz-flex-basis: 160px;
        -ms-flex-basis: 160px;
        -webkit-flex-basis: 160px;
        flex-basis: 160px; } }
    .locked-box-submit .button-sign-up {
      width: 100%;
      min-height: 32px;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 5px; }

.download-cont {
  padding-bottom: 30px; }

.download-small-cont {
  padding-bottom: 30px;
  margin-top: 20px; }
  @media (min-width: 992px) {
    .download-small-cont {
      margin-top: 40px; } }
  .download-small-cont .product-card-title {
    margin-bottom: 5px; }
  .download-small-cont .download-item {
    margin-top: 10px;
    margin-bottom: 0px; }

.subscribed .locked-box {
  display: none; }

.unsubscribed .download-item.locked:before {
  content: "\e90a"; }

.solutions {
  display: flex; }

.blu-harters-page {
  color: #fff;
  position: relative; }
  .blu-harters-page:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1800px;
    background: linear-gradient(180deg, #0078c0 0%, #0078c0 15%, rgba(0, 118, 192, 0.171) 60%, transparent 90%); }
  @media (min-width: 992px) {
    .blu-harters-page .pux-container {
      background-image: url(/Prolyte/media/system/img/blu-harter.png);
      background-repeat: no-repeat;
      background-position: 100% 13rem;
      background-size: 41rem auto;
      position: relative;
      z-index: 22; } }
  .blu-harters-page-perex {
    font-weight: 700;
    margin-bottom: 7rem; }
    @media (min-width: 320px) {
      .blu-harters-page-perex {
        font-size: 6rem; } }
    @media (min-width: 480px) {
      .blu-harters-page-perex {
        font-size: 5.8rem; } }
    @media (min-width: 320px) {
      .blu-harters-page-perex {
        line-height: 6rem; } }
    @media (min-width: 480px) {
      .blu-harters-page-perex {
        line-height: 5.45455rem; } }
    @media (min-width: 545px) {
      .blu-harters-page-perex {
        line-height: 5.3rem; } }
    @media (min-width: 992px) {
      .blu-harters-page-perex {
        max-width: 87rem; } }
    .blu-harters-page-perex h1 {
      text-decoration: underline;
      text-decoration-color: #fe761c;
      margin-bottom: 1rem; }
      @media (min-width: 320px) {
        .blu-harters-page-perex h1 {
          font-size: 6rem; } }
      @media (min-width: 480px) {
        .blu-harters-page-perex h1 {
          font-size: 5.8rem; } }
      @media (min-width: 320px) {
        .blu-harters-page-perex h1 {
          line-height: 6rem; } }
      @media (min-width: 480px) {
        .blu-harters-page-perex h1 {
          line-height: 5.45455rem; } }
      @media (min-width: 545px) {
        .blu-harters-page-perex h1 {
          line-height: 5.3rem; } }

.blu-harter-item-box:not(:first-child) {
  margin: 12rem 0 0 0; }

.blu-harter-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  @media (min-width: 768px) {
    .blu-harter-item {
      flex-wrap: nowrap; } }
  @media (min-width: 992px) {
    .blu-harter-item {
      max-width: 93rem; } }
  .blu-harter-item-image {
    border-radius: 50%;
    width: 25rem;
    height: 25rem;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
    margin: 0 auto 2rem auto; }
    @media (min-width: 320px) {
      .blu-harter-item-image {
        width: 25rem; } }
    @media (min-width: 320px) {
      .blu-harter-item-image {
        height: 25rem; } }
    @media (min-width: 768px) {
      .blu-harter-item-image {
        margin: 0; } }
    .blu-harter-item-image img {
      min-width: 100%; }
  @media (min-width: 768px) {
    .blu-harter-item-text {
      margin-left: 4rem; } }
  .blu-harter-item-text-header {
    align-items: baseline;
    margin-bottom: 0.5rem;
    text-align: center; }
    @media (min-width: 768px) {
      .blu-harter-item-text-header {
        text-align: left; } }
    .blu-harter-item-text-header h3 {
      display: inline;
      color: #a4a4a4;
      margin-right: 8px; }
      @media (min-width: 320px) {
        .blu-harter-item-text-header h3 {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .blu-harter-item-text-header h3 {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .blu-harter-item-text-header h3 {
          font-size: 3.2rem; } }
    .blu-harter-item-text-header-position {
      color: #b9b9b9;
      font-weight: 700; }
      @media (min-width: 320px) {
        .blu-harter-item-text-header-position {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .blu-harter-item-text-header-position {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .blu-harter-item-text-header-position {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .blu-harter-item-text-header-position {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .blu-harter-item-text-header-position {
          font-size: 2rem; } }
  .blu-harter-item-text-long {
    position: relative;
    padding: 5rem 5rem 5rem 3rem;
    color: #000;
    background-color: #fafafa;
    text-align: center; }
    @media (min-width: 320px) {
      .blu-harter-item-text-long {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      .blu-harter-item-text-long {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .blu-harter-item-text-long {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .blu-harter-item-text-long {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .blu-harter-item-text-long {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .blu-harter-item-text-long {
        font-size: 1.6rem; } }
    @media (min-width: 320px) {
      .blu-harter-item-text-long {
        line-height: 2.8rem; } }
    @media (min-width: 480px) {
      .blu-harter-item-text-long {
        line-height: 2.54545rem; } }
    @media (min-width: 545px) {
      .blu-harter-item-text-long {
        line-height: 2.33333rem; } }
    @media (min-width: 768px) {
      .blu-harter-item-text-long {
        line-height: 1.86667rem; } }
    @media (min-width: 992px) {
      .blu-harter-item-text-long {
        line-height: 1.75rem; } }
    @media (min-width: 1200px) {
      .blu-harter-item-text-long {
        line-height: 1.6rem; } }
    @media (min-width: 768px) {
      .blu-harter-item-text-long {
        text-align: left; } }
    .blu-harter-item-text-long-inner {
      position: relative;
      z-index: 2; }
  .blu-harter-item-text-quote:before {
    content: "“";
    font-family: Helvetica !important;
    font-weight: 700;
    color: #e0e0e0;
    position: absolute;
    font-size: 21rem;
    line-height: 18rem;
    top: 0rem;
    left: 2rem;
    opacity: 0.5; }

#map-canvas {
  height: 95rem; }
  @media (min-width: 768px) {
    #map-canvas {
      height: 65rem; } }

.pac-container {
  display: block; }

.map-container {
  position: relative;
  margin: 0 auto 4rem auto; }
  .map-container .breadcrumbs {
    display: none; }
    @media (min-width: 768px) {
      .map-container .breadcrumbs {
        display: block;
        position: absolute;
        top: 10rem;
        z-index: 9999;
        left: 50%;
        width: 100%;
        max-width: 1260px;
        transform: translateX(-50%); } }
    @media (min-width: 320px) and (max-width: 1399px) {
      .map-container .breadcrumbs {
        margin-left: 15px; } }
    @media (min-width: 1200px) {
      .map-container .breadcrumbs {
        top: 9.5rem; } }

.map-search {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 768px) {
    .map-search {
      flex-wrap: nowrap; } }
  .map-search .btn {
    height: 47px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    width: 200px; }
    @media (min-width: 768px) {
      .map-search .btn {
        width: auto; } }
  .map-search input[type="text"] {
    border: none;
    background: #fff;
    width: 30rem;
    height: 47px;
    margin-bottom: 1rem;
    width: 200px; }
    @media (min-width: 768px) {
      .map-search input[type="text"] {
        width: 42rem;
        margin-right: 3.5rem; } }

.store-list {
  display: flex;
  flex-wrap: wrap;
  background-color: #f5f4f5;
  padding: 4rem; }
  @media (min-width: 992px) {
    .store-list {
      margin: 0 -2rem; } }
  .store-list .store-item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 2rem; }
    @media (min-width: 480px) {
      .store-list .store-item {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 768px) {
      .store-list .store-item {
        flex: 0 0 33.333%;
        max-width: 33.333%; } }
    .store-list .store-item-label {
      margin-bottom: 2rem; }
      .store-list .store-item-label-title {
        font-weight: 400;
        border-bottom: 1px solid #000;
        user-select: none;
        cursor: pointer; }
        @media (min-width: 320px) {
          .store-list .store-item-label-title {
            font-size: 2.8rem; } }
        @media (min-width: 480px) {
          .store-list .store-item-label-title {
            font-size: 2.54545rem; } }
        @media (min-width: 545px) {
          .store-list .store-item-label-title {
            font-size: 2.33333rem; } }
        @media (min-width: 768px) {
          .store-list .store-item-label-title {
            font-size: 1.9rem; } }
      .store-list .store-item-label-container {
        display: none; }
      .store-list .store-item-label-address, .store-list .store-item-label-location, .store-list .store-item-label-city, .store-list .store-item-label-zip, .store-list .store-item-label-tel, .store-list .store-item-label-email, .store-list .store-item-label-web {
        font-size: 16px; }

.filter-category {
  background-color: #f5f4f5;
  margin: 0 0 3rem 0;
  padding: 2rem 1.5rem 3rem 1.5rem; }
  @media (min-width: 992px) {
    .filter-category {
      margin: 0 1rem 0 0; } }
  .filter-category .map-filter-item {
    margin: 0 2rem 1rem 0;
    font-weight: 600;
    cursor: pointer; }
    @media (min-width: 992px) {
      .filter-category .map-filter-item {
        margin: 0 0 1rem 0; } }
    .filter-category .map-filter-item img {
      margin-right: 1rem; }
      @media (min-width: 992px) {
        .filter-category .map-filter-item img {
          margin-right: 0.5rem; } }

.title-dealers {
  position: absolute;
  top: 14rem;
  left: 50%;
  width: 100%;
  max-width: 1260px;
  transform: translateX(-50%);
  pointer-events: none; }
  @media (min-width: 320px) and (max-width: 1399px) {
    .title-dealers {
      margin-left: 15px; } }
  @media (min-width: 1200px) {
    .title-dealers {
      top: 17rem; } }

.contact-page .box-header {
  min-height: 55rem;
  margin-bottom: 2rem; }

.contact-page-col {
  display: flex;
  flex-direction: column; }

.contact-page.store-list {
  display: none; }

.contact-page-form {
  background: #f5f4f5;
  padding: 16px 16px 7.5rem 16px;
  margin-top: 1rem; }
  .contact-page-form input[type="text"],
  .contact-page-form textarea,
  .contact-page-form .selectric {
    background: #fff;
    margin-bottom: 0; }
  .contact-page-form .selectric .label {
    color: #000; }
  .contact-page-form .selectric .button {
    background-color: #999; }
  .contact-page-form .selectric-items li {
    color: #000;
    background: #fff; }
    .contact-page-form .selectric-items li:hover, .contact-page-form .selectric-items li:focus {
      background: #999; }
  .contact-page-form .contact-upload-text {
    margin-top: 1rem; }
  .contact-page-form .contact-submit-text {
    margin-top: 1rem; }

.contact-page#map-canvas {
  height: 38rem;
  border: 16px solid #f5f4f5;
  margin-top: 1.5rem; }

.contact-page-addresses {
  background-color: #f5f4f5;
  padding: 1.5rem 2rem;
  flex: 0 1 100%;
  margin-top: 1rem; }
  .contact-page-addresses ul {
    padding-left: 0;
    list-style: none;
    position: relative;
    padding-left: 30px; }
    .contact-page-addresses ul li:before {
      content: url("http://localhost:1168/Prolyte/media/system/img/pins/black-pin.png");
      position: absolute;
      top: 0;
      left: 0; }

.map-filter-item {
  padding: 0 0 10px 0; }
  .map-filter-item img {
    margin: 0 10px 0 0; }

.download-section {
  flex-wrap: wrap; }
  .download-section .division-box {
    margin: 0 -3.5px; }
    @media (min-width: 768px) {
      .download-section .division-box {
        margin: 0 -7px; } }
  .download-section.two-cards .download-item-box {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 545px) {
      .download-section.two-cards .download-item-box {
        flex: 0 0 50%;
        max-width: 50%;
        width: auto; } }
  .download-section.three-cards .download-item-box {
    width: 100%; }
    @media (min-width: 768px) {
      .download-section.three-cards .download-item-box {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        width: auto; } }
  .download-section .download-item-box {
    padding: 3.5px; }
    @media (min-width: 768px) {
      .download-section .download-item-box {
        padding: 7px; } }
  .download-section .download-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 2rem;
    background-color: #e0e0e0;
    background-image: none !important; }
    .download-section .download-item:hover, .download-section .download-item:focus {
      background-color: #f5f4f5; }
    .download-section .download-item .colored-title {
      margin-top: 0; }
    .download-section .download-item .perex {
      font-weight: 400;
      max-width: 280px;
      margin: 2.5rem auto 0 auto;
      color: #000; }
      @media (min-width: 320px) {
        .download-section .download-item .perex {
          font-size: 4rem; } }
      @media (min-width: 480px) {
        .download-section .download-item .perex {
          font-size: 3.63636rem; } }
      @media (min-width: 545px) {
        .download-section .download-item .perex {
          font-size: 3.33333rem; } }
      @media (min-width: 768px) {
        .download-section .download-item .perex {
          font-size: 2.66667rem; } }
      @media (min-width: 992px) {
        .download-section .download-item .perex {
          font-size: 2.5rem; } }
      @media (min-width: 1200px) {
        .download-section .download-item .perex {
          font-size: 2.3rem; } }
      @media (min-width: 320px) {
        .download-section .download-item .perex {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .download-section .download-item .perex {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .download-section .download-item .perex {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .download-section .download-item .perex {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .download-section .download-item .perex {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .download-section .download-item .perex {
          line-height: 2.3rem; } }
    .download-section .download-item-image {
      display: block;
      margin: 2.5rem 0 3rem 0; }
      .download-section .download-item-image img {
        width: auto;
        max-height: 38rem; }
    .download-section .download-item .btn {
      margin: 0 auto 9.5rem auto; }
    .download-section .download-item:before {
      display: none; }

.image-slider {
  position: relative;
  overflow: hidden;
  display: flex;
  z-index: 1; }
  .image-slider .image-slide {
    display: flex;
    flex: 0 0 100vw;
    max-width: 100vw;
    min-height: 48rem;
    background-position: center;
    background-size: cover;
    visibility: hidden;
    opacity: 0;
    padding: 10rem 0;
    user-select: none; }
    .image-slider .image-slide .pux-container-narrow {
      position: relative;
      width: 100%; }
    .image-slider .image-slide-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }
      @media (min-width: 992px) {
        .image-slider .image-slide-inner {
          height: 100%;
          justify-content: flex-start;
          flex-direction: row; } }
    .image-slider .image-slide-content {
      line-height: 1.7; }
      @media (min-width: 320px) {
        .image-slider .image-slide-content {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .image-slider .image-slide-content {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .image-slider .image-slide-content {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .image-slider .image-slide-content {
          font-size: 2.8rem; } }
      @media (min-width: 992px) {
        .image-slider .image-slide-content {
          padding-right: 4rem; } }
      @media (min-width: 1200px) {
        .image-slider .image-slide-content {
          padding-right: 20rem; } }
      .image-slider .image-slide-content-cta {
        padding: 4rem 0 2rem; }
      .image-slider .image-slide-content h1,
      .image-slider .image-slide-content h2,
      .image-slider .image-slide-content h3,
      .image-slider .image-slide-content h4,
      .image-slider .image-slide-content big {
        font-size: 40px;
        line-height: 1;
        letter-spacing: -0.2rem;
        font-weight: 600;
        margin: 0.8rem 0 2rem;
        display: block;
        text-shadow: 1px 1px 2px #000; }
        @media (min-width: 480px) {
          .image-slider .image-slide-content h1,
          .image-slider .image-slide-content h2,
          .image-slider .image-slide-content h3,
          .image-slider .image-slide-content h4,
          .image-slider .image-slide-content big {
            font-size: 11.2rem; } }
        @media (min-width: 1200px) {
          .image-slider .image-slide-content h1,
          .image-slider .image-slide-content h2,
          .image-slider .image-slide-content h3,
          .image-slider .image-slide-content h4,
          .image-slider .image-slide-content big {
            width: calc(100% + 20rem); } }
      .image-slider .image-slide-content .marker {
        color: #ff9100; }
    .image-slider .image-slide-picture {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px; }
      @media (min-width: 992px) {
        .image-slider .image-slide-picture {
          flex-grow: 1;
          max-width: 65%;
          padding: 0; } }
      .image-slider .image-slide-picture img {
        pointer-events: none; }
    .image-slider .image-slide.background-white {
      background-color: #fff; }
    .image-slider .image-slide.background-black {
      background-color: #000; }
    .image-slider .image-slide.background-blue {
      background-color: #0086ce; }
    .image-slider .image-slide.slide-remove {
      transition: opacity 600ms ease-out;
      opacity: 0.3; }
      .image-slider .image-slide.slide-remove .base-slider-dots {
        visibility: hidden !important;
        opacity: 0 !important; }
    .image-slider .image-slide.slide-active {
      position: relative;
      opacity: 1;
      visibility: visible;
      z-index: 2;
      transition: opacity 600ms ease-in; }
      .image-slider .image-slide.slide-active .base-slider-dots li.slide-active {
        background-color: #0078c0; }
  .image-slider .base-slider-dots {
    display: inline-flex;
    flex-wrap: wrap;
    position: absolute;
    left: 15px;
    z-index: 3;
    list-style: none;
    padding: 0;
    margin: 0; }
    @media (min-width: 1200px) {
      .image-slider .base-slider-dots {
        left: 8.5%; } }
    .image-slider .base-slider-dots li {
      padding: 1.2rem 4rem 1.2rem 0;
      cursor: pointer;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      letter-spacing: 0.1rem;
      font-size: 18px;
      line-height: 1; }
      @media (min-width: 992px) {
        .image-slider .base-slider-dots li {
          font-size: 2rem; } }
      .image-slider .base-slider-dots li div {
        background-color: rgba(184, 184, 184, 0.22);
        color: #b8b8b8;
        padding: 0.8rem 5px;
        position: relative; }
        .image-slider .base-slider-dots li div:before {
          content: "";
          width: calc(100% + 4rem);
          height: 2px;
          background-color: #b8b8b8;
          position: absolute;
          bottom: 0;
          left: 0;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          transform: translateY(100%); }
      .image-slider .base-slider-dots li.dot-active div {
        color: #000; }
        .image-slider .base-slider-dots li.dot-active div:before {
          background-color: #000; }
  .image-slider.fixed-top .base-slider-dots {
    top: 3rem; }
  @media (min-width: 320px) and (max-width: 544px) {
    .image-slider.fixed-top.mobile-padding .image-slide {
      padding-top: 120px; } }
  .image-slider.fixed-bottom .base-slider-dots {
    bottom: 3rem; }
  @media (min-width: 320px) and (max-width: 544px) {
    .image-slider.fixed-bottom.mobile-padding .image-slide {
      padding-bottom: 120px; } }
  .image-slider.hide-navigation-text .base-slider-dots li {
    width: 4.2rem;
    padding: 2rem 0;
    font-size: 0;
    margin: 0 0.6rem; }
    .image-slider.hide-navigation-text .base-slider-dots li div {
      font-size: 0;
      padding: 0; }
      .image-slider.hide-navigation-text .base-slider-dots li div:before {
        height: 1px;
        background-color: #8c8c8c;
        bottom: 40%;
        width: 100%; }
    .image-slider.hide-navigation-text .base-slider-dots li.dot-active div:before {
      height: 2px;
      background-color: #ff9100; }
  .image-slider.under-text .base-slider-dots {
    position: static;
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 3rem;
    width: calc(100vw * 0.83); }
  .image-slider.height-heighest .image-slide {
    min-height: 102rem; }
  .image-slider.height-fullscreen .image-slide {
    min-height: 100vh; }
    @media (min-width: 1500px) {
      .image-slider.height-fullscreen .image-slide {
        padding-bottom: 15rem; } }
    @media (min-width: 1800px) and (max-width: 4000px) {
      .image-slider.height-fullscreen .image-slide {
        padding-bottom: 10rem; } }
  .image-slider.theme-dark {
    background-color: #333333; }
    .image-slider.theme-dark .image-slide {
      color: #fff; }
      .image-slider.theme-dark .image-slide .marker {
        color: #0086ce; }
    .image-slider.theme-dark:not(.hide-navigation-text) .base-slider-dots li div {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.75); }
      .image-slider.theme-dark:not(.hide-navigation-text) .base-slider-dots li div:before {
        background-color: #636363; }
    .image-slider.theme-dark:not(.hide-navigation-text) .base-slider-dots li.dot-active div {
      color: #fff; }
      .image-slider.theme-dark:not(.hide-navigation-text) .base-slider-dots li.dot-active div:before {
        background-color: #fff; }
  .image-slider.theme-blue {
    background-color: #0086ce; }
    .image-slider.theme-blue .image-slide {
      color: #fff; }
      .image-slider.theme-blue .image-slide .base-slider-dots li div {
        background-color: transparent; }
        .image-slider.theme-blue .image-slide .base-slider-dots li div:before {
          background-color: #fff; }
    .image-slider.theme-blue:not(.hide-navigation-text).base-slider-dots li {
      color: #636363; }
      .image-slider.theme-blue:not(.hide-navigation-text).base-slider-dots li div:before {
        background-color: #636363; }
      .image-slider.theme-blue:not(.hide-navigation-text).base-slider-dots li.dot-active {
        color: #fff; }
        .image-slider.theme-blue:not(.hide-navigation-text).base-slider-dots li.dot-active div:before {
          background-color: #fff; }
  @media (min-width: 320px) and (max-width: 991px) {
    .image-slider.content-center .image-slide-inner {
      justify-content: center; } }
  @media (min-width: 992px) {
    .image-slider.content-center .image-slide-inner {
      align-items: center; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .image-slider.content-bottom .image-slide-inner {
      justify-content: flex-end; } }
  @media (min-width: 992px) {
    .image-slider.content-bottom .image-slide-inner {
      align-items: flex-end; } }
  .image-slider.align-right .image-slide-inner {
    text-align: right; }
    @media (min-width: 320px) and (max-width: 991px) {
      .image-slider.align-right .image-slide-inner {
        align-items: flex-end; } }
    @media (min-width: 992px) {
      .image-slider.align-right .image-slide-inner {
        justify-content: flex-end; } }
  @media (min-width: 992px) {
    .image-slider.align-right .image-slide-content {
      padding: 0;
      order: 2;
      padding-left: 4rem; } }
  @media (min-width: 1200px) {
    .image-slider.align-right .image-slide-content {
      padding-left: 20rem; } }
  @media (min-width: 1200px) {
    .image-slider.align-right .image-slide-content h1,
    .image-slider.align-right .image-slide-content h2,
    .image-slider.align-right .image-slide-content h3,
    .image-slider.align-right .image-slide-content h4,
    .image-slider.align-right .image-slide-content big {
      transform: translateX(-20rem); } }
  @media (min-width: 992px) {
    .image-slider.align-right .image-slide-picture {
      order: 1; } }
  .image-slider.align-right .base-slider-dots.fixed {
    left: auto;
    right: 15px; }
    @media (min-width: 1200px) {
      .image-slider.align-right .base-slider-dots.fixed {
        right: 8.5%; } }
  @media (min-width: 992px) {
    .image-slider[class*=text-width] .image-slide-content {
      flex: 0 0 60%;
      max-width: 60%; } }
  @media (min-width: 992px) {
    .image-slider.text-width-35 .image-slide-content {
      flex: 0 0 55%;
      max-width: 55%; } }
  @media (min-width: 1400px) {
    .image-slider.text-width-35 .image-slide-content {
      flex: 0 1 35%;
      max-width: 35%; } }
  @media (min-width: 1400px) {
    .image-slider.text-width-45 .image-slide-content {
      flex: 0 0 45%;
      max-width: 45%; } }
  @media (min-width: 1400px) {
    .image-slider.text-width-55 .image-slide-content {
      flex: 0 0 55%;
      max-width: 55%; } }

.trainers {
  text-align: center;
  background: #000;
  color: #fff; }
  @media (min-width: 1200px) {
    .trainers {
      text-align: left;
      display: flex;
      padding-left: 8.5%; } }
  .trainers-content {
    padding: 8rem 5%;
    font-size: 20px;
    line-height: 1.8; }
    @media (min-width: 768px) {
      .trainers-content {
        padding-top: 8rem;
        font-size: 3.0rem; } }
    @media (min-width: 1200px) {
      .trainers-content {
        flex: 0 1 50rem;
        max-width: 50rem;
        padding: 8rem 0; } }
    .trainers-content h1,
    .trainers-content h2,
    .trainers-content h3,
    .trainers-content h4,
    .trainers-content big {
      font-size: 40px;
      line-height: 1;
      letter-spacing: -0.2rem;
      font-weight: 600;
      padding-bottom: 2rem; }
      @media (min-width: 480px) {
        .trainers-content h1,
        .trainers-content h2,
        .trainers-content h3,
        .trainers-content h4,
        .trainers-content big {
          font-size: 11.2rem; } }
  .trainers-persons {
    display: flex;
    overflow: hidden;
    padding-bottom: 100px; }
    @media (min-width: 545px) {
      .trainers-persons {
        padding-bottom: 0; } }
    @media (min-width: 1200px) {
      .trainers-persons {
        flex-grow: 1; } }
    .trainers-persons-item {
      flex: 0 1 40%;
      max-width: 40%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      text-align: left;
      min-height: 67rem; }
      .trainers-persons-item-text {
        z-index: 1;
        letter-spacing: 0.47px;
        color: #999999;
        text-transform: uppercase;
        padding-top: 9rem; }
        @media (min-width: 768px) {
          .trainers-persons-item-text {
            padding-top: 8rem; } }
      .trainers-persons-item-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 250%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .trainers-persons-item img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        width: 100%; }
      .trainers-persons-item.left-type .trainers-persons-item-text {
        padding-left: 15px; }
        @media (min-width: 545px) {
          .trainers-persons-item.left-type .trainers-persons-item-text {
            padding-left: 7rem; } }
      .trainers-persons-item.center-type {
        flex: 0 1 20%;
        max-width: 20%;
        z-index: 2;
        transform: translateY(100px); }
        @media (min-width: 545px) {
          .trainers-persons-item.center-type {
            transform: none; } }
        .trainers-persons-item.center-type .trainers-persons-item-text {
          padding-left: 10rem; }
      .trainers-persons-item.right-type {
        justify-content: center; }
        @media (min-width: 545px) {
          .trainers-persons-item.right-type {
            padding-left: 7rem; } }
        @media (min-width: 1400px) {
          .trainers-persons-item.right-type {
            padding-left: 0; } }
  .trainers.theme-light {
    background: #fff;
    color: #000; }
    .trainers.theme-light .trainers-persons-item-text {
      color: gray; }
  .trainers.theme-blue {
    background: #0086ce; }

.yt-video {
  background-color: #000;
  color: #fff;
  padding-bottom: 10rem; }
  @media (min-width: 768px) {
    .yt-video-inner {
      display: flex;
      flex-wrap: wrap; } }
  .yt-video-title {
    flex: 0 1 100%;
    max-width: 100%; }
  .yt-video-main {
    padding-bottom: 10px; }
    @media (min-width: 768px) {
      .yt-video-main {
        flex-grow: 1;
        width: calc(100% - 200px);
        padding: 0;
        padding-right: 10px; } }
    @media (min-width: 1200px) {
      .yt-video-main {
        width: calc(100% - 25rem);
        padding-right: 1rem; } }
    @media (min-width: 768px) {
      .yt-video-main a {
        height: 100%; } }
  .yt-video-list {
    position: relative; }
    @media (min-width: 768px) {
      .yt-video-list {
        flex: 0 1 200px;
        max-width: 200px;
        height: 486px; } }
    @media (min-width: 992px) {
      .yt-video-list {
        height: 600px; } }
    @media (min-width: 1200px) {
      .yt-video-list {
        flex: 0 1 25rem;
        max-width: 25rem;
        height: 60rem; } }
    @media (min-width: 1400px) {
      .yt-video-list {
        height: 74rem; } }
    .yt-video-list a {
      flex: 0 0 25vw;
      max-width: 25vw;
      display: block;
      min-height: 50px;
      margin: 0 10px 0 0; }
      @media (min-width: 768px) {
        .yt-video-list a {
          flex: none;
          max-width: none;
          margin: 0 0 10px; } }
      @media (min-width: 1200px) {
        .yt-video-list a {
          margin: 0 0 1rem; } }
    .yt-video-list-inner {
      overflow: auto;
      display: flex;
      width: 100%; }
      @media (min-width: 768px) {
        .yt-video-list-inner {
          display: block;
          overflow: hidden;
          max-height: 100%; } }
    .yt-video-list .yt-video-arrow {
      position: absolute;
      width: 30px;
      height: 100%;
      z-index: 6;
      cursor: pointer;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      background: rgba(0, 0, 0, 0.8);
      display: none; }
      @media (min-width: 768px) {
        .yt-video-list .yt-video-arrow {
          width: 100%;
          height: 3rem;
          display: block; } }
      .yt-video-list .yt-video-arrow:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url("/Prolyte/media/system/img/arrow-video.svg");
        background-position: center;
        background-repeat: no-repeat; }
      .yt-video-list .yt-video-arrow.arrow-prev {
        top: 0;
        left: 0; }
        .yt-video-list .yt-video-arrow.arrow-prev:before {
          transform: rotate(90deg); }
          @media (min-width: 768px) {
            .yt-video-list .yt-video-arrow.arrow-prev:before {
              transform: rotate(180deg); } }
      .yt-video-list .yt-video-arrow.arrow-next {
        top: 0;
        right: 0; }
        @media (min-width: 768px) {
          .yt-video-list .yt-video-arrow.arrow-next {
            right: auto;
            top: auto;
            bottom: 0;
            left: 0; } }
        .yt-video-list .yt-video-arrow.arrow-next:before {
          transform: rotate(-90deg); }
          @media (min-width: 768px) {
            .yt-video-list .yt-video-arrow.arrow-next:before {
              transform: rotate(0deg); } }
      .yt-video-list .yt-video-arrow:hover, .yt-video-list .yt-video-arrow:focus {
        background: rgba(0, 0, 0, 0.5); }
      .yt-video-list .yt-video-arrow.arrow-disabled {
        opacity: 0; }
  .yt-video a {
    display: block;
    background-position: center;
    background-size: cover;
    position: relative; }
    .yt-video a:before {
      content: "";
      display: block;
      padding-top: 56%; }

.blueheart-banner {
  background-color: #0086ce;
  color: #fff;
  padding: 30rem 8.5% 40rem;
  position: relative;
  overflow: hidden;
  margin-bottom: -1px; }
  @media (min-width: 992px) {
    .blueheart-banner {
      display: flex;
      margin: 0;
      min-height: 80vh;
      padding: 0;
      padding-left: 8.5%; } }
  .blueheart-banner-title {
    padding: 0 15px;
    position: relative;
    z-index: 2;
    margin-bottom: 10rem; }
    @media (min-width: 992px) {
      .blueheart-banner-title {
        padding: 0;
        flex-shrink: 0;
        align-self: flex-end;
        display: inline-block; } }
    .blueheart-banner-title h1 {
      font-size: 40px;
      line-height: 1;
      letter-spacing: -0.2rem;
      font-weight: 600;
      margin: 0.8rem 0 2rem;
      display: block; }
      @media (min-width: 480px) {
        .blueheart-banner-title h1 {
          font-size: 11.2rem; } }
    .blueheart-banner-title:after {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      display: block;
      z-index: -1;
      background-image: url("/Prolyte/media/system/img/heart-bg-2.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      height: 40rem;
      transform: translateY(100%); }
      @media (min-width: 992px) {
        .blueheart-banner-title:after {
          right: 0;
          transform: translate(99%, -4%);
          background-size: 100% 100%;
          width: calc(100vw - 100%);
          padding-bottom: 50%; } }
      @media (min-width: 1400px) {
        .blueheart-banner-title:after {
          transform: translateX(99%);
          padding-bottom: 70%; } }
  .blueheart-banner:after {
    content: "";
    display: block;
    width: 100%;
    height: 50rem;
    z-index: 2;
    background-image: url("/Prolyte/media/system/img/heart-man.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 0; }
    @media (min-width: 992px) {
      .blueheart-banner:after {
        position: static;
        height: auto;
        bottom: auto;
        flex-grow: 1;
        background-size: 72%;
        background-position: -50% 100%; } }
    @media (min-width: 1200px) {
      .blueheart-banner:after {
        background-size: 65%;
        background-position: -25% 100%; } }
    @media (min-width: 1400px) {
      .blueheart-banner:after {
        background-size: contain;
        background-position: left bottom; } }

.blueheart-page .pux-container-narrow {
  max-width: 77%; }

@media (min-width: 992px) {
  .blueheart-page .align-right .image-slide-content {
    text-align: left; } }

@media (min-width: 1200px) {
  .blueheart-page .align-right .image-slide-content {
    padding-left: 8rem;
    padding-right: 10rem; } }

@media (min-width: 992px) {
  .blueheart-page .align-right .image-slide-content h1,
  .blueheart-page .align-right .image-slide-content h2,
  .blueheart-page .align-right .image-slide-content h3,
  .blueheart-page .align-right .image-slide-content h4,
  .blueheart-page .align-right .image-slide-content big {
    transform: none; } }

.tooltip-inner {
  font-size: 20px; }

.afi-gallery-item {
  padding-left: 5px;
  padding-bottom: 5px;
  flex: 0 1 100%;
  max-width: 100%; }
  @media (min-width: 480px) {
    .afi-gallery-item {
      flex: 0 1 50%;
      max-width: 50%; } }
  @media (min-width: 992px) {
    .afi-gallery-item {
      flex: 0 1 33.333%;
      max-width: 33.333%; } }
  .afi-gallery-item-inner {
    position: relative;
    overflow: hidden;
    display: block; }
    .afi-gallery-item-inner:before {
      content: "";
      display: block;
      padding-top: 80%; }
    .afi-gallery-item-inner img {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: top; }

.afi-gallery.large-space .afi-gallery-item {
  padding-bottom: 40px; }

.IE11 .hp-heroimage-content-title {
  min-height: 80vh; }

@media (min-width: 992px) {
  .IE11 .blueheart-banner-title {
    padding-top: 50rem; } }

.roof-config-page {
  display: flex;
  align-items: center;
  padding: clamp(20px, 4.10256vw, 4rem) 0; }
  @media (min-width: 2000px) {
    .roof-config-page {
      height: 100vh; } }
  .roof-config-page .pux-container {
    width: 100%; }

.roof-config-slider {
  display: flex;
  overflow: hidden; }
  .roof-config-slider .roof-config-slider-item {
    width: 100%;
    min-width: 100%; }
  .roof-config-slider .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 22;
    width: clamp(40px, 7.17949vw, 7rem);
    height: clamp(40px, 7.17949vw, 7rem);
    border: 2px solid #0078c0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    background: #fff;
    color: #0078c0;
    transition: 250ms ease-in-out; }
    @media (min-width: 320px) and (max-width: 767px) {
      .roof-config-slider .slick-arrow {
        display: none !important; } }
    .roof-config-slider .slick-arrow:before {
      font-size: clamp(30px, 5.12821vw, 5rem); }
    .roof-config-slider .slick-arrow:focus {
      outline: 0; }
    .roof-config-slider .slick-arrow:hover {
      background-color: #0078c0;
      color: #fff; }
  .roof-config-slider .slick-prev {
    left: clamp(10px, 3.07692vw, 3rem); }
    .roof-config-slider .slick-prev:before {
      content: "\e90d"; }
  .roof-config-slider .slick-next {
    right: clamp(10px, 3.07692vw, 3rem); }
    .roof-config-slider .slick-next:before {
      content: "\e90c";
      display: inline-block;
      transform: rotate(90deg); }
  .roof-config-slider .slick-dots {
    position: absolute;
    left: 0;
    bottom: clamp(-60px, -9.23077vw, -9rem);
    z-index: 22;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: clamp(15px, 1.53846vw, 1.5rem);
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%; }
    @media (min-width: 1200px) {
      .roof-config-slider .slick-dots {
        max-width: 50%;
        left: 50%;
        transform: translateX(-50%); } }
    .roof-config-slider .slick-dots button {
      border: none;
      background: #D5E7F1;
      font-size: 0;
      width: clamp(50px, 10.25641vw, 10rem);
      height: clamp(5px, 0.51282vw, 0.5rem);
      outline: 0; }
      .roof-config-slider .slick-dots button:focus {
        outline: 0; }
    .roof-config-slider .slick-dots .slick-active button {
      background: #0078C0; }

.roof-config-main-image {
  position: relative; }

.roof-config-slider.slick-initialized {
  display: block;
  overflow: visible; }
  .roof-config-slider.slick-initialized .roof-config-slider-item {
    width: 100%;
    min-width: 1px; }

.roof-config {
  position: relative;
  display: flex;
  font-size: clamp(14px, 1.64103vw, 1.6rem);
  line-height: clamp(16px, 1.84615vw, 1.8rem);
  font-weight: 700;
  border: 2px solid #D5E7F1; }
  .roof-config .roof-config-main {
    width: 100%;
    position: relative; }
    @media (min-width: 768px) {
      .roof-config .roof-config-main {
        max-width: 70%; } }
    @media (min-width: 1500px) {
      .roof-config .roof-config-main {
        width: calc(100% - clamp(30px, 63.58974vw, 62rem)); } }
    .roof-config .roof-config-main .roof-config-product {
      position: relative;
      padding: 11rem 3rem 4rem 3rem;
      text-align: center; }
      @media (min-width: 768px) {
        .roof-config .roof-config-main .roof-config-product {
          text-align: end;
          padding: 3rem 3rem 4rem 3rem; } }
      .roof-config .roof-config-main .roof-config-product .roof-config-open-settings {
        position: absolute;
        top: 2rem;
        left: 2rem;
        height: 6rem;
        background-color: #0078c0;
        padding: 0 1.5rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px; }
        @media (min-width: 768px) {
          .roof-config .roof-config-main .roof-config-product .roof-config-open-settings {
            display: none; } }
        .roof-config .roof-config-main .roof-config-product .roof-config-open-settings::before {
          content: "\e91d";
          font-size: 4rem;
          margin: 0 1rem 0 0; }
      .roof-config .roof-config-main .roof-config-product .roof-config-product-name {
        margin-bottom: 2rem;
        font-size: clamp(24px, 3.07692vw, 3rem);
        line-height: clamp(29px, 3.58974vw, 3.5rem); }
      .roof-config .roof-config-main .roof-config-product .roof-config-product-perex {
        margin-left: auto;
        font-weight: 400; }
        @media (min-width: 768px) {
          .roof-config .roof-config-main .roof-config-product .roof-config-product-perex {
            max-width: 70rem; } }
    .roof-config .roof-config-main .roof-config-main-image {
      height: 53rem;
      margin: 0 auto clamp(40px, 10.25641vw, 10rem) auto; }
      .roof-config .roof-config-main .roof-config-main-image .slick-list {
        width: 100%;
        max-width: 90rem;
        margin: auto; }
        .roof-config .roof-config-main .roof-config-main-image .slick-list .slick-track {
          display: flex;
          align-items: center; }
      .roof-config .roof-config-main .roof-config-main-image img {
        width: 100%;
        max-width: 90rem;
        height: 100%;
        max-height: 53rem;
        object-fit: contain; }
    .roof-config .roof-config-main .roof-config-result {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 4rem;
      margin-top: 2rem;
      padding: 3rem;
      background-color: #0078c0;
      color: #fff;
      position: relative;
      text-align: center; }
      @media (min-width: 768px) {
        .roof-config .roof-config-main .roof-config-result {
          text-align: left; } }
      @media (min-width: 992px) {
        .roof-config .roof-config-main .roof-config-result {
          flex-direction: unset; } }
      .roof-config .roof-config-main .roof-config-result .roof-config-result-left .roof-config-result-name {
        margin: 0 0 1rem 0;
        font-size: clamp(24px, 3.07692vw, 3rem);
        line-height: clamp(29px, 3.48718vw, 3.4rem); }
      .roof-config .roof-config-main .roof-config-result .roof-config-result-left .roof-config-result-accessories {
        font-weight: 400; }
      .roof-config .roof-config-main .roof-config-result .roof-config-result-right .btn-default {
        height: unset;
        padding: clamp(15px, 3.07692vw, 3rem) clamp(30px, 6.15385vw, 6rem);
        font-size: clamp(16px, 2.05128vw, 2rem);
        line-height: clamp(19px, 2.35897vw, 2.3rem);
        font-weight: 700;
        border: 2px solid #fff !important;
        transition: 250ms ease-in-out;
        background: #0078c0;
        color: #fff !important; }
        .roof-config .roof-config-main .roof-config-result .roof-config-result-right .btn-default:hover {
          background-color: #fff !important;
          color: #0078c0 !important; }
    .roof-config .roof-config-main .btn-more {
      position: absolute;
      bottom: 0;
      right: 3rem; }
  .roof-config .roof-config-more-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 3rem;
    position: relative; }
    .roof-config .roof-config-more-btn .btn {
      padding: clamp(15px, 2.05128vw, 2rem) clamp(20px, 3.07692vw, 3rem);
      font-weight: 700; }
  .roof-config .roof-config-settings {
    display: none;
    padding: 3rem 4rem;
    overflow: auto; }
    @media (min-width: 768px) {
      .roof-config .roof-config-settings {
        display: block;
        width: clamp(30px, 63.58974vw, 62rem);
        border-left: 2px solid #D5E7F1; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .roof-config .roof-config-settings.open {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 100; } }
    .roof-config .roof-config-settings .roof-config-settings-btns {
      margin-top: 4rem; }
      @media (min-width: 768px) {
        .roof-config .roof-config-settings .roof-config-settings-btns {
          display: none; } }
  .roof-config .roof-config-setting-title {
    margin: 3rem 0 1.5rem 0;
    font-size: clamp(20px, 2.46154vw, 2.4rem);
    line-height: clamp(24px, 2.87179vw, 2.8rem); }
  .roof-config table {
    width: 100%;
    margin: 0; }
  .roof-config td {
    padding: 0; }
  .roof-config tr + tr label {
    margin-top: 1.5rem; }
  .roof-config input[type="radio"] {
    display: none; }
    .roof-config input[type="radio"] + label::before {
      border-radius: 50%;
      transition: 250ms ease-in-out; }
    .roof-config input[type="radio"]:checked + label {
      color: #0078c0; }
      .roof-config input[type="radio"]:checked + label::before {
        border-width: clamp(5px, 0.71795vw, 0.7rem); }
  .roof-config input[type="checkbox"] {
    display: none; }
    .roof-config input[type="checkbox"] + label::before {
      transition: 250ms ease-in-out; }
    .roof-config input[type="checkbox"] + label::after {
      content: "\2713";
      display: inline-block;
      position: absolute;
      top: calc(50% - 1px);
      left: clamp(0.5px, 0.41026vw, 0.4rem);
      line-height: clamp(5.5px, 0.66667vw, 0.65rem);
      color: #fff;
      opacity: 0;
      /* TODO: change with change icon */
      transform: translateY(-50%);
      transition: 250ms ease-in-out; }
    .roof-config input[type="checkbox"]:checked + label {
      color: #0078c0; }
      .roof-config input[type="checkbox"]:checked + label::before {
        background-color: #0078c0; }
      .roof-config input[type="checkbox"]:checked + label::after {
        opacity: 1; }
  .roof-config input[type="text"] {
    width: 100%;
    margin-bottom: 30px;
    border: 2px solid #0078c0;
    background-color: #fff; }
    .roof-config input[type="text"]::placeholder {
      color: #000; }
  .roof-config label {
    position: relative;
    margin-bottom: 0;
    padding-left: clamp(25px, 3.07692vw, 3rem);
    font-size: clamp(14px, 1.64103vw, 1.6rem);
    line-height: clamp(15px, 2.05128vw, 2rem);
    font-weight: 700; }
    .roof-config label::before {
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;
      height: clamp(15px, 2.05128vw, 2rem);
      width: clamp(15px, 2.05128vw, 2rem);
      border: 2px solid #0078c0; }
  .roof-config .selectric-wrapper .selectric {
    height: clamp(35px, 4.10256vw, 4rem);
    background-color: #fff;
    border: 2px solid #0078c0; }
    .roof-config .selectric-wrapper .selectric .label {
      line-height: clamp(35px, 4.10256vw, 4rem);
      font-size: clamp(14px, 1.64103vw, 1.6rem);
      color: #0078c0; }
    .roof-config .selectric-wrapper .selectric .button {
      background-color: inherit; }
      .roof-config .selectric-wrapper .selectric .button::after {
        color: #0078c0; }
  .roof-config .selectric-wrapper .selectric-items {
    background-color: #fff;
    border: 2px solid #0078c0; }
    .roof-config .selectric-wrapper .selectric-items li {
      background-color: unset; }
      .roof-config .selectric-wrapper .selectric-items li:hover {
        color: #0078c0; }
      .roof-config .selectric-wrapper .selectric-items li.highlighted {
        color: #0078c0;
        background-color: rgba(0, 120, 192, 0.05); }
  .roof-config .selectric-wrapper .selectric-hide-select.selectric-is-native select {
    background-color: #fff;
    border: 2px solid #0078c0; }
    .roof-config .selectric-wrapper .selectric-hide-select.selectric-is-native select option {
      background-color: unset;
      font-weight: 700; }
      .roof-config .selectric-wrapper .selectric-hide-select.selectric-is-native select option[selected="selected"] {
        color: #0078c0;
        background-color: rgba(0, 120, 192, 0.05); }
  .roof-config .roof-config-copy {
    position: absolute;
    left: 5px;
    top: 5px; }
  .roof-config .roof-config-copy-result {
    position: absolute;
    left: 50%;
    top: 0;
    background: #fff;
    color: #0078c0;
    padding: 5px 10px;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: 250ms; }
    .roof-config .roof-config-copy-result.active {
      opacity: 1;
      transform: translate(-50%, 0%); }
  .roof-config .selectric-scroll ul {
    display: block; }

.roof-config-setting-item .color {
  display: flex;
  margin: 0 -.7rem; }
  .roof-config-setting-item .color label {
    font-size: 0; }

.roof-config-setting-item select {
  height: clamp(35px, 4.10256vw, 4rem);
  background-color: #fff;
  border: 2px solid #0078c0; }
  .roof-config-setting-item select option {
    display: inline-block;
    padding: 1rem; }

.roof-config-color {
  padding: 0 clamp(7px, 0.71795vw, 0.7rem); }
  .roof-config-color label:before {
    display: none; }
  .roof-config-color label {
    padding: 0; }
  .roof-config-color .fake-radio {
    width: clamp(30px, 3.07692vw, 3rem);
    height: clamp(30px, 3.07692vw, 3rem);
    border-radius: 50%;
    display: block;
    outline: 1px solid #fff;
    position: relative; }
  .roof-config-color.color-mesh .fake-radio {
    background: #fff; }
    .roof-config-color.color-mesh .fake-radio:before {
      content: "";
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.8;
      background-image: linear-gradient(135deg, grey 25%, transparent 25%), linear-gradient(225deg, grey 25%, transparent 25%), linear-gradient(45deg, grey 25%, transparent 25%), linear-gradient(315deg, grey 25%, #fff 25%);
      background-position: 5px 0, 5px 0, 0 0, 0 0;
      background-size: 5px 5px;
      background-repeat: repeat;
      width: clamp(30px, 3.07692vw, 3rem);
      height: clamp(30px, 3.07692vw, 3rem);
      position: absolute;
      top: 0;
      left: 0; }
  .roof-config-color.color-alu .fake-radio {
    background: #848789; }
  .roof-config-color.color-transparent .fake-radio {
    background: #ececec;
    outline: 1px solid #000; }
  .roof-config-color.color-grey-black .fake-radio {
    background: #000; }
    .roof-config-color.color-grey-black .fake-radio:before {
      content: "";
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      background: grey;
      width: clamp(15px, 1.53846vw, 1.5rem);
      height: clamp(30px, 3.07692vw, 3rem);
      position: absolute;
      top: 0;
      left: 0; }
  .roof-config-color.color-black .fake-radio {
    background: #000; }
  .roof-config-color.color-black-black .fake-radio {
    background: #000; }
  .roof-config-color.color-grey .fake-radio {
    background: grey; }
  .roof-config-color.color-empty .fake-radio {
    outline: 1px solid #000; }
  .roof-config-color input[type="radio"]:checked + .fake-radio {
    outline-width: 3px;
    outline-color: #0078c0; }

.mainBlock ul {
  padding: 0 0 0 16px; }

.dealer-page {
  padding: 20px; }
  .dealer-page .roof-config-main {
    position: relative;
    height: calc(100vh - 50px);
    background-color: #D5E7F1; }
  .dealer-page .roof-config-settings {
    display: none;
    flex-direction: column;
    padding: 1rem 4rem; }
    @media (min-width: 768px) {
      .dealer-page .roof-config-settings {
        display: flex; } }
    .dealer-page .roof-config-settings .roof-dealer-btns {
      margin-top: 30px; }
      @media (min-width: 768px) {
        .dealer-page .roof-config-settings .roof-dealer-btns {
          margin-top: auto; } }
      .dealer-page .roof-config-settings .roof-dealer-btns .btn {
        width: 100%; }
    .dealer-page .roof-config-settings .roof-config-setting-item > input:last-of-type {
      margin-bottom: 0; }
    .dealer-page .roof-config-settings .dealer-setting-title {
      margin: 30px 0; }
      @media (min-width: 320px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          font-size: 2rem; } }
      @media (min-width: 320px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .dealer-page .roof-config-settings .dealer-setting-title {
          line-height: 2.3rem; } }
    .dealer-page .roof-config-settings .map-search {
      position: inherit;
      bottom: unset;
      transform: translateX(0); }
    .dealer-page .roof-config-settings .dealer-setting-title-h4 {
      margin: 3rem 0 2rem 0;
      font-weight: 400; }

.dealer-open-settings {
  position: absolute;
  top: 2rem;
  left: 2rem;
  height: 6rem;
  z-index: 1;
  background-color: #0078c0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 44;
  padding: 0 1.5rem;
  font-size: 12px; }
  @media (min-width: 768px) {
    .dealer-open-settings {
      display: none; } }
  .dealer-open-settings::before {
    content: "\e91f";
    font-size: 4rem;
    margin: 0 1rem 0 0; }

.dealer-switch {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  gap: 5px;
  padding: 5px;
  background-color: #fff;
  z-index: 55; }
  .dealer-switch .dealer-switch-item {
    padding: 10px 4rem;
    color: #0078c0;
    text-transform: uppercase;
    cursor: pointer; }
    @media (min-width: 320px) {
      .dealer-switch .dealer-switch-item {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .dealer-switch .dealer-switch-item {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .dealer-switch .dealer-switch-item {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .dealer-switch .dealer-switch-item {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .dealer-switch .dealer-switch-item {
        font-size: 2rem; } }
    @media (min-width: 320px) {
      .dealer-switch .dealer-switch-item {
        line-height: 4rem; } }
    @media (min-width: 480px) {
      .dealer-switch .dealer-switch-item {
        line-height: 3.63636rem; } }
    @media (min-width: 545px) {
      .dealer-switch .dealer-switch-item {
        line-height: 3.33333rem; } }
    @media (min-width: 768px) {
      .dealer-switch .dealer-switch-item {
        line-height: 2.66667rem; } }
    @media (min-width: 992px) {
      .dealer-switch .dealer-switch-item {
        line-height: 2.5rem; } }
    @media (min-width: 1200px) {
      .dealer-switch .dealer-switch-item {
        line-height: 2.3rem; } }
    .dealer-switch .dealer-switch-item.active, .dealer-switch .dealer-switch-item:hover {
      background-color: #0078c0;
      color: #fff; }
    .dealer-switch .dealer-switch-item.active {
      cursor: default; }

#dealer-map {
  width: 100%;
  height: calc(100vh - 50px); }

.dealer-map {
  opacity: 0;
  width: 100%;
  position: relative;
  background-color: #0078c0; }
  .dealer-map.active {
    opacity: 1;
    z-index: 33; }

.dealer-list {
  padding: clamp(80px, 10rem, 10rem) 3rem 3rem 3rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .dealer-list .dealer-list-inner {
    overflow: auto;
    height: 100%; }
  .dealer-list.active {
    display: block;
    z-index: 22; }
  .dealer-list .dealer-list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    background-color: #fff;
    text-align: center; }
    @media (min-width: 480px) {
      .dealer-list .dealer-list-item {
        text-align: unset; } }
    @media (min-width: 1200px) {
      .dealer-list .dealer-list-item {
        flex-wrap: nowrap; } }
    .dealer-list .dealer-list-item + .dealer-list-item {
      margin-top: 10px; }
    .dealer-list .dealer-list-item .dealer-list-item-img {
      width: 100%;
      max-width: 200px;
      height: 133px;
      position: relative; }
      @media (min-width: 480px) {
        .dealer-list .dealer-list-item .dealer-list-item-img {
          width: 30%; } }
      @media (min-width: 1200px) {
        .dealer-list .dealer-list-item .dealer-list-item-img {
          width: 100%; } }
      .dealer-list .dealer-list-item .dealer-list-item-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center; }
      .dealer-list .dealer-list-item .dealer-list-item-img span {
        background: #0078c0;
        color: #fff;
        font-size: 14px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 5px; }
    .dealer-list .dealer-list-item .dealer-list-item-content {
      font-weight: 400; }
      @media (min-width: 480px) {
        .dealer-list .dealer-list-item .dealer-list-item-content {
          width: calc(70% - 15px); } }
      @media (min-width: 1200px) {
        .dealer-list .dealer-list-item .dealer-list-item-content {
          width: unset;
          flex-grow: 1; } }
      .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        text-transform: uppercase; }
        @media (min-width: 320px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            font-size: 3.2rem; } }
        @media (min-width: 480px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            font-size: 2.90909rem; } }
        @media (min-width: 545px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            font-size: 2.66667rem; } }
        @media (min-width: 768px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            font-size: 2.13333rem; } }
        @media (min-width: 992px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            font-size: 2rem; } }
        @media (min-width: 320px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 4rem; } }
        @media (min-width: 480px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 3.63636rem; } }
        @media (min-width: 545px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 3.33333rem; } }
        @media (min-width: 768px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 2.66667rem; } }
        @media (min-width: 992px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 2.5rem; } }
        @media (min-width: 1200px) {
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name {
            line-height: 2.3rem; } }
        .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
          color: #9E9E9E;
          font-size: 3.2rem;
          line-height: 4rem; }
          @media (min-width: 480px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 2.90909rem; } }
          @media (min-width: 545px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 2.66667rem; } }
          @media (min-width: 768px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 2.13333rem; } }
          @media (min-width: 992px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 2rem; } }
          @media (min-width: 1200px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 1.77778rem; } }
          @media (min-width: 1400px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              font-size: 1.6rem; } }
          @media (min-width: 480px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 3.63636rem; } }
          @media (min-width: 545px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 3.33333rem; } }
          @media (min-width: 768px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 2.66667rem; } }
          @media (min-width: 992px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 2.5rem; } }
          @media (min-width: 1200px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 2.22222rem; } }
          @media (min-width: 1400px) {
            .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name span {
              line-height: 2rem; } }
        .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name:hover {
          color: #0078c0; }
          .dealer-list .dealer-list-item .dealer-list-item-content .dealer-list-item-name:hover span {
            color: #0078c0; }
      .dealer-list .dealer-list-item .dealer-list-item-content p {
        margin: 0; }
        .dealer-list .dealer-list-item .dealer-list-item-content p + p {
          margin-top: 10px; }
    .dealer-list .dealer-list-item .dealer-list-item-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%; }
      @media (min-width: 480px) {
        .dealer-list .dealer-list-item .dealer-list-item-btns {
          flex-direction: row; } }
      @media (min-width: 1200px) {
        .dealer-list .dealer-list-item .dealer-list-item-btns {
          flex-direction: column;
          width: unset; } }
      .dealer-list .dealer-list-item .dealer-list-item-btns .btn {
        width: 100%;
        text-transform: uppercase; }
    .dealer-list .dealer-list-item.visible {
      display: flex !important; }
  .dealer-list .no-top .dealer-list-item-img span {
    display: none; }

.roof-config-setting-item ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 0; }
  @media (min-width: 480px) {
    .roof-config-setting-item ul {
      grid-template-columns: 1fr 1fr 1fr; } }
  @media (min-width: 768px) {
    .roof-config-setting-item ul {
      grid-template-columns: 1fr 1fr; } }
  @media (min-width: 1500px) {
    .roof-config-setting-item ul {
      grid-template-columns: 1fr 1fr 1fr; } }
  .roof-config-setting-item ul li {
    list-style: none; }

.dealer-detail {
  padding: clamp(80px, 10rem, 10rem) 3rem 3rem 3rem;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .dealer-detail.active {
    display: block;
    z-index: 55; }
  .dealer-detail .dealer-detail-inner {
    padding: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    overflow: auto;
    height: 100%; }
  .dealer-detail [data-detail-distance] {
    color: #9E9E9E; }
    @media (min-width: 320px) {
      .dealer-detail [data-detail-distance] {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .dealer-detail [data-detail-distance] {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .dealer-detail [data-detail-distance] {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .dealer-detail [data-detail-distance] {
        font-size: 2.2rem; } }
    @media (min-width: 320px) {
      .dealer-detail [data-detail-distance] {
        line-height: 4.2rem; } }
    @media (min-width: 480px) {
      .dealer-detail [data-detail-distance] {
        line-height: 3.81818rem; } }
    @media (min-width: 545px) {
      .dealer-detail [data-detail-distance] {
        line-height: 3.5rem; } }
    @media (min-width: 768px) {
      .dealer-detail [data-detail-distance] {
        line-height: 2.8rem; } }
    @media (min-width: 992px) {
      .dealer-detail [data-detail-distance] {
        line-height: 2.7rem; } }
  .dealer-detail a[data-detail-back] {
    display: block;
    margin-bottom: clamp(20px, 3rem, 3rem);
    color: #0078c0;
    text-decoration: underline; }
    .dealer-detail a[data-detail-back]:hover {
      text-decoration: none; }
  .dealer-detail .dealer-detail-top {
    text-align: center; }
    @media (min-width: 545px) {
      .dealer-detail .dealer-detail-top {
        display: flex;
        align-items: center;
        text-align: unset; } }
    @media (min-width: 320px) {
      .dealer-detail .dealer-detail-top {
        gap: 3rem; } }
    .dealer-detail .dealer-detail-top .dealer-detail-img {
      width: 300px;
      height: 200px;
      max-width: 100%;
      margin: 0 auto 2rem auto; }
      @media (min-width: 545px) {
        .dealer-detail .dealer-detail-top .dealer-detail-img {
          margin: unset; } }
      .dealer-detail .dealer-detail-top .dealer-detail-img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center; }
    .dealer-detail .dealer-detail-top .dealer-detail-content {
      font-weight: 400; }
      @media (min-width: 320px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 2.66667rem; } }
      @media (min-width: 992px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 2.5rem; } }
      @media (min-width: 1200px) {
        .dealer-detail .dealer-detail-top .dealer-detail-content {
          line-height: 2.4rem; } }
      .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
        margin-bottom: 20px;
        font-weight: 700; }
        @media (min-width: 320px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            font-size: 4rem; } }
        @media (min-width: 480px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            font-size: 3.63636rem; } }
        @media (min-width: 545px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            font-size: 3.33333rem; } }
        @media (min-width: 768px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            font-size: 3rem; } }
        @media (min-width: 320px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            line-height: 5rem; } }
        @media (min-width: 480px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            line-height: 4.54545rem; } }
        @media (min-width: 545px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            line-height: 4.16667rem; } }
        @media (min-width: 768px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-list-item-name {
            line-height: 3.5rem; } }
      .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-detail-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        gap: 10px 3rem;
        align-items: center; }
        @media (min-width: 545px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-detail-btns {
            justify-content: unset; } }
        @media (min-width: 1200px) {
          .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-detail-btns {
            flex-wrap: nowrap; } }
        .dealer-detail .dealer-detail-top .dealer-detail-content .dealer-detail-btns .btn {
          min-width: 207px; }
  .dealer-detail .dealer-detail-title {
    margin: 4rem 0 clamp(10px, 1.5rem, 1.5rem) 0; }
  .dealer-detail .dealer-detail-brands {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 12px; }
    @media (min-width: 480px) {
      .dealer-detail .dealer-detail-brands {
        grid-template-columns: repeat(4, 1fr); } }
    @media (min-width: 992px) {
      .dealer-detail .dealer-detail-brands {
        grid-template-columns: repeat(6, 1fr); } }
    @media (min-width: 1400px) {
      .dealer-detail .dealer-detail-brands {
        grid-template-columns: repeat(8, 1fr); } }
    .dealer-detail .dealer-detail-brands .dealer-detail-brands-item {
      display: block;
      height: clamp(76px, 7.6rem, 7.6rem); }
      .dealer-detail .dealer-detail-brands .dealer-detail-brands-item img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .dealer-detail .dealer-detail-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px; }
    @media (min-width: 992px) {
      .dealer-detail .dealer-detail-products {
        grid-template-columns: repeat(4, 1fr); } }
    @media (min-width: 1699px) {
      .dealer-detail .dealer-detail-products {
        grid-template-columns: repeat(6, 1fr); } }
    .dealer-detail .dealer-detail-products .dealer-detail-products-item {
      border: 1px solid #D5E7F1;
      text-align: center;
      text-decoration: none;
      color: #000; }
      .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-img {
        height: clamp(100px, 11.5rem, 11.5rem);
        background-color: #fff; }
        .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
        padding: 10px 2rem;
        font-size: 2.8rem;
        line-height: 3.2rem; }
        @media (min-width: 480px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 2.54545rem; } }
        @media (min-width: 545px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 2.33333rem; } }
        @media (min-width: 768px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 1.86667rem; } }
        @media (min-width: 992px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 1.75rem; } }
        @media (min-width: 1200px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 1.55556rem; } }
        @media (min-width: 1400px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            font-size: 1.4rem; } }
        @media (min-width: 480px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 2.90909rem; } }
        @media (min-width: 545px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 2.66667rem; } }
        @media (min-width: 768px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 2.13333rem; } }
        @media (min-width: 992px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 2rem; } }
        @media (min-width: 1200px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 1.77778rem; } }
        @media (min-width: 1400px) {
          .dealer-detail .dealer-detail-products .dealer-detail-products-item .dealer-detail-products-name {
            line-height: 1.6rem; } }
    .dealer-detail .dealer-detail-products a.dealer-detail-products-item:hover {
      color: #0078c0; }
    .dealer-detail .dealer-detail-products a.dealer-detail-products-item:focus {
      text-decoration: none; }

.name-none {
  display: none !important; }

.brand-none {
  display: none; }

.type-none {
  display: none; }

.product-none {
  display: none; }

.dealer-modal .modal-content {
  width: 95vw !important;
  max-width: 100%;
  min-height: 90vh; }

.dealer-modal .modal-dialog {
  margin: 5vh auto 5vh auto; }

.dealer-modal .modal-title {
  color: #fff;
  font-size: 20px; }

.dealer-modal .modal-header {
  margin: 0 !important;
  border: none !important; }

.dealer-modal .modal-body {
  padding: 0 !important;
  display: flex; }

.dealer-modal .roof-config {
  border: none;
  width: 100%; }

.dealer-modal .dealer-map {
  height: 100%; }

.dealer-modal #dealer-map {
  height: 100%; }

.dealer-modal input[type="text"] {
  background-color: #fff !important; }

@media (min-width: 768px) {
  .btn-text-respo {
    display: none; } }

.btn-text-desk {
  display: none; }
  @media (min-width: 768px) {
    .btn-text-desk {
      display: block; } }

.roof-config label.checkbox-color-blue:before {
  border-color: #00a8e4; }

.roof-config input[type="checkbox"]:checked + label.checkbox-color-blue:before {
  background-color: #00a8e4; }

.roof-config label.checkbox-color-green:before {
  border-color: #62bb00; }

.roof-config input[type="checkbox"]:checked + label.checkbox-color-green:before {
  background-color: #62bb00; }

.roof-config label.checkbox-color-orange:before {
  border-color: #ffab00; }

.roof-config input[type="checkbox"]:checked + label.checkbox-color-orange:before {
  background-color: #ffab00; }

.roof-config label.checkbox-color-fuchsia:before {
  border-color: #c7079b; }

.roof-config input[type="checkbox"]:checked + label.checkbox-color-fuchsia:before {
  background-color: #c7079b; }

.dealer-name-search {
  background-color: #fff;
  position: absolute !important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  top: 38px;
  display: none; }
  .dealer-name-search span {
    cursor: default;
    padding: 0 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 12px;
    color: #515151;
    font-weight: 400;
    font-family: Arial,sans-serif;
    display: block; }
    .dealer-name-search span:hover {
      background-color: #fafafa; }

.dealer-name-input {
  position: relative; }
  .dealer-name-input.active .dealer-name-search {
    display: block; }

.btn-primary.loading {
  position: relative; }
  .btn-primary.loading:before {
    content: "" !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    border-top: 3px solid #fff;
    animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle; }

@keyframes rotating {
  100% {
    transform: rotate(360deg); } }

.modal-body .roof-config input[type="checkbox"] + label::after {
  top: calc(50% - 3px); }

.pac-container {
  z-index: 99999; }

@media print {
  header,
  footer,
  .box-header-content,
  .content-mobile-menu,
  .products-menu,
  .products-menu-generated,
  .newsletter-box,
  .square-button,
  .square-button-bw,
  .big-button-bw,
  .social-list,
  .big-button-bw-small,
  .content-button-level-up,
  .slick-arrow {
    display: none !important; }
  .main-content {
    width: 100%; }
  .slick-list {
    overflow: visible !important; }
  .slick-track {
    width: 100% !important;
    transform: none !important; }
  .slick-slide {
    display: inline-block !important;
    float: none !important; }
  .content-gallery-list {
    width: 100% !important;
    max-height: none !important; }
  .content-tabs-list {
    display: block !important; }
  body {
    margin: 0;
    padding: 0;
    font-family: "Times New Roman", Times, serif;
    color: #423f3f !important; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Arial, Helvetica, sans-serif; }
  h1 {
    font-size: 16pt; }
  h2 {
    font-size: 14pt; }
  h3,
  h4,
  h5,
  h6 {
    font-size: 12pt; }
  p {
    line-height: 1.4;
    font-size: 12pt; }
  a:link,
  a:visited {
    text-decoration: underline;
    font-weight: bold;
    color: #000; }
  img {
    border: 1px solid #000; }
  ul * {
    display: block; } }

.modal.pux-modal {
  background-color: transparent !important; }
  .modal.pux-modal .modal-dialog {
    min-height: 1px;
    margin: 10px auto;
    text-align: center;
    width: auto;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    right: 10px; }
    .modal.pux-modal .modal-dialog .close {
      -webkit-align-self: flex-end;
      -moz-align-self: flex-end;
      -ms-align-self: flex-end;
      -webkit-align-self: flex-end;
      align-self: flex-end;
      border: none !important;
      color: #000 !important;
      top: 0 !important;
      left: 0 !important;
      position: relative !important; }
      .modal.pux-modal .modal-dialog .close:after {
        color: #000 !important; }
      .modal.pux-modal .modal-dialog .close:focus, .modal.pux-modal .modal-dialog .close:hover {
        background-color: transparent !important; }
    .modal.pux-modal .modal-dialog .modal-content {
      background-color: #dadada;
      width: auto;
      max-width: 100%; }

.modal-backdrop {
  background-color: transparent !important; }

.modal-open.pux-modal-open {
  padding-right: 0 !important;
  overflow: auto; }
