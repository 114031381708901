
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";
@import "_mixins/afi-scale-configurator.scss";

$roof-config-settings-width: pux-scale(30px, 62rem);
$roof-config-slider-shift: pux-scale(10px, 3rem);
$roof-config-slider-arrow-width: pux-scale(40px, 7rem);

.roof-config-page{
  display: flex;
  align-items: center;
  padding: pux-scale(20px, 4rem) 0;
  @include media(xxxxl) {
    height: 100vh;
  }
  .pux-container {
    width: 100%;
  }
}

.roof-config-slider{
  display: flex;
  overflow: hidden;
  .roof-config-slider-item{
    width: 100%;
    min-width: 100%;
  }
  .slick-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 22;
    width: $roof-config-slider-arrow-width;
    height: $roof-config-slider-arrow-width;
    border: 2px solid $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
    background: $color-white;
    color: $main-color;
    transition: 250ms ease-in-out;
    @include media(xxs, sm) {
      display: none !important;
    }
    &:before{
      font-size: pux-scale(30px, 5rem);
    }
    &:focus{
      outline: 0;
    }
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
  }
  .slick-prev{
    left: $roof-config-slider-shift;
    &:before{
      content:"\e90d";
    }
  }
  .slick-next{
    right: $roof-config-slider-shift;
    &:before{
      content:"\e90c";
      display: inline-block;
      transform: rotate(90deg);
    }
  }
  .slick-dots{
    position: absolute;
    left:0;
    bottom: pux-scale(-60px, -9rem);
    z-index: 22;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: pux-scale(15px, 1.5rem);
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    @include media(xl) {
      max-width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
    button{
      border: none;
      background: #D5E7F1;
      font-size: 0;
      width: pux-scale(50px, 10rem);
      height: pux-scale(5px, 0.5rem);
      outline: 0;
      &:focus{
        outline: 0;
      }
    }
    .slick-active{
      button{
        background: #0078C0;
      }
    }
  }
}

.roof-config-main-image{
  position: relative;
}

.roof-config-slider.slick-initialized{
  display: block;
  overflow: visible;
  .roof-config-slider-item{
    width: 100%;
    min-width: 1px;
  }
}

.roof-config{
  position: relative;
  display: flex;
  font-size: pux-scale(14px, 1.6rem);
  line-height: pux-scale(16px, 1.8rem);
  font-weight: 700;
  border: 2px solid #D5E7F1;
  .roof-config-main {
    width: 100%;
    position: relative;
    @include media(md) {
      max-width: 70%;
    }
    @include media(xxxl) {
      width: calc(100% - #{$roof-config-settings-width});
    }
    .roof-config-product {
      position: relative;
      padding: 11rem 3rem 4rem 3rem;
      text-align: center;
      @include media(md){
        text-align: end;
        padding: 3rem 3rem 4rem 3rem;
      }
      .roof-config-open-settings {
        position: absolute;
        top: 2rem;
        left: 2rem;
        height: 6rem;
        background-color: $main-color;
        padding: 0 1.5rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        @include media(md) {
          display: none;
        }
        &::before {
          content: "\e91d";
          font-size: 4rem;
          margin: 0 1rem 0 0;
        }
      }
      .roof-config-product-name {
        margin-bottom: 2rem;
        font-size: pux-scale(24px, 3rem);
        line-height: pux-scale(29px, 3.5rem);
      }
      .roof-config-product-perex {
        margin-left: auto;
        font-weight: 400;
        @include media(md){
          max-width: 70rem;
        }
      }
    }
    .roof-config-main-image {
      height: 53rem;
      margin: 0 auto pux-scale(40px, 10rem) auto;
      .slick-list {
        width: 100%;
        max-width: 90rem;
        margin: auto;
        .slick-track {
          display: flex;
          align-items: center;
        }
      }
      img {
        width: 100%;
        max-width: 90rem;
        height: 100%;
        max-height: 53rem;
        object-fit: contain;
      }
    }
    .roof-config-result {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 4rem;
      margin-top: 2rem;
      padding: 3rem;
      background-color: $color-primary;
      color: $color-white;
      position: relative;
      text-align: center;
      @include media(md){
        text-align: left;
      }
      @include media(lg) {
        flex-direction: unset;
      }
      .roof-config-result-left {
        .roof-config-result-name {
          margin: 0 0 1rem 0;
          font-size: pux-scale(24px, 3rem);
          line-height: pux-scale(29px, 3.4rem);
        }
        .roof-config-result-accessories {
          font-weight: 400;
        }
      }
      .roof-config-result-right {
        .btn-default {
          height: unset;
          padding: pux-scale(15px, 3rem) pux-scale(30px, 6rem);
          font-size: pux-scale(16px, 2rem);
          line-height: pux-scale(19px, 2.3rem);
          font-weight: 700;
          border: 2px solid $color-white !important;
          transition: 250ms ease-in-out;
          background: $main-color;
          color: #fff !important;
          &:hover {
            background-color: $color-white !important;
            color: $color-primary !important;
          }
        }
      }
    }
    .btn-more{
      position: absolute;
      bottom: 0;
      right: 3rem;
    }
  }
  .roof-config-more-btn{
    display: flex;
    justify-content: flex-end;
    padding: 0 3rem;
    position: relative;
    @include media(xl) {
      //margin-top: pux-scale(-80px, -8rem);
    }
    .btn {
      padding: pux-scale(15px, 2rem) pux-scale(20px, 3rem);
      font-weight: 700;
    }
  }
  .roof-config-settings {
    display: none;
    padding: 3rem 4rem;
    overflow: auto;
    @include media(md) {
      display: block;
      width: $roof-config-settings-width;
      border-left: 2px solid #D5E7F1;
    }
    &.open {
      @include media(xxs, sm) {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: 100;
      }
    }
    .roof-config-settings-btns {
      margin-top: 4rem;
      @include media(md) {
        display: none;
      }
    }
  }
  .roof-config-setting-title {
    margin: 3rem 0 1.5rem 0;
    font-size: pux-scale(20px, 2.4rem);
    line-height: pux-scale(24px, 2.8rem);
  }
  table {
    width: 100%;
    margin: 0;
  }
  td {
    padding: 0;
  }
  tr {
    & + tr {
      label {
        margin-top: 1.5rem;
      }
    }
  }
  input {
    &[type="radio"] {
      display: none;
      & + label {
        &::before {
          border-radius: 50%;
          transition: 250ms ease-in-out;
        }
      }
      &:checked {
        & + label {
          color: $color-primary;
          &::before {
            border-width: pux-scale(5px, 0.7rem);
          }
        }
      }
    }
    &[type="checkbox"] {
      display: none;
      & + label {
        &::before {
          transition: 250ms ease-in-out;
        }
        &::after {
          content: "\2713";
          display: inline-block;
          position: absolute;
          top: calc(50% - 1px);
          left: pux-scale(0.5px, 0.4rem);
          line-height: pux-scale(5.5px, 0.65rem);
          color: $color-white;
          opacity: 0;
          /* TODO: change with change icon */
          transform: translateY(-50%);
          transition: 250ms ease-in-out;
        }
      }
      &:checked {
        & + label {
          color: $color-primary;
          &::before {
            background-color: $color-primary;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
    &[type="text"] {
      width: 100%;
      margin-bottom: 30px;
      border: 2px solid $color-primary;
      background-color: $color-white;
      &::placeholder {
        color: $color-black;
      }
    }
  }
  label {
    position: relative;
    margin-bottom: 0;
    padding-left: pux-scale(25px, 3rem);
    font-size: pux-scale(14px, 1.6rem);
    line-height: pux-scale(15px, 2rem);
    font-weight: 700;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      display: inline-block;
      height: pux-scale(15px, 2rem);
      width: pux-scale(15px, 2rem);
      border: 2px solid $color-primary;
    }
  }
  .selectric-wrapper {
    .selectric {
      height: pux-scale(35px, 4rem);
      background-color: $color-white;
      border: 2px solid $color-primary;
      .label {
        line-height: pux-scale(35px, 4rem);
        font-size: pux-scale(14px, 1.6rem);
        color: $color-primary;
      }
      .button {
        background-color: inherit;
        &::after {
          color: $color-primary;
        }
      }
    }
    .selectric-items {
      background-color: $color-white;
      border: 2px solid $color-primary;
      li {
        background-color: unset;
        &:hover {
          color: $color-primary;
        }
        &.highlighted {
          color: $color-primary;
          background-color: rgba($color-primary, 0.05);
        }
      }
    }
    .selectric-hide-select.selectric-is-native select {
      background-color: $color-white;
      border: 2px solid $color-primary;
      option {
        background-color: unset;
        font-weight: 700;
        &[selected="selected"] {
          color: $color-primary;
          background-color: rgba($color-primary, 0.05);
        }
      }
    }
  }
  .roof-config-copy{
    position: absolute;
    left: 5px;
    top:5px;
  }
  .roof-config-copy-result{
    position: absolute;
    left: 50%;
    top:0;
    background: #fff;
    color: $main-color;
    padding: 5px 10px;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: 250ms;
    &.active{
      opacity: 1;
      transform: translate(-50%, 0%);
    }
  }
  .selectric-scroll{
    ul{
      display: block;
    }
  }
}

.roof-config-setting-item {
  .color{
    display: flex;
    margin: 0 -.7rem;
    label{
      font-size: 0;
    }
  }
  select {
    height: pux-scale(35px, 4rem);
    background-color: $color-white;
    border: 2px solid $color-primary;
    option {
      display: inline-block;
      padding: 1rem;
    }
  }
}

.roof-config-color{
  padding: 0 pux-scale(7px, 0.7rem);
  label:before{
    display: none;
  }
  label{
    padding: 0;
  }
  .fake-radio{
    width: pux-scale(30px, 3rem);
    height: pux-scale(30px, 3rem);
    border-radius: 50%;
    display: block;
    //border: 1px solid #fff;
    outline:1px solid #fff;
    position: relative;
  }
  &.color-mesh{
    .fake-radio{
      background: #fff;
      &:before{
        content: "";
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.8;
        background-image:  linear-gradient(135deg, grey 25%, transparent 25%), linear-gradient(225deg, grey 25%, transparent 25%), linear-gradient(45deg, grey 25%, transparent 25%), linear-gradient(315deg, grey 25%, #fff 25%);
        background-position:  5px 0, 5px 0, 0 0, 0 0;
        background-size: 5px 5px;
        background-repeat: repeat;
        width: pux-scale(30px, 3rem);
        height: pux-scale(30px, 3rem);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &.color-alu{
    .fake-radio{
      background: #848789;
    }
  }
  &.color-transparent{
    .fake-radio{
      background: #ececec;
      outline: 1px solid #000;
    }
  }
  &.color-grey-black{
    .fake-radio{
      background: #000;
      &:before{
        content: "";
        border-top-left-radius: 3rem;
        border-bottom-left-radius: 3rem;
        background: grey;
        width: pux-scale(15px, 1.5rem);
        height: pux-scale(30px, 3rem);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &.color-black{
    .fake-radio{
      background: #000;
    }
  }
  &.color-black-black{
    .fake-radio{
      background: #000;
    }
  }
  &.color-grey{
    .fake-radio{
      background: grey;
    }
  }
  &.color-empty{
    .fake-radio{
      outline: 1px solid #000;
    }
  }
  input[type="radio"]:checked{
    & + .fake-radio{
      outline-width: 3px;
      outline-color: $main-color;
      //border-width: 3px;
      //border-color: $main-color;
    }
  }
}

.mainBlock{
  ul{
    padding: 0 0 0 16px;
  }
}
