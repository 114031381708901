@function get-clamp($max, $container: 97.5%) {
    $maxStrip: strip-unit($max);
    $containerStrip: strip-unit($container);
    $value: $maxStrip / $containerStrip * 100vw;
		@return $value;
}

@function pux-scale($min, $max) {
	$value : clamp(#{$min}, #{get-clamp($max)}, #{$max});
	@return $value;
}

@mixin pux-scale($key, $min, $max) {
	$value : pux-scale($min, $max);
	#{$key}: $value;
}