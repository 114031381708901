
@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.dealer-page{
  padding: 20px;
  .roof-config-main {
    position: relative;
    height: calc(100vh - 50px);
    background-color: #D5E7F1;
  }
  .roof-config-settings {
    display: none;
    flex-direction: column;
    padding: 1rem 4rem;
    @include media(md) {
      display: flex;
    }
    .roof-dealer-btns {
      margin-top: 30px;
      @include media(md) {
        margin-top: auto;
      }
      .btn {
        width: 100%;
      }
    }
    .roof-config-setting-item {
      & > input {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .dealer-setting-title {
      margin: 30px 0;
      @include pux-scale-with-min('font-size', 20px, 16px);
      @include pux-scale-with-min('line-height', 23px, 20px);
    }
    .map-search {
      position: inherit;
      bottom: unset;
      transform: translateX(0);
    }
    .dealer-setting-title-h4 {
      margin: 3rem 0 2rem 0;
      font-weight: 400;
    }
  }
}

.dealer-open-settings {
  position: absolute;
  top: 2rem;
  left: 2rem;
  //width: 6rem;
  height: 6rem;
  z-index: 1;
  background-color: $main-color;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 44;
  padding: 0 1.5rem;
  font-size: 12px;
  @include media(md) {
    display: none;
  }
  &::before {
    content: "\e91f";
    font-size: 4rem;
    margin: 0 1rem 0 0;
  }
}
.dealer-switch {
  position: absolute;
  top: 3rem;
  right: 3rem;
  display: flex;
  gap: 5px;
  padding: 5px;
  background-color: $color-white;
  z-index: 55;
  .dealer-switch-item {
    padding: 10px 4rem;
    @include pux-scale-with-min('font-size', 20px, 16px);
    @include pux-scale-with-min('line-height', 23px, 20px);
    color: $color-primary;
    text-transform: uppercase;
    cursor: pointer;
    &.active,
    &:hover {
      background-color: $color-primary;
      color: $color-white;
    }
    &.active {
      cursor: default;
    }
  }
}

#dealer-map{
  width: 100%;
  height: calc(100vh - 50px);
}

.dealer-map {
  //display: none;
  opacity: 0;
  width: 100%;
  position: relative;
  background-color: $color-primary;
  &.active {
    //display: block;
    opacity: 1;
    z-index: 33;
  }
}
.dealer-list {
  padding: clamp(80px, 10rem, 10rem) 3rem 3rem 3rem;
  display: none;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  .dealer-list-inner{
    overflow: auto;
    height: 100%;
  }
  &.active {
    display: block;
    z-index: 22;
  }
  .dealer-list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;
    gap: 15px;
    padding: 10px;
    background-color: $color-white;
    text-align: center;
    @include media(xs) {
      text-align: unset;
    }
    @include media(xl) {
      flex-wrap: nowrap;
    }
    & + .dealer-list-item {
      margin-top: 10px;
    }
    .dealer-list-item-img {
      width: 100%;
      max-width: 200px;
      height: 133px;
      position: relative;
      @include media(xs) {
        width: 30%;
      }
      @include media(xl) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
      span{
        background:$main-color;
        color: #fff;
        font-size: 14px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding: 2px 5px;
      }
    }
    .dealer-list-item-content {
      font-weight: 400;
      @include media(xs) {
        width: calc(70% - 15px);
      }
      @include media(xl) {
        width: unset;
        flex-grow: 1;
      }
      .dealer-list-item-name {
        display: block;
        margin-bottom: 10px;
        @include pux-scale-with-min('font-size', 20px, 16px);
        @include pux-scale-with-min('line-height', 23px, 20px);
        font-weight: 700;
        text-transform: uppercase;
        span{
          color: #9E9E9E;
          @include pux-static-size('font-size', 16px);
          @include pux-static-size('line-height', 20px);
        }
        &:hover {
          color: $color-primary;
          span{
            color: $color-primary;
          }
        }
      }
      p {
        margin: 0;
        & + p {
          margin-top: 10px;
        }
      }
    }
    .dealer-list-item-btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%;
      @include media(xs) {
        flex-direction: row;
      }
      @include media(xl) {
        flex-direction: column;
        width: unset;
      }
      .btn {
        width: 100%;
        text-transform: uppercase;
      }
    }
    &.visible{
      display: flex !important;
    }
  }
  .no-top{
    .dealer-list-item-img span{
      display: none;
    }
  }
}
.roof-config-setting-item {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
    @include media(xs) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media(md) {
      grid-template-columns: 1fr 1fr;
    }
    @include media(xxxl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    li {
      list-style: none;
    }
  }
}
.dealer-detail {
  padding: clamp(80px, 10rem, 10rem) 3rem 3rem 3rem;
  display: none;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  &.active {
    display: block;
    z-index: 55;
  }
  .dealer-detail-inner{
    padding: 3rem;
    background-color: rgba($color-white, 0.8);
    overflow: auto;
    height: 100%;
  }
  [data-detail-distance]{
    color: #9E9E9E;
    @include pux-scale-with-min('font-size', 22px, 16px);
    @include pux-scale-with-min('line-height', 27px, 21px);
  }
  a[data-detail-back] {
    display: block;
    margin-bottom: clamp(20px, 3rem, 3rem);
    color: $main-color;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  .dealer-detail-top {
    text-align: center;
    @include media(sm) {
      display: flex;
      align-items: center;
      text-align: unset
    }
    @include pux-scale-with-min('gap', 30px, 10px);
    .dealer-detail-img {
      width: 300px;
      height: 200px;
      max-width: 100%;
      margin: 0 auto 2rem auto;
      @include media(sm) {
        margin: unset;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .dealer-detail-content {
      @include pux-scale-with-min('line-height', 24px, 20px);
      font-weight: 400;
      .dealer-list-item-name {
        margin-bottom: 20px;
        @include pux-scale-with-min('font-size', 30px, 20px);
        @include pux-scale-with-min('line-height', 35px, 25px);
        font-weight: 700;
      }
      .dealer-detail-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        gap: 10px 3rem;
        align-items: center;
        @include media(sm) {
          justify-content: unset;
        }
        @include media(xl) {
          flex-wrap: nowrap;
        }
        .btn {
          min-width: 207px;
        }
      }
    }
  }
  .dealer-detail-title {
    margin: 4rem 0 clamp(10px, 1.5rem, 1.5rem) 0;
  }
  .dealer-detail-brands {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 12px;
    @include media(xs) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media(lg) {
      grid-template-columns: repeat(6, 1fr);
    }
    @include media(xxl) {
      grid-template-columns: repeat(8, 1fr);
    }
    .dealer-detail-brands-item {
      display: block;
      height: clamp(76px, 7.6rem, 7.6rem);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  .dealer-detail-products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    @include media(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (min-width: 1699px) {
      grid-template-columns: repeat(6, 1fr);
    }
    .dealer-detail-products-item {
      border: 1px solid #D5E7F1;
      text-align: center;
      text-decoration: none;
      color: #000;
      .dealer-detail-products-img {
        height: clamp(100px, 11.5rem, 11.5rem);
        background-color: $color-white;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .dealer-detail-products-name {
        padding: 10px 2rem;
        @include pux-static-size('font-size', 14px);
        @include pux-static-size('line-height', 16px);
      }
    }
    a.dealer-detail-products-item{
      &:hover{
        color: $main-color;
      }
      &:focus{
        text-decoration: none;
      }
    }
  }
}

.name-none{
  display: none !important;
}

.brand-none{
  display: none;
}

.type-none{
  display: none;
}

.product-none{
  display: none;
}

.dealer-modal {
  .modal-content{
    width: 95vw !important;
    max-width: 100%;
    min-height: 90vh;
  }
  .modal-dialog{
    margin: 5vh auto 5vh auto;
  }
  .modal-title{
    color: #fff;
    font-size: 20px;
  }
  .modal-header{
    margin: 0 !important;
    border: none !important;
  }
  .modal-body{
    padding: 0 !important;
    display: flex;
  }
  .roof-config{
    border: none;
    width: 100%;
  }
  .dealer-map{
    height: 100%;
  }
  #dealer-map{
    height: 100%;
  }
  input[type="text"]{
    background-color: #fff !important;
  }
}

.btn-text-respo{
  @include media(md){
    display: none;
  }
}

.btn-text-desk{
  display: none;
  @include media(md){
    display: block;
  }
}

.roof-config label.checkbox-color-blue:before{
  border-color: #00a8e4;
}

.roof-config input[type="checkbox"]:checked + label.checkbox-color-blue:before{
  background-color: #00a8e4;
}

.roof-config label.checkbox-color-green:before{
  border-color: #62bb00;
}

.roof-config input[type="checkbox"]:checked + label.checkbox-color-green:before{
  background-color: #62bb00;
}

.roof-config label.checkbox-color-orange:before{
  border-color: #ffab00;
}

.roof-config input[type="checkbox"]:checked + label.checkbox-color-orange:before{
  background-color: #ffab00;
}

.roof-config label.checkbox-color-fuchsia:before{
  border-color: #c7079b;
}

.roof-config input[type="checkbox"]:checked + label.checkbox-color-fuchsia:before{
  background-color: #c7079b;
}

.dealer-name-search{
  background-color: #fff;
  position: absolute!important;
  z-index: 1000;
  border-radius: 2px;
  border-top: 1px solid #d9d9d9;
  box-shadow: 0 2px 6px rgba(0,0,0,.3);
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  top: 38px;
  display: none;
  span{
    cursor: default;
    padding: 0 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 12px;
    color: #515151;
    font-weight: 400;
    font-family: Arial,sans-serif;
    display: block;
    &:hover {
      background-color: #fafafa;
    }
  }
}

.dealer-name-input{
  position: relative;
  &.active{
    .dealer-name-search{
      display: block;
    }
  }
}

.btn-primary.loading{
  position: relative;
  &:before{
    content: "" !important;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255,255,255,.1);
    border-top: 3px solid #fff;
    animation: rotating 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}

@keyframes rotating {
  100% {
    transform: rotate(360deg);
  }
}

.modal-body{
  .roof-config input[type="checkbox"] + label::after{
    top: calc(50% - 3px);
  }
}

.pac-container{
  z-index: 99999;
}