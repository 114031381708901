@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import '_mixins/pux/scale.scss';
@import "_mixins/afi.scss";
@import "_mixins/base.scss";

.fancybox-container {
  & * {
    outline: 0 !important;
  }
}

.fancybox-infobar__body {
  position: relative !important;
  @include calc("max-width", "100% - 132px");
  height: 44px !important;
  padding: 0 !important;
  margin: 0 !important;
  display: inline-block !important;
  background: transparent !important;

  .fancybox-caption {
    border: 0 !important;
    text-align: left;
    background: transparent !important;
    padding: 13px 10px;
    color: white;
  }
}

.fancybox-arrow {
  width: 40px !important;
  background-image: none !important;
  margin-left: 5px !important;
  margin-right: 5px !important;

  @include media(md) {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  &:before {
    @include icomoon;
    @include pseudo-elements("\e908", relative, 50%, 50%);
    display: inline-block !important;
    @include translate(-50%, 0);
    color: $color-white !important;
    font-size: 40px;
    width: 40px !important;
    height: 40px !important;
    border-radius: 40px !important;
    border: 1px solid $color-white !important;
    background-color: rgba($color-black, 0.95) !important;
    @include transition(150ms);
    z-index: 9999999;
  }

  &:after {
    background-color: transparent !important;
    background-image: none !important;
  }
}


.fancy-video {

  &.fancybox-is-open .fancybox-bg {
    opacity: 1 !important;
    background-color: $color-black !important;
  }

  &.fancybox-is-open .fancybox-arrow {
    //opacity: 1 !important;
  }


  .fancybox-toolbar,
  .fancybox-navigation {
    height: 50px !important;
  }

  .fancybox-infobar {
    top: auto !important;
    bottom: 0px !important;
    text-align: center !important;
    font-size: 16px;
    margin-left: -50px !important;
    left: 50% !important;
  }

  .fancybox-stage {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
  }

  .fancybox-content {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
  }

  .fancybox-arrow {
    height: auto !important;
    top: auto;
    bottom: 0% !important;
  }

  .fancybox-button--close {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    top: 5px;
    right: 5px;

    @include media(md) {
      right: 20px;
    }

    &:hover,
    &:focus {
      color: #fff !important;

      &-before {
        color: #fff !important;
      }
    }
  }
}
