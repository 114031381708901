@import "_variables.scss";
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/afi.scss";
@import "_mixins/base.scss";
.modal{
  &.pux-modal{
    background-color:transparent !important;
    .modal-dialog{
      min-height: 1px;
      margin: 10px auto;
      text-align: center;
      width: auto;
      max-width: 100%;
      position: absolute;
      bottom: 0;
      right: 10px;
      .close{
        @include align-self(flex-end);
        border:none !important;
        color:#000 !important;
        top:0 !important;
        left:0 !important;
        position: relative !important;
        &:after{
          color:#000 !important;
        }
        &:focus,
        &:hover{
          background-color: transparent !important;
        }
      }
      .modal-content{
        background-color: #dadada;
        width:auto;
        max-width: 100%;
      }
    }
  }
}

.modal-backdrop{
  background-color: transparent !important;
}

.modal-open.pux-modal-open{
 	padding-right:0 !important;
  overflow: auto;
}
